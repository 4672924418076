import { clearToken } from '../helpers/utility'

// function getCookie (name) {
//   var value = '; ' + document.cookie
//   var parts = value.split('; ' + name + '=')
//   if (parts.length === 2) {
//     return parts
//       .pop()
//       .split(';')
//       .shift()
//   }
// }

const responseChecker = err => {
  if (err !== undefined || err.response !== undefined) {
    let status = ''
    if (err.status !== undefined) {
      status = err.status
    } else {
      status = err.response?.status
    }
    if (status === 401) {
      const from = window.sessionStorage.getItem('isFromGojek')
      const fromGoDriver = window.sessionStorage.getItem('isFromGoDriver')
      if (from === 'true') {
        window.history.pushState({}, null, '/gojek-passenger/claim')
        clearToken()
      } else if (fromGoDriver === 'true') {
        window.history.pushState({}, null, '/go-driver/claim')
        clearToken()
      } else {
        window.history.pushState({}, null, '/')
        clearToken()
      }
    }
  }
}

export default responseChecker
