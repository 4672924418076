import getRequest from '../get'
import postRequest from '../post'
import authPostRequest from '../authPost'
import authPutRequest from '../authPut'
import authDeleteRequest from '../authDelete'
import postUpload from '../postUpload'
import url from '../../endpoint/user'
// import axios from 'axios'

const getUserProfile = async () => {
  try {
    const base = url.getUserProfile()
    return await getRequest(base)
  } catch (error) {
    return error.response
  }
}

const getUserDetails = async () => {
  try {
    const base = url.getUserDetails()
    return await getRequest(base)
  } catch (error) {
    return error.response
  }
}

const getLocations = async () => {
  try {
    const base = url.getLocations()
    return await getRequest(base)
  } catch (error) {
    return error.response
  }
}

const sendOtp = async ({ body }) => {
  try {
    const base = url.sendOtp()
    return await postRequest(base, { ...body, partner: 'pasarpolis' }) // needs to change to actual partner
  } catch (error) {
    return error.response
  }
}

const verifyOtp = async ({ body }) => {
  try {
    const base = url.verifyOtp()
    return await postRequest(base, body)
  } catch (error) {
    return error.response
  }
}

const updatePhone = async ({ body }) => {
  try {
    const base = url.updatePhone()
    return await authPostRequest(base, { ...body, partner: 'pasarpolis' })
  } catch (error) {
    return error.response
  }
}

const updateEmail = async ({ body }) => {
  try {
    const base = url.updateEmail()
    return await authPostRequest(base, { ...body, partner: 'pasarpolis' })
  } catch (error) {
    return error.response
  }
}

const addEmail = async ({ body }) => {
  try {
    const base = url.addEmail()
    return await authPostRequest(base, { ...body, partner: 'pasarpolis' })
  } catch (error) {
    return error.response
  }
}

const uploadFile = async ({ body }) => {
  try {
    const base = url.uploadFile()
    return await postUpload(base, body)
  } catch (error) {
    return error.response
  }
}

const updateUser = async ({ body }) => {
  try {
    const base = url.getUserDetails()
    return await authPutRequest(base, body)
  } catch (error) {
    return error.response
  }
}

const updateUserProfile = async ({ body }) => {
  try {
    const base = url.getUserProfile()
    return await authPutRequest(base, body)
  } catch (error) {
    return error.response
  }
}

const getBankAccounts = async () => {
  try {
    const base = url.bankAccounts()
    return await getRequest(base)
  } catch (error) {
    return error.response
  }
}

const putBankAccounts = async ({ body }) => {
  try {
    const base = url.bankAccounts()
    return await authPutRequest(base, body)
  } catch (error) {
    return error.response
  }
}

const postBankAccounts = async ({ body }) => {
  try {
    const base = url.bankAccounts()
    return await authPostRequest(base, body)
  } catch (error) {
    return error.response
  }
}

const deleteBankAccounts = async ({ body }) => {
  try {
    const base = url.bankAccounts()
    return await authDeleteRequest(base, body)
  } catch (error) {
    return error.response
  }
}

const getDocuments = async () => {
  try {
    const base = url.documents()
    return await getRequest(base)
  } catch (error) {
    return error.response
  }
}

const putDocuments = async ({ body }) => {
  try {
    const base = url.documents()
    return await authPutRequest(base, body)
  } catch (error) {
    return error.response
  }
}

const postDocuments = async ({ body }) => {
  try {
    const base = url.documents()
    return await authPostRequest(base, body)
  } catch (error) {
    return error.response
  }
}

const deleteDocuments = async ({ body }) => {
  try {
    const base = url.documents()
    return await authDeleteRequest(base, body)
  } catch (error) {
    return error.response
  }
}

const verifyBankAccount = async ({ body }) => {
  try {
    const base = url.verifyBankAccount()
    return await authPostRequest(base, body)
  } catch (error) {
    return error.response
  }
}

export default {
  getUserProfile,
  getUserDetails,
  getLocations,
  sendOtp,
  verifyOtp,
  updatePhone,
  updateEmail,
  addEmail,
  uploadFile,
  updateUser,
  updateUserProfile,
  getDocuments,
  putDocuments,
  postDocuments,
  deleteDocuments,
  getBankAccounts,
  putBankAccounts,
  postBankAccounts,
  deleteBankAccounts,
  verifyBankAccount
}
