const host = process.env.REACT_APP_URL

const createOrderUrl = () => {
  return `${host}api/v1/go-life/create-order/`
}

const getBenefitsGolifePassengerUrl = () => {
  return `${host}api/v1/go-life/benefits/`
}

const createClaimGocarPassengerUrl = () => {
  return `${host}api/v1/go-life/create-claim/`
}

export default {
  createOrderUrl,
  getBenefitsGolifePassengerUrl,
  createClaimGocarPassengerUrl
}
