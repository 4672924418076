import React, { Component } from 'react'
import { Icon, Row, Col } from 'antd'
import { connect } from 'react-redux'
import authAction from '../../redux/auth/actions'
import ShippingHeaderStyle from './shippingHeader.style'
import IntlMessages from '../utility/intlMessages'
import { language } from '../../settings'

import AppActions from '../../redux/app/actions'

const { changeLang } = AppActions
const { signOut } = authAction

class AppHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      defaultLang: language()
    }
  }
    signOut = () => {
      const {history} = this.props
      let checking = window.sessionStorage.getItem('isFromGojek')
      let checkingGoDriver = window.sessionStorage.getItem('isFromGoDriver')
      if (checking === 'true') {
        history.push('/gojek-passenger/claim')
      } else if (checkingGoDriver === 'true') {
        history.push('/go-driver/claim')
      }

      this.props.signOut()
    }
    goToClaim = () => {
      const {history} = this.props
      history.push('/tokopedia/shipping')
    }
    renderSignout = () => {
      if (this.props.isLoggedIn) {
        return <div className='logout-container'>
          <a className='user-icon' onClick={this.signOut} style={{ display: 'flex', alignItems: 'center' }}>
            <Icon type='login' theme='outlined' /> &nbsp;
            <p><IntlMessages id='Keluar' /></p>
          </a>
        </div>
      }
    }
    handleChangeLang = val => {
      window.localStorage.setItem('lang', val)
      this.props.changeLang(val)
    }
    render () {
      return (
        <ShippingHeaderStyle>
          <Row type='flex' justify='center'>
            <Col span={18} xs={18}>
              <div className='header'>
                <h3 style={{ lineHeight: '5px' }}>
                  <img
                    src='https://storage.googleapis.com/pp-static-testing/tokopedia-logo.svg'
                    style={{ cursor: 'pointer' }}
                    onClick={this.goToClaim}
                    alt=''
                  />
                  <p className='pasarpolis'><small>Powered by</small> <b>Pasarpolis</b></p>
                </h3>
              </div>
            </Col>
          </Row>
        </ShippingHeaderStyle>
      )
    }
}

export default connect(
  state => ({
    isLoggedIn: state.Auth.idToken !== null,
    language: state.App.language
  }), { signOut, changeLang }
)(AppHeader)
