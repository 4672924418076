const host = process.env.REACT_APP_URL

const getBankList = () => {
  let base = `${host}api/v1/master-data/banks/`
  return base
}

export default {
  getBankList
}
