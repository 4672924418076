import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import request from '../../request/axios/golifePassenger'
import middleware from '../../middleware/responseChecker'

export function * createOrderRequest () {
  yield takeEvery(actions.CREATE_ORDER_GOLIFE_PASSENGER, function * (payload) {
    let res = yield call(request.createOrder, payload)
    if (res.status === 200) {
      yield put({
        type: actions.CREATE_ORDER_GOLIFE_PASSENGER_STATUS,
        createOrderStatus: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.CREATE_ORDER_GOLIFE_PASSENGER_STATUS,
        createOrderStatus: res.data.error
      })
    }
  })
}

export function * getBenefitsGolifeRequest () {
  yield takeEvery(actions.GET_BENEFITS_GO_LIFE_REQUEST, function * (
    payload
  ) {
    let res = yield call(request.getBenefitsGolifePassenger, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_BENEFITS_GO_LIFE_SUCCESS,
        data: res.data,
        getBenefitsGolifePassengerStatus: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_BENEFITS_GO_LIFE_FAILURE,
        data: [],
        getBenefitsGolifePassengerStatus: res.data.error
      })
    }
  })
}

export function * createClaimGolifeRequest () {
  yield takeEvery(actions.CREATE_CLAIM_GO_LIFE_REQUEST, function * (
    payload
  ) {
    let res = yield call(request.createClaimGocarPassenger, payload)
    if (res.status === 200) {
      yield put({
        type: actions.CREATE_CLAIM_GO_LIFE_SUCCESS,
        data: res.data,
        message: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.CREATE_CLAIM_GO_LIFE_FAILURE,
        data: {},
        message: res.data.error || 'message'
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(createOrderRequest),
    fork(getBenefitsGolifeRequest),
    fork(createClaimGolifeRequest)
  ])
}
