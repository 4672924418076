import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import actions from './actions'
import request from '../../request/axios/app'
import _ from 'lodash/object'

export function * changeLanguageRequest () {
  yield takeEvery(actions.CHANGE_LANG_REQUEST, function * (payload) {
    const { language } = payload
    const messagesId = (language || {}).messagesId
    if (messagesId === 'ind') {
      // not need to call the server api to get messgae in case of ind mesaage id
      yield delay(200)
      yield put({
        type: actions.CHANGE_LANG_SUCCESS,
        language,
        messages: {}
      })
    } else {
      let res = yield call(request.getLanguage, { messagesId })
      if (res.status === 200) {
        yield put({
          type: actions.CHANGE_LANG_SUCCESS,
          language,
          messages: res.data
        })
      } else {
        yield put({
          type: actions.CHANGE_LANG_FAILURE,
          language,
          messages: res.data
        })
      }
    }
  })
}

export function * getAppConfigsRequest () {
  yield takeEvery(actions.GET_DOMAIN_CONFIGS_REQUEST, function * () {
    try {
      let res = yield call(request.getAppConfigs)
      if (res.status === 200) {
        const configs = Array.isArray(res.data) ? res.data : []
        const currentLocation = window.location.pathname || ''
        const pathname = currentLocation.substring(1) // remove '/' from parthname
        const defaultConfig = configs.find(({ product }) => !product) || _.get(configs, '[0]', null)
        const pathConfig = configs.find(({ product }) => pathname === product) || defaultConfig
        if (!pathConfig) {
          yield put({
            type: actions.GET_DOMAIN_CONFIGS_FAILURE,
            domainConfigs: []
          })
        } else {
          yield put({
            type: actions.GET_DOMAIN_CONFIGS_SUCCESS,
            domainConfigs: configs
          })
          yield put({
            type: actions.SET_APP_CONFIG,
            ...pathConfig
          })
        }
      } else {
        yield put({
          type: actions.GET_DOMAIN_CONFIGS_FAILURE,
          domainConfigs: []
        })
      }
    } catch (error) {
      yield put({
        type: actions.GET_DOMAIN_CONFIGS_FAILURE,
        domainConfigs: []
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(changeLanguageRequest),
    fork(getAppConfigsRequest)
  ])
}
