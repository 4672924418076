import axios from 'axios'
import { getHeader } from '../../helpers/utility'

const putRequest = async (url, payload) => {
  if (payload === undefined) {
    return await axios.put(url, {}, { headers: getHeader() })
  } else {
    return await axios.put(url, payload, { headers: getHeader() })
  }
}

export default putRequest
