import postRequest from '../post'
import url from '../../endpoint/tokopediaCrackScreenEndorse'

const postTokopediaCrackScreenRequest = async ({ body = {}, params = {} }) => {
  try {
    const basrUrl = url.posttokopediaCrackScreenUrl(params)
    return await postRequest(basrUrl, body)
  } catch (error) {
    return error.response
  }
}

export default {
  postTokopediaCrackScreenRequest
}
