const actions = {
  GET_LAZADA_POLICY_DETAIL_REQUEST: 'GET_LAZADA_POLICY_DETAIL_REQUEST',
  GET_LAZADA_POLICY_DETAIL_SUCCESS: 'GET_LAZADA_POLICY_DETAIL_SUCCESS',
  GET_LAZADA_POLICY_DETAIL_FAILURE: 'GET_LAZADA_POLICY_DETAIL_FAILURE',
  GET_LAZADA_VEHICLE_POLICY_DETAIL_REQUEST: 'GET_LAZADA_VEHICLE_POLICY_DETAIL_REQUEST',
  GET_LAZADA_VEHICLE_POLICY_DETAIL_SUCCESS: 'GET_LAZADA_VEHICLE_POLICY_DETAIL_SUCCESS',
  GET_LAZADA_VEHICLE_POLICY_DETAIL_FAILURE: 'GET_LAZADA_VEHICLE_POLICY_DETAIL_FAILURE',
  GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_REQUEST: 'GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_REQUEST',
  GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_SUCCESS: 'GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_SUCCESS',
  GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_FAILURE: 'GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_FAILURE',
  POST_LAZADA_POLICY_DETAIL_REQUEST: 'POST_LAZADA_POLICY_DETAIL_REQUEST',
  POST_LAZADA_POLICY_DETAIL_SUCCESS: 'POST_LAZADA_POLICY_DETAIL_SUCCESS',
  POST_LAZADA_POLICY_DETAIL_FAILURE: 'POST_LAZADA_POLICY_DETAIL_FAILURE',
  RESET_LAZADA_ENDORSE_REDUX: 'RESET_LAZADA_ENDORSE_REDUX',
  GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_REQUEST: 'GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_REQUEST',
  GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_SUCCESS: 'GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_SUCCESS',
  GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_FAILURE: 'GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_FAILURE',
  GET_PROVINCE_DATA_REQUEST: 'GET_PROVINCE_DATA_REQUEST',
  GET_PROVINCE_DATA_SUCCESS: 'GET_PROVINCE_DATA_SUCCESS',
  GET_PROVINCE_DATA_FAILURE: 'GET_PROVINCE_DATA_FAILURE',

  getLazadaCrackScreenPolicyDetails: payload => ({
    type: actions.GET_LAZADA_POLICY_DETAIL_REQUEST,
    payload
  }),
  getLazadaVehiclePolicyDetails: payload => ({
    type: actions.GET_LAZADA_VEHICLE_POLICY_DETAIL_REQUEST,
    payload
  }),
  getLazadaFullGadgetProtectionPolicyDetails: payload => ({
    type: actions.GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_REQUEST,
    payload
  }),
  postLazadaCrackScreenEndorse: payload => ({
    type: actions.POST_LAZADA_POLICY_DETAIL_REQUEST,
    payload
  }),
  resetLazadaEndorseData: payload => ({
    type: actions.RESET_LAZADA_ENDORSE_REDUX,
    payload
  }),
  getLazadaHospitalCashPlanPolicyDetails: payload => ({
    type: actions.GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_REQUEST,
    payload
  }),
  getProvinceData: payload => ({
    type: actions.GET_PROVINCE_DATA_REQUEST,
    payload
  })
}
export default actions
