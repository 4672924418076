import GreenStar from './green_star.svg'
import InformationFilled from './Information_filled.svg'

export {
  InformationFilled,
  GreenStar
}

export const ArrowDown = `https://storage.googleapis.com/pp_img/claim_portal/ArrowDown.svg`
export const LogoutIcon = `https://storage.googleapis.com/pp_img/claim_portal/logout.svg`
export const LoaderIcon = `https://storage.googleapis.com/pp_img/claim_portal/loader.svg`
export const RightBlackIcon = `https://storage.googleapis.com/pp_img/claim_portal/right-black.svg`
export const PlayStoreIconBlack = `https://storage.googleapis.com/pp_img/claim_portal/playStoreIconBlack.svg`
export const AlertRedIcon = `https://storage.googleapis.com/pp_img/claim_portal/Alert.svg`
export const ProfileIcon = `https://storage.googleapis.com/pp_img/claim_portal/profile.svg`
export const DocumentIcon = `https://storage.googleapis.com/pp_img/claim_portal/Document.svg`
export const AppleAppStoteIcon = `https://storage.googleapis.com/pp_img/claim_portal/app-store.svg`
export const GooglePlayStoreIcon = `https://storage.googleapis.com/pp_img/claim_portal/google-play-store.svg`
export const StoreIcon = 'https://storage.googleapis.com/pp_img/claim_portal/storeIconBtn.svg'
export const CalenderIcon = `https://storage.googleapis.com/pp_img/claim_portal/calender-icon.svg`
export const CursorBlack = `https://storage.googleapis.com/pp_img/claim_portal/cursorBlack.svg`
export const GroupIcon = `https://storage.googleapis.com/pp_img/claim_portal/group-icon.svg`
export const MoneyBag = `https://storage.googleapis.com/pp_img/claim_portal/moneyBag.svg`
export const ArrowRight = `https://storage.googleapis.com/pp_img/claim_portal/arrowRight.svg`
export const SentIcon = `https://storage.googleapis.com/pp_img/claim_portal/Sent.svg`
export const CartIcon = `https://storage.googleapis.com/pp_img/claim_portal/Cart.svg`
export const PackageIcon = `https://storage.googleapis.com/pp_img/claim_portal/Package.svg`
export const TagIcon = `https://storage.googleapis.com/pp_img/claim_portal/Tag.svg`
export const LikedIcon = `https://storage.googleapis.com/pp_img/claim_portal/Likes.svg`
export const PolicyId = `https://storage.googleapis.com/pp_img/claim_portal/Policy%20Claim%20ID.svg`
export const InfoIcon = `https://storage.googleapis.com/pp_img/claim_portal/info.svg`
export const UplaodIcon = `https://storage.googleapis.com/pp_img/claim_portal/uplaod.svg`
export const whiteUploadIcon = `https://storage.googleapis.com/pp_img/claim_portal/upload-white.svg`
// export const CalenderIcon = `https://storage.googleapis.com/pp_img/claim_portal/calender-icon.svg`
export const ArrowSelection = `https://storage.googleapis.com/pp_img/claim_portal/Arrow%20Selection.svg`
export const DeleteIcon = `https://storage.googleapis.com/pp_img/claim_portal/Delete.svg`
export const ViewOnIcon = `https://storage.googleapis.com/pp_img/claim_portal/View%20On.svg`
export const PdfFallBackIcon = `https://storage.googleapis.com/pp_img/claim_portal/pdf.png`
export const creditCardIcon = `https://storage.googleapis.com/pp_img/claim_portal/Credit%20Card.svg`
export const bankIcon = `https://storage.googleapis.com/pp_img/claim_portal/Bank.svg`
export const HamBurgerIcon = `https://storage.googleapis.com/pp_img/claim_portal/Icon%20Right.svg`
export const IconLeftArrow = `https://storage.googleapis.com/pp_img/claim_portal/Icon%20Left.svg`
export const CheckLine = `https://storage.googleapis.com/pp_img/claim_portal/Check%20Line.svg`
// export const ArrowRight = `https://storage.googleapis.com/pp_img/claim_portal/Arrow%20Right.svg`
export const ImageBlock = `https://storage.googleapis.com/pp_img/claim_portal/Image%20Block.png`
export const ppBrokerLogo = `https://storage.googleapis.com/pp_img/ppib_assets/common/ppib-pasarpolis.png`
export const ppLogo = `https://storage.googleapis.com/pp_img/website/pp-logo-black-cap.png`
