const host = process.env.REACT_APP_URL
const BASE_PATH = 'api/v1/tanya/'

const getCommentsUrl = () => {
  return `${host}${BASE_PATH}comments/`
}

const addCommentUrl = () => {
  return `${host}${BASE_PATH}add-comments/`
}
const getTicketsUrl = () => {
  return `${host}${BASE_PATH}all-solved-tickets/`
}

export default {
  getCommentsUrl,
  addCommentUrl,
  getTicketsUrl
}
