const host = process.env.REACT_APP_URL || ''

const getUserProfile = () => {
  let base = `${host}api/v1/user/profile/`
  return base
}

const getUserDetails = () => {
  let base = `${host}api/v1/user/details/`
  return base
}

const getLocations = () => {
  let base = `${host}api/v1/user/locations/`
  return base
}

const sendOtp = () => {
  return `${host}api/v1/user/send_otp/`
}

const verifyOtp = () => {
  return `${host}api/v1/user/verify_otp/`
}

const updatePhone = () => {
  return `${host}api/v1/user/update-phone/`
}

const updateEmail = () => {
  return `${host}api/v1/user/update-email/`
}

const addEmail = () => {
  return `${host}api/v1/user/add-email/`
}

const uploadFile = () => {
  return `${host}api/v1/file/upload/`
}

const documents = () => {
  return `${host}api/v1/user/documents/`
}

const bankAccounts = () => {
  return `${host}api/v1/user/bank-account/`
}

const verifyBankAccount = () => {
  return `${host}api/v1/claim/verify-bank-account/`
}

export default {
  getUserProfile,
  getUserDetails,
  getLocations,
  sendOtp,
  verifyOtp,
  updatePhone,
  updateEmail,
  addEmail,
  uploadFile,
  documents,
  bankAccounts,
  verifyBankAccount
}
