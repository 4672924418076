import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import request from '../../request/axios/lazadaCrackScreenEndorse'
import _ from 'lodash/object'

export function * getLazadaCrackScreenRequest () {
  yield takeEvery(actions.GET_LAZADA_POLICY_DETAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getLazadaCrackScreenPolicyDetails, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_LAZADA_POLICY_DETAIL_SUCCESS,
          message: 'Sukses',
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_LAZADA_POLICY_DETAIL_FAILURE,
          message: _.get(res, 'data.message', _.get(res, 'data.errors', '')),
          data: {}
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_LAZADA_POLICY_DETAIL_FAILURE,
        message: 'Gagal mendapatkan detil',
        data: {}
        // TODO: move to constant
      })
    }
  })
}
export function * getLazadaVehicleDetailRequest () {
  yield takeEvery(actions.GET_LAZADA_VEHICLE_POLICY_DETAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getLazadaVehiclePolicyDetails, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_LAZADA_VEHICLE_POLICY_DETAIL_SUCCESS,
          message: 'Sukses',
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_LAZADA_VEHICLE_POLICY_DETAIL_FAILURE,
          message: _.get(res, 'data.message', _.get(res, 'data.errors', '')),
          data: {}
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_LAZADA_POLICY_DETAIL_FAILURE,
        message: 'Gagal mendapatkan detil',
        data: {}
        // TODO: move to constant
      })
    }
  })
}
export function * getLazadaFullGadgetProtectionRequest () {
  yield takeEvery(actions.GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getLazadaFullGadgetProtectionPolicyDetails, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_SUCCESS,
          message: 'Sukses',
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_FAILURE,
          message: _.get(res, 'data.message', _.get(res, 'data.errors', '')),
          data: {}
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_FAILURE,
        message: 'Gagal mendapatkan detil',
        data: {}
        // TODO: move to constant
      })
    }
  })
}
export function * getPostLazadaCrackScreenEndorseRequest (props) {
  yield takeEvery(actions.POST_LAZADA_POLICY_DETAIL_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.postLazadaCrackScreenPolicyEndorse, payload)
      if (res.status === 200) {
        yield put({
          type: actions.POST_LAZADA_POLICY_DETAIL_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.POST_LAZADA_POLICY_DETAIL_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.POST_LAZADA_POLICY_DETAIL_FAILURE,
          message: e.data.error
        })
      }
    }
  })
}

export function * getLazadaHospitalCashPlanEndorseRequest (props) {
  yield takeEvery(actions.GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getLazadaHospitalCashPlanEndorse, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_FAILURE,
          message: e.data.error
        })
      }
    }
  })
}

export function * getprovinceDataRequest () {
  yield takeEvery(actions.GET_PROVINCE_DATA_REQUEST, function * () {
    try {
      let res = yield call(request.getprovinceData)
      if (res.status === 200) {
        yield put({
          type: actions.GET_PROVINCE_DATA_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_PROVINCE_DATA_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.GET_PROVINCE_DATA_FAILURE,
          message: e.data.error
        })
      }
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getLazadaCrackScreenRequest),
    fork(getPostLazadaCrackScreenEndorseRequest),
    fork(getLazadaFullGadgetProtectionRequest),
    fork(getLazadaVehicleDetailRequest),
    fork(getLazadaHospitalCashPlanEndorseRequest),
    fork(getprovinceDataRequest)
  ])
}
