import axios from 'axios'

const getRequest = async (url, payload) => {
  let headers = {}
  if (payload !== undefined && Object.keys(payload).length > 0) {
    headers = { params: payload }
  }
  return await axios.get(url, headers)
}

export default getRequest
