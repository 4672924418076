const actions = {
  POLICY_LIST: 'POLICY_LIST',
  POLICY_LIST_START: 'POLICY_LIST_START',
  CLAIM_LIST: 'CLAIM_LIST',
  POLICY_CLAIMS_LIST: 'POLICY_CLAIMS_LIST',
  POLICY_DETAILS_REQUEST: 'POLICY_DETAILS_REQUEST',
  POLICY_LIST_RESULT: 'POLICY_LIST_RESULT',
  CLAIM_LIST_RESULT: 'CLAIM_LIST_RESULT',
  POLICY_CLAIM_LIST_RESULT: 'POLICY_CLAIM_LIST_RESULT',
  POLICY_DETAILS_SUCCESS: 'POLICY_DETAILS_SUCCESS',
  FAILED_GET_POLICY_LIST: 'FAILED_GET_POLICY_LIST',
  FAILED_GET_POLICY_CLAIMS_LIST: 'FAILED_GET_POLICY_CLAIMS_LIST',
  FAILED_GET_POLICY_DETAIL: 'POLICY_DETAILS_FAILURE',
  FAILED_GET_CLAIM_LIST: 'FAILED_GET_CLAIM_LIST',
  AGGREGATE_POLICY_DETAILS_REQUEST: 'AGGREGATE_POLICY_DETAILS_REQUEST',
  AGGREGATE_POLICY_DETAILS_SUCCESS: 'AGGREGATE_POLICY_DETAILS_SUCCESS',
  AGGREGATE_POLICY_DETAILS_FAILURE: 'AGGREGATE_POLICY_DETAILS_FAILURE',
  TOKENIZED_ENDORSEMENT_DETAIL_REQUEST: 'TOKENIZED_ENDORSEMENT_DETAIL_REQUEST',
  TOKENIZED_ENDORSEMENT_DETAIL_SUCCESS: 'TOKENIZED_ENDORSEMENT_DETAIL_SUCCESS',
  TOKENIZED_ENDORSEMENT_DETAIL_FAILURE: 'TOKENIZED_ENDORSEMENT_DETAIL_FAILURE',
  TOKENIZED_ENDORSEMENT_REQUEST: 'TOKENIZED_ENDORSEMENT_REQUEST',
  TOKENIZED_ENDORSEMENT_SUCCESS: 'TOKENIZED_ENDORSEMENT_SUCCESS',
  TOKENIZED_ENDORSEMENT_FAILURE: 'TOKENIZED_ENDORSEMENT_FAILURE',
  AUTHORIZED_ENDORSEMENT_REQUEST: 'AUTHORIZED_ENDORSEMENT_REQUEST',
  UNAUTHORIZED_ENDORSEMENT_REQUEST: 'UNAUTHORIZED_ENDORSEMENT_REQUEST',
  AUTHORIZED_ENDORSEMENT_FAILURE: 'AUTHORIZED_ENDORSEMENT_FAILURE',
  AUTHORIZED_ENDORSEMENT_SUCCESS: 'AUTHORIZED_ENDORSEMENT_SUCCESS',
  UNAUTHORIZED_ENDORSEMENT_FAILURE: 'UNAUTHORIZED_ENDORSEMENT_FAILURE',
  UNAUTHORIZED_ENDORSEMENT_SUCCESS: 'UNAUTHORIZED_ENDORSEMENT_SUCCESS',
  RESET_REDUX_POLICY: 'RESET_REDUX_POLICY',
  FILTERED_POLICY: 'FILTERED_POLICY',

  SEND_VERIFICATION_OTP_REQUEST: 'SEND_VERIFICATION_OTP_REQUEST',
  SEND_VERIFICATION_OTP_SUCCESS: 'SEND_VERIFICATION_OTP_SUCCESS',
  SEND_VERIFICATION_OTP_FAILURE: 'SEND_VERIFICATION_OTP_FAILURE',

  RESEND_VERIFICATION_OTP_REQUEST: 'RESEND_VERIFICATION_OTP_REQUEST',
  RESEND_VERIFICATION_OTP_SUCCESS: 'RESEND_VERIFICATION_OTP_SUCCESS',
  RESEND_VERIFICATION_OTP_FAILURE: 'RESEND_VERIFICATION_OTP_FAILURE',

  VERIFY_VERIFICATION_OTP_REQUEST: 'VERIFY_VERIFICATION_OTP_REQUEST',
  VERIFY_VERIFICATION_OTP_SUCCESS: 'VERIFY_VERIFICATION_OTP_SUCCESS',
  VERIFY_VERIFICATION_OTP_FAILURE: 'VERIFY_VERIFICATION_OTP_FAILURE',

  POLICY_LIST_REQUEST: 'POLICY_LIST_REQUEST',
  POLICY_LIST_SUCCESS: 'POLICY_LIST_SUCCESS',
  POLICY_LIST_FAILURE: 'POLICY_LIST_FAILURE',

  CLEAR_POLICY_REDUX: 'CLEAR_POLICY_REDUX',

  GET_CLAIM_LIST_REQUEST: 'GET_CLAIM_LIST_REQUEST',
  GET_CLAIM_LIST_SUCCESS: 'GET_CLAIM_LIST_SUCCESS',
  GET_CLAIM_LIST_FAILURE: 'GET_CLAIM_LIST_FAILURE',

  getPolicyList: payload => ({
    type: actions.POLICY_LIST,
    payload
  }),
  getClaimList: payload => ({
    type: actions.CLAIM_LIST,
    payload
  }),
  getPolicyClaimsList: payload => ({
    type: actions.POLICY_CLAIMS_LIST,
    payload
  }),
  getPolicyDetails: id => ({
    type: actions.POLICY_DETAILS_REQUEST,
    payload: id
  }),
  getAggregatePolicyDetails: payload => ({
    type: actions.AGGREGATE_POLICY_DETAILS_REQUEST,
    payload
  }),
  resetReduxPolicy: (payload) => ({
    type: actions.RESET_REDUX_POLICY,
    payload
  }),
  tokenizedDetailEndorsement: payload => ({
    type: actions.TOKENIZED_ENDORSEMENT_DETAIL_REQUEST,
    payload
  }),
  tokenizedEndorsement: payload => ({
    type: actions.TOKENIZED_ENDORSEMENT_REQUEST,
    payload
  }),
  authorisedEndorsement: payload => ({
    type: actions.AUTHORIZED_ENDORSEMENT_REQUEST,
    payload
  }),
  unAuthorisedEndorsement: payload => ({
    type: actions.UNAUTHORIZED_ENDORSEMENT_REQUEST,
    payload
  }),
  filteredPolicy: payload => ({
    type: actions.FILTERED_POLICY,
    payload
  }),
  sendOtp: (payload) => ({
    type: actions.SEND_VERIFICATION_OTP_REQUEST,
    payload
  }),
  resendOtp: (payload) => ({
    type: actions.RESEND_VERIFICATION_OTP_REQUEST,
    payload
  }),
  verifyOtp: (payload) => ({
    type: actions.VERIFY_VERIFICATION_OTP_REQUEST,
    payload
  }),
  clearPolicyRedux: () => ({
    type: actions.CLEAR_POLICY_REDUX
  })
}
export default actions
