import { get } from 'lodash'
import actions from './actions'
import agencyClaimActions from '../agency/actions'
import _ from 'lodash/object'

export const initState = {
  data: [],
  failedGetBenefitLists: '',
  formBenefit: [],
  failedGetFormBenefitLists: '',
  claimId: '',
  failedPostClaim: '',
  createClaimStatus: '',
  getClaimDetailStatus: '',
  claimDetails: {},
  commentsList: [],
  uploadCommentDoc: {},
  message: '',
  saveAttributesGenericDocStatus: {},
  saveGenericAttributesStatus: '',
  finalSubmitStatus: '',
  verifyBankAccountFormStatus: '',
  verifyAccountData: undefined,
  successGetBenefitLists: '',
  validateClaimStatus: '',
  feedbackForms: {},
  salvageForms: {},
  getFeedbackFormStatus: '',
  postFeedbackFormStatus: '',
  postSalvageFormStatus: '',
  validateClaimStatusCode: undefined,
  uploadGenericClaimDocLoading: {},
  createShippingClaimStatus: undefined,
  shippingClaimData: {},
  uploadVerificationFileError: '',
  ocrSuccessFull: false,
  ocrNotRequired: false,
  successGetCommentLists: false,
  ticketList: [],
  successAddCommentLists: false,
  failedCommentMessage: '',
  benefitsList: [],
  uploadGenericClaimDocError: {},
  serviceCenterList: [],
  provinceList: [],
  cityListOnProvince: [],
  branchList: [],
  totalServiceCenterList: [],
  totalQuestionsList: [],
  serviceCenterBrandList: [],
  serviceCenterBranchListMessage: '',
  isMultiBenefitClaimable: false,
  dataValidate: {},
  tapTravelUserList: [],
  familyEligibleUserBenefitRemainingTSI: null,
  formLoadingStates: {},
  associatedUserList: []
}

export default function policy (state = initState, action) {
  let errorStates = {}
  let mergedState = {}
  switch (action.type) {
    case actions.RESET_STATE:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...initState
        }
      }
    case actions.BENEFIT_LISTS:
      return {
        ...state,
        data: action.data,
        successGetBenefitLists: action.successGetBenefitLists
      }
    case actions.FAILED_GET_BENEFITS:
      return {
        ...state,
        failedGetBenefitLists: action.failedGetBenefitLists
      }
    case actions.SUCCESS_CLAIM:
      return {
        ...state,
        claimId: action.claimId,
        createClaimStatus: action.createClaimStatus
      }
    case actions.FAILED_CLAIM:
      return {
        ...state,
        createClaimStatus: action.createClaimStatus
      }
    case actions.GET_CLAIM_DETAILS_SUCCESS:
    case actions.GET_CLAIM_DETAILS_FAILURE:
      return {
        ...state,
        claimDetails: action.claimDetails,
        getClaimDetailStatus: action.getClaimDetailStatus
      }
    case actions.UPDATE_CLAIM_DETAILS:
      return {
        ...state,
        claimDetails: action.claimDetails
      }
    case actions.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsList: action.commentsList || [],
        successGetCommentLists: action.successGetCommentLists
      }
    case actions.GET_TICKETS_SUCCESS:
      return {
        ...state,
        ticketList: action.ticketList || [],
        successGetTicketLists: action.successGetTicketLists
      }
    case actions.GET_TICKETS_FAILURE:
      return {
        ...state,
        ticketList: [],
        successGetTicketLists: action.successGetTicketLists
      }
    case actions.ADD_COMMENT_REQUEST:
      return {
        ...state,
        successGetCommentLists: false
      }
    case actions.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        successAddCommentLists: action.successAddCommentLists
      }

    case actions.ADD_COMMENT_FAILURE:
      return {
        ...state,
        successAddCommentLists: action.successAddCommentLists,
        failedCommentMessage: action.failedCommentMessage
      }

    case actions.UPLOAD_COMMENT_DOC_SUCCESS:
    case actions.UPLOAD_COMMENT_DOC_FAILURE:
      return {
        ...state,
        uploadCommentDoc: action.data
      }
    case actions.UPLOAD_GENERIC_CLAIM_DOC_SUCCESS:
    case actions.UPLOAD_GENERIC_CLAIM_DOC_FAILURE:
    case actions.UPDATE_CLAIM_FORM_WITH_GENERIC_DOC:
      return {
        ...state,
        genericDataDocUpload: action.data,
        saveAttributesGenericDocStatus: action.saveAttributesGenericDocStatus
      }
    case actions.UPDATE_UPLOAD_GENERIC_CLAIM_DOC_ERROR_STATES:
      errorStates = _.merge(state.uploadGenericClaimDocError, action.errorTarget)
      mergedState = _.merge(state.uploadGenericClaimDocLoading, action.target)
      return {
        ...state,
        uploadGenericClaimDocError: {
          ...errorStates
        },
        uploadGenericClaimDocLoading: {
          ...mergedState
        }
      }

    case actions.UPDATE_UPLOAD_GENERIC_CLAIM_DOC_LOADING_STATES:
      mergedState = _.merge(state.uploadGenericClaimDocLoading, action.target)
      errorStates = _.merge(state.uploadGenericClaimDocError, action.errorTarget)
      return {
        ...state,
        uploadGenericClaimDocLoading: {
          ...mergedState
        },
        uploadGenericClaimDocError: {
          ...errorStates
        }
      }

    case actions.UPDATE_FORM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        uploadGenericClaimDocLoading: {},
        uploadGenericClaimDocError: {}
      }
    case actions.SAVE_GENERIC_ATTRIBUTES_REQUEST:
      return {
        ...state,
        formLoadingStates: {
          ...state.formLoadingStates,
          [action.payload.form]: true
        }
      }
    case actions.SAVE_GENERIC_ATTRIBUTES_SUCCESS:
    case actions.SAVE_GENERIC_ATTRIBUTES_FAILURE:
      return {
        ...state,
        formLoadingStates: {
          ...state.formLoadingStates,
          [action.payload.form]: false
        },
        saveGenericAttributesStatus: {
          ...state.saveGenericAttributesStatus,
          [action.payload.form]: action.saveGenericAttributesStatus
        }
      }
    case actions.FINAL_SUBMIT_SUCCESS:
    case actions.FINAL_SUBMIT_FAILURE:
      return {
        ...state,
        finalSubmitStatus: action.finalSubmitStatus
      }
    case actions.VERIFY_BANK_ACCOUNT_FORMS_SUCCESS:
    case actions.VERIFY_BANK_ACCOUNT_FORMS_FAILURE:
      return {
        ...state,
        verifyBankAccountFormStatus: action.status,
        verifyAccountData: action.data
      }
    case actions.VALIDATE_CLAIM_STATUS:
      return {
        ...state,
        validateClaimStatus: action.validateClaimStatus,
        dataValidate: action.dataValidate,
        validateClaimStatusCode: action.validateClaimStatusCode
      }
    case actions.GET_FEEDBACK_FORMS_SUCCESS:
    case actions.GET_FEEDBACK_FORMS_FAILURE:
      return {
        ...state,
        feedbackForms: action.feedbackForms,
        getFeedbackFormStatus: action.getFeedbackFormStatus
      }
    case actions.POST_FEEDBACK_FORMS_STATUS:
      return {
        ...state,
        postFeedbackFormStatus: action.postFeedbackFormStatus
      }
    case actions.POST_SALVAGE_FORMS_SUCCESS:
    case actions.POST_SALVAGE_FORMS_FAILURE:
      return {
        ...state,
        postSalvageFormStatus: action.postSalvageFormStatus
      }
    case actions.CREATE_SHIPPING_CLAIM_RESULT:
      return {
        ...state,
        createShippingClaimStatus: action.status,
        shippingClaimData: action.data
      }
    case actions.CREATE_VERIFY_IMAGE_SUCCESS:
    case actions.CREATE_VERIFY_IMAGE_FAILURE:
      return {
        ...state,
        verifyingImage: action.verifyingImage
      }
    case actions.UPLOAD_VERIFICATION_CLAIM_FILE_FAILURE:
    case actions.UPLOAD_VERIFICATION_CLAIM_FILE_SUCCESS:
    case actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE:
      return {
        ...state,
        uploadGenericClaimDocLoading: false,
        uploadVerificationFileError: action.message,
        uploadVerificationFileErrorData: action.data
      }
    case actions.POLLING_VERIFY_IMAGE_SUCCESS:
    case actions.POLLING_VERIFY_IMAGE_FAILURE:
      return {
        ...state,
        verifyImageData: action.verifyImageData,
        ocrSuccessFull: action.ocrSuccessFull,
        ocrNotRequired: action.ocrNotRequired,
        verifyImageMessage: action.message
      }
    case actions.VERIFY_IMAGE_CONTENT_SUCCESS:
    case actions.VERIFY_IMAGE_CONTENT_FAILURE:
      return {
        ...state,
        resultDict: action.resultDict,
        allValueMatches: action.allValueMatches,
        verifyImageContentMessage: action.message
      }
    case actions.SET_VERIFYING_DOCUMENT_URL:
      return {
        ...state,
        verifyingDocumentUrl: action.url
      }
    case actions.GET_BENEFITS_LIST_REQUEST:
      return {
        ...state,
        benefitsListLoading: true
      }
    case actions.GET_BENEFITS_LIST_SUCCESS:
    case actions.GET_BENEFITS_LIST_FAILURE:
      return {
        ...state,
        benefitsListLoading: false,
        benefitsList: action.data?.benefit_list_data,
        isMultiBenefitClaimable: action.data?.is_multi_benefit_claimable,
        getBenefitsListMessage: action.message
      }
    case actions.CLEAR_UPLOAD_ERROR:
      return {
        ...state,
        uploadVerificationFileError: ''
      }
    case actions.GET_CLAIM_BANK_LIST_SUCCESS:
    case actions.GET_CLAIM_BANK_LIST_FAILURE:
      return {
        ...state,
        claimBankList: action.data
      }
    case actions.GET_CHOICES_EXTERNAL_DATA_SUCCESS:
    case actions.GET_CHOICES_EXTERNAL_DATA_FAILURE:
      return {
        ...state,
        choicesExternalData: action.data
      }
    case actions.GET_SERVICE_CENTER_LIST_SUCCESS:
    case actions.GET_SERVICE_CENTER_LIST_FAILURE:
      return {
        ...state,
        serviceCenterList: action.data.map(({ display_name: displayName, value }) => ({ value, displayName }))
      }

    case actions.GET_PROVINCE_LIST_SUCCESS:
    case actions.GET_PROVINCE_LIST_FAILURE:
      return {
        ...state,
        provinceList: action.data.map(({ display_name: displayName, value }) => ({ value, displayName })),
        provinceErrorMessage: get(action, 'message', '')
      }
    case actions.GET_CITY_LIST_ON_PROVINCE_SUCCESS:
    case actions.GET_CITY_LIST_ON_PROVINCE_FAILURE:
      return {
        ...state,
        cityListOnProvince: action.data.map(({ display_name: displayName, value }) => ({ value, displayName }))
      }

    case actions.GET_POSTAL_CODE_LIST_SUCCESS:
    case actions.GET_POSTAL_CODE_LIST_FAILURE:
      return {
        ...state,
        postalCodeList: action.data.map(({ display_name: displayName, value }) => ({ value, displayName }))
      }

    case actions.GET_SERVICE_CENTER_BRANCH_LIST_SUCCESS:
    case actions.GET_SERVICE_CENTER_BRANCH_LIST_FAILURE:
      return {
        ...state,
        serviceCenterBranchListMessage: action.message,
        branchList: action.data.map(({ branch_code: branchCode, name, address, specializing }) => ({
          value: branchCode,
          displayName: `${name} - ${address}`,
          specializing
        }))
      }
    case agencyClaimActions.CLEAR_BENEFIT_LIST:
      return {
        ...state,
        benefitsList: [],
        isMultiBenefitClaimable: false,
        getBenefitsListMessage: ''
      }

    case actions.GET_TOTAL_SERVICE_CENTER_LIST_FAILURE:
    case actions.GET_TOTAL_SERVICE_CENTER_LIST_SUCCESS:
      return {
        ...state,
        totalServiceCenterList: get(action, 'data', [])
      }

    case actions.GET_QUESTIONS_LIST_SUCCESS:
    case actions.GET_QUESTIONS_LIST_FAILURE:
      return {
        ...state,
        totalQuestionsList: action.data,
        totalQuestionsListMessage: action.message
      }

    case actions.GET_SERVICE_CENTER_BRAND_LIST_SUCCESS:
    case actions.GET_SERVICE_CENTER_BRAND_LIST_FAILURE:
      return {
        ...state,
        serviceCenterBrandList: action.data?.map(({ name, code }) => ({ displayName: name, value: code }))
      }
    case actions.GET_FAMILY_ELIGIBLIE_USER_LIST_SUCCESS:
    case actions.GET_FAMILY_ELIGIBLIE_USER_LIST_FAILURE:
      return {
        ...state,
        tapTravelUserList: action.userList || [],
        familyEligibleUserBenefitRemainingTSI: action.benefitRemainingTSI,
        getFamilyBenefitUserMessage: action.message
      }
    case actions.POST_FAMILY_CREATE_CLAIM_FAILURE:
    case actions.POST_FAMILY_CREATE_CLAIM_SUCCESS:
      return {
        ...state,
        claimId: action.claimId,
        createClaimStatus: action.createClaimStatus
      }
    case actions.GET_ASSOCIATED_USER_ON_CLAIM_SUCCESS:
    case actions.GET_ASSOCIATED_USER_ON_CLAIM_FAILURE:
      return {
        ...state,
        associatedUserList: action.data
      }
    default:
      return state
  }
}
