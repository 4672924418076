import { getDefaultPath } from '../../helpers/urlSync'
import actions, { getView } from './actions'
import config, {
  getCurrentLanguage
} from '../../containers/LanguageSwitcher/config'

const preKeys = getDefaultPath()
const initState = {
  collapsed: !window.innerWidth > 1220,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  mobileView: false,
  language: getCurrentLanguage(config.defaultLanguage),
  messages: {}
}
export default function appReducer (state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      return { ...state, collapsed: !state.collapsed }
    case actions.COLLPSE_OPEN_DRAWER:
      return { ...state, openDrawer: !state.openDrawer }
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height || state.mobileView !== action.mobileView) {
        const height = action.height ? action.height : state.height
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height: height,
          mobileView: action.mobileView
        }
      }
      break
    case actions.CHANGE_OPEN_KEYS:
      return { ...state, openKeys: action.openKeys }
    case actions.CHANGE_CURRENT:
      return { ...state, current: action.current }
    case actions.CLOSE_ALL:
      return { ...state, current: [], openKeys: [] }
    case actions.CHANGE_LANG_SUCCESS:
      return {
        ...state,
        language: action.language,
        messages: action.messages
      }
    case actions.CHANGE_LANG_FAILURE:
      return {
        ...state,
        language: action.language,
        messages: {}
      }
    case actions.GET_DOMAIN_CONFIGS_SUCCESS:
    case actions.GET_DOMAIN_CONFIGS_FAILURE:
      return {
        ...state,
        domainConfigs: action.domainConfigs
      }
    case actions.SET_APP_CONFIG:
      return {
        ...state,
        config: action.config,
        product: action.product,
        partner: action.partner
      }
    default:
      return state
  }
  return state
}
