import actions from './actions'

const initState = {
  idToken: null,
  generateOtpStatus: '',
  errorMessage: '',
  verifyOtpFailedStatus: '',
  operationId: '',
  isWhatsAppLogin: false,
  countryCode: '62',
  authStatus: false,
  ssoTokenVerified: false,
  tokenFailureMessage: '',
  serviceList: []
}

export default function authReducer (state = initState, action) {
  switch (action.type) {
    case actions.RESET_REDUX_STATES:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state,
          ...initState
        }
      }
    case actions.GENERATE_OTP_SUCCESS:
      return {
        ...state,
        generateOtpStatus: action.status,
        operationId: action.operationId,
        phone: action.phone
      }
    case actions.GENERATE_OTP_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage
      }
    case actions.WHATS_APP_OTP_CHECK_SUCCESS:
      return {
        ...state,
        whatsAppOtpdata: action.data,
        operationId: action.operationId
      }
    case actions.AUTH_VERIFY_OTP_SUCCESS:
    case actions.AUTH_VERIFY_OTP_FAILURE:
      return {
        ...state,
        idToken: action.token,
        serviceList: action.serviceList,
        profile: action.payload,
        verifyOtpError: action.otpErrorMessage,
        verifyOtpMessage: action.message
      }
    case actions.RESET_STATUS:
      return {
        ...state,
        errorMessage: '',
        verifyOtpFailedStatus: '',
        verifyOtpError: '',
        verifyOtpMessage: '',
        isWhatsAppLogin: false,
        operationId: ''
      }
    case actions.CLEAR_VERIFY_OTP_ERROR:
      return {
        ...state,
        verifyOtpError: '',
        errorMessage: ''
      }
    case actions.UPDATE_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload
      }
    case actions.WHATS_APP_LOGIN:
      return {
        ...state,
        isWhatsAppLogin: action.payload
      }
    case actions.LOGOUT_SUCCESS:
      return {
        ...initState,
        logoutErrorMessage: action.message
      }
    case actions.LOGOUT_FAILURE:
      return {
        ...initState,
        logoutErrorMessage: action.message
      }
    case actions.LOGOUT:
      return initState
    case actions.AUTH_SUCCESS:
      return {
        ...state,
        authStatus: true
      }
    case actions.AUTH_FAILURE:
      return {
        ...state,
        authStatus: false
      }
    case actions.SSO_TOKEN_VERIFIED:
      return {
        ...state,
        ssoTokenVerified: true
      }
    case actions.SSO_TOKEN_FAILURE:
      return {
        ...state,
        ssoTokenVerified: false,
        tokenFailureMessage: action.message
      }
    default:
      return state
  }
}
