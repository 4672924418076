const actions = {
  SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_REQUEST: 'SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_REQUEST',
  SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_SUCCESS: 'SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_SUCCESS',
  SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_FAILURE: 'SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_FAILURE',
  RESET_TOKOPEDIA_CRACK_SCREEN_ENDORSE_REDUX: 'RESET_TOKOPEDIA_CRACK_SCREEN_ENDORSE_REDUX',
  postTokopediaCrackScreenForm: (payload) => ({
    type: actions.SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_REQUEST,
    payload
  }),
  resetTokopediaCrackScreenEndorseRedux: (payload) => ({
    type: actions.RESET_TOKOPEDIA_CRACK_SCREEN_ENDORSE_REDUX,
    payload
  })
}
export default actions
