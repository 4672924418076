import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import request from '../../request/axios/endorsement'
import _ from 'lodash/object'
import { ERROR_STRINGS } from '../../constants/lang'

export function * submitGosureEndorseRequest (props) {
  yield takeEvery(actions.SUBMIT_GOSURE_ENDORSE_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.postGosureEndorseRequest, payload)
      if (res.status === 200) {
        yield put({
          type: actions.SUBMIT_GOSURE_ENDORSE_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SUBMIT_GOSURE_ENDORSE_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.SUBMIT_GOSURE_ENDORSE_FAILURE,
          message: _.get(e, 'data.error', 'Terjadi Kesalahan')
        })
      }
    }
  })
}

export function * submitAggregateEndorseRequest (props) {
  yield takeEvery(actions.SUBMIT_AGGREGATE_ENDORSE_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.postAggregateEndorseRequest, payload)
      if (res.status === 200) {
        yield put({
          type: actions.SUBMIT_AGGREGATE_ENDORSE_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SUBMIT_AGGREGATE_ENDORSE_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.SUBMIT_GOSURE_ENDORSE_FAILURE,
          message: _.get(e, 'data.error', 'Terjadi Kesalahan')
        })
      }
    }
  })
}

export function * getBikeBrand () {
  yield takeEvery(actions.GET_BIKE_BRAND_REQUEST, function * () {
    let res = yield call(request.getBikebrandList)
    if (res.status === 200) {
      yield put({
        type: actions.GET_BIKE_BRAND_SUCCESS,
        data: res.data,
        tagBrandStatus: 'success'
      })
    } else {
      yield put({
        type: actions.GET_BIKE_BRAND_FAILURE,
        tagBrandStatus: ERROR_STRINGS.serverError
      })
    }
  })
}

export function * getBikeModel () {
  yield takeEvery(actions.GET_BIKE_MODEL_REQUEST, function * () {
    let res = yield call(request.getBikeModelList)
    if (res.status === 200) {
      yield put({
        type: actions.GET_BIKE_MODEL_SUCCESS,
        data: res.data,
        tagModelStatus: 'success'
      })
    } else {
      yield put({
        type: actions.GET_BIKE_MODEL_FAILURE,
        tagModelStatus: ERROR_STRINGS.serverError
      })
    }
  })
}

export function * getNationalityData () {
  yield takeEvery(actions.GET_NATIONALITY_REQUEST, function * () {
    try {
      const res = yield call(request.getNationalityData)
      if (res.status === 200) {
        yield put({
          type: actions.GET_NATIONALITY_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_NATIONALITY_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.GET_NATIONALITY_FAILURE,
          message: _.get(e, 'data.error', 'Terjadi Kesalahan')
        })
      }
    }
  })
}

export function * getCityDataSaga () {
  yield takeEvery(actions.GET_CITY_REQUEST, function * () {
    try {
      const res = yield call(request.getCityData)
      if (res.status === 200) {
        yield put({
          type: actions.GET_CITY_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_CITY_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.GET_CITY_FAILURE,
          message: _.get(e, 'data.error', 'Terjadi Kesalahan')
        })
      }
    }
  })
}

export function * getDistrictDataSaga () {
  yield takeEvery(actions.GET_DISTRICT_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getDistrictData, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_DISTRICT_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_DISTRICT_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.GET_DISTRICT_FAILURE,
          message: _.get(e, 'data.error', 'Terjadi Kesalahan')
        })
      }
    }
  })
}

export function * getTownDataSaga () {
  yield takeEvery(actions.GET_TOWN_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getTownData, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_TOWN_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_TOWN_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.GET_TOWN_FAILURE,
          message: _.get(e, 'data.error', 'Terjadi Kesalahan')
        })
      }
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(submitGosureEndorseRequest),
    fork(submitAggregateEndorseRequest),
    fork(getBikeBrand),
    fork(getBikeModel),
    fork(getNationalityData),
    fork(getCityDataSaga),
    fork(getDistrictDataSaga),
    fork(getTownDataSaga)
  ])
}
