import { getCurrentLanguage } from '../../containers/LanguageSwitcher/config'

export function getView (width) {
  let newView = 'MobileView'
  if (width > 1220) {
    newView = 'DesktopView'
  } else if (width > 767) {
    newView = 'TabView'
  }
  return newView
}
const actions = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLOSE_ALL: 'CLOSE_ALL',
  CHANGE_LANG_REQUEST: 'CHANGE_LANG_REQUEST',
  CHANGE_LANG_SUCCESS: 'CHANGE_LANG_SUCCESS',
  CHANGE_LANG_FAILURE: 'CHANGE_LANG_FAILURE',
  GET_DOMAIN_CONFIGS_REQUEST: 'GET_DOMAIN_CONFIGS_REQUEST',
  GET_DOMAIN_CONFIGS_SUCCESS: 'GET_DOMAIN_CONFIGS_SUCCESS',
  GET_DOMAIN_CONFIGS_FAILURE: 'GET_DOMAIN_CONFIGS_FAILURE',
  SET_APP_CONFIG: 'SET_APP_CONFIG',
  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE
  }),
  toggleAll: (width, height) => {
    const view = getView(width)
    const collapsed = view !== 'DesktopView'
    const mobileView = view === 'MobileView'
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
      mobileView
    }
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER
  }),
  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current
  }),
  closeAll: () => ({ type: actions.CLOSE_ALL }),
  changeLang: (language, countryCode) => {
    return {
      type: actions.CHANGE_LANG_REQUEST,
      language: getCurrentLanguage(language, countryCode)
    }
  },
  getDomainConfigs: () => ({
    type: actions.GET_DOMAIN_CONFIGS_REQUEST
  }),
  setAppConfig: payload => ({
    type: actions.SET_APP_CONFIG,
    config: payload
  })
}
export default actions
