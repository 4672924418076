import styled from 'styled-components'
import { COLORS } from '../../constants/colors'

export const HeaderLogoImage = styled.img`
    max-width: 200px;
    max-height: 70px;
`

export const HeaderContainer = styled.div`
  display: flex;
  height: 80px;
  padding: 0px 160px 0px 152px;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.headerBackground};
`
export const Select = styled.select`
    background: transparent;
    border: 0px
`

export const Option = styled.option``

export const LeftSideLogoContainer = styled.div`
    display: flex;
    gap: 16px;
    height: 100%;
    align-items: center;
`

export const HeaderLogoContainer = styled.div`
  border-bottom: ${props => props.active ? '1px' : '0px'} solid ${COLORS.black};
`

export const NavigationLinksListWrapper = styled.div`
    height: 100%;
    display: flex;
`

export const NavigationLinksWrapper = styled.div`
    color: ${props => props.active ? COLORS.black : props.textColor};
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    height: 100%;
    border-bottom:  ${props => props.active ? `2px solid ${COLORS.black}` : '0px'};
    align-items: center;
    display: flex;
    padding: 0px 16px;

    text-transform: capitalize;
`

export const ProfileUrlImage = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`

export const RightSideContainer = styled.div`
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
`

export const UserNameTitle = styled.div`
    color: ${COLORS.black};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
`

export const ProfileWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

export const ProfileArrowContainer = styled.div`
    position: relative;
    .transform-180{
    transform: rotate(180deg);
    transition: transform 0.5s;
  }

  .transform-360{
    transform: rotate(360deg);
    transition: transform 0.5s;
  }
`

export const DropDownMenu = styled.div`
    position: absolute;
    background: ${props => props.background};
    border-radius: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    height: min-content;
    z-index: 2;
    padding: 12px 5px;
    width: 180px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    width: 125px;
    border-radius: 8px;
    padding: 16px;
    align-items: center;
    gap: 4px;
    width: min-content;
    text-align: start;
    justify-content: start;
    align-items: start;
`

export const DropDownMenuItem = styled.div`
    display: flex;
    gap: 8px;
`

export const DropDownImage = styled.img`
    width: 26px;
    height: 26px;
    border-radius: 32px;
    background: ${COLORS.white};
    padding: 6px;
`
