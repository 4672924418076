export const TravelIcon = `https://storage.googleapis.com/pp_img/claim_portal/travel-colored.svg`
export const BicycleIcon = `https://storage.googleapis.com/pp_img/claim_portal/Bicycle.svg`
export const BusIcon = `https://storage.googleapis.com/pp_img/claim_portal/Bus.svg`
export const AccidentIcon = `https://storage.googleapis.com/pp_img/claim_portal/accident-colored.svg`
export const CreditIcon = `https://storage.googleapis.com/pp_img/claim_portal/credit.svg`
export const DeliveryIcon = `https://storage.googleapis.com/pp_img/claim_portal/delivery.svg`
export const DeviceIcon = `https://storage.googleapis.com/pp_img/claim_portal/device.svg`
export const EcommerceIcon = `https://storage.googleapis.com/pp_img/claim_portal/ecommerce.svg`
export const EducationIcon = `https://storage.googleapis.com/pp_img/claim_portal/education.svg`
export const ElectronicIcon = `https://storage.googleapis.com/pp_img/claim_portal/electronic.svg`
export const FilmIcon = `https://storage.googleapis.com/pp_img/claim_portal/film.svg`
export const FireIcon = `https://storage.googleapis.com/pp_img/claim_portal/fire.svg`
export const FlightIcon = `https://storage.googleapis.com/pp_img/claim_portal/flight.svg`
export const FloodIcon = `https://storage.googleapis.com/pp_img/claim_portal/flood.svg`
export const FoodIcon = `https://storage.googleapis.com/pp_img/claim_portal/food.svg`
export const GadgetIcon = `https://storage.googleapis.com/pp_img/claim_portal/gadget-colored.svg`
export const HealthIcon = `https://storage.googleapis.com/pp_img/claim_portal/health-colored.svg`
export const HomeContentIcon = `https://storage.googleapis.com/pp_img/claim_portal/home-content.svg`
export const LiabilityIcon = `https://storage.googleapis.com/pp_img/claim_portal/liability.svg`
export const LifeIcon = `https://storage.googleapis.com/pp_img/claim_portal/life.svg`
export const LifestyleIcon = `https://storage.googleapis.com/pp_img/claim_portal/lifestyle.svg`
export const MerchantIcon = `https://storage.googleapis.com/pp_img/claim_portal/merchant.svg`
export const OthersIcon = `https://storage.googleapis.com/pp_img/claim_portal/others.svg`
export const PropertyIcon = `https://storage.googleapis.com/pp_img/claim_portal/property-colored.svg`
export const ShippingIcon = `https://storage.googleapis.com/pp_img/claim_portal/shipping.svg`
export const TicketIcon = `https://storage.googleapis.com/pp_img/claim_portal/ticket.svg`
export const TrainIcon = `https://storage.googleapis.com/pp_img/claim_portal/train.svg`
export const AutoIcon = `https://storage.googleapis.com/pp_img/claim_portal/auto-colored.svg`
export const MotorcycleIcon = `https://storage.googleapis.com/pp_img/claim_portal/motorcycle.svg`
export const LogisticsIcon = `https://storage.googleapis.com/pp_img/claim_portal/logistics.svg`

export const categoryIconMap = {
  default: OthersIcon,
  auto: AutoIcon,
  bicycle: BicycleIcon,
  bike: MotorcycleIcon,
  bus: BusIcon,
  car: AutoIcon,
  credit: CreditIcon,
  device: GadgetIcon,
  ecommerce: EcommerceIcon,
  electronic: ElectronicIcon,
  flight: FlightIcon,
  gadget: GadgetIcon,
  health: HealthIcon,
  'home-content': HomeContentIcon,
  liability: LiabilityIcon,
  life: LifeIcon,
  lifestyle: LifestyleIcon,
  logistics: LogisticsIcon,
  merchant: MerchantIcon,
  movies: FilmIcon,
  other: OthersIcon,
  others: OthersIcon,
  'personal-accident': AccidentIcon,
  phone: GadgetIcon,
  property: PropertyIcon,
  shipping: ShippingIcon,
  ticket: TicketIcon,
  train: TrainIcon,
  travel: TravelIcon,
  vehicle: AutoIcon,
  delivery: DeliveryIcon,
  education: EducationIcon,
  fire: FireIcon,
  food: FoodIcon
}

export const getCategoryIcon = (code) => categoryIconMap[code] || categoryIconMap.default
