import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import request from '../../request/axios/tokopediaCrackScreenEndorse'
import _ from 'lodash/object'

export function * submitTokopediaCrackScreenRequest (props) {
  yield takeEvery(actions.SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.postTokopediaCrackScreenRequest, payload)
      if (res.status === 200) {
        yield put({
          type: actions.SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_FAILURE,
          message: _.get(e, 'data.error', 'Terjadi Kesalahan')
        })
      }
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(submitTokopediaCrackScreenRequest)
  ])
}
