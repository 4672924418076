import actions from './actions'

const initState = {
  gosureEndorseDetails: {},
  aggregateEndorseSuccess: {},
  brandTags: [],
  modelTags: [],
  tagModelStatus: '',
  tagBrandStatus: '',
  gosureDetailsMessage: '',
  nationalityData: [],
  nationalityMessage: '',
  cityData: [],
  cityMessage: '',
  districtData: [],
  districtMessage: '',
  townData: [],
  townMessage: ''
}

export default function appReducer (state = initState, action) {
  switch (action.type) {
    case actions.SUBMIT_GOSURE_ENDORSE_SUCCESS:
    case actions.SUBMIT_AGGREGATE_ENDORSE_FAILURE:
    case actions.SUBMIT_GOSURE_ENDORSE_FAILURE:
      return {
        ...state,
        gosureEndorseDetails: action.data,
        gosureDetailsMessage: action.message
      }
    case actions.GET_BIKE_BRAND_SUCCESS:
    case actions.GET_BIKE_BRAND_FAILURE:
      return {
        ...state,
        brandTags: action.data,
        tagBrandStatus: action.tagBrandStatus
      }
    case actions.GET_BIKE_MODEL_SUCCESS:
    case actions.GET_BIKE_MODEL_FAILURE:
      return {
        ...state,
        modelTags: action.data,
        tagModelStatus: action.tagModelStatus
      }
    case actions.SUBMIT_AGGREGATE_ENDORSE_SUCCESS:
      return {
        ...state,
        aggregateEndorseSuccess: action.data,
        gosureDetailsMessage: action.message
      }
    case actions.GET_NATIONALITY_SUCCESS:
    case actions.GET_NATIONALITY_FAILURE:
      return {
        ...state,
        nationalityData: action.data,
        nationalityMessage: action.message
      }
    case actions.GET_CITY_SUCCESS:
    case actions.GET_CITY_FAILURE:
      return {
        ...state,
        cityData: action.data,
        cityMessage: action.message
      }
    case actions.GET_DISTRICT_SUCCESS:
    case actions.GET_DISTRICT_FAILURE:
      return {
        ...state,
        districtData: action.data,
        districtMessage: action.message
      }
    case actions.GET_TOWN_SUCCESS:
    case actions.GET_TOWN_FAILURE:
      return {
        ...state,
        townData: action.data,
        townMessage: action.message
      }
    case actions.RESET_GOSURE_REDUX:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}
