import { language } from '../../settings'

import { UNITED_STATES, INDONESIA, THAILAND, VIETNAM } from '../../constants/icons'
import _ from 'lodash/object'

/*
{
  languageId: "english", human readable language name
  locale: "en", locale key
  messagesId: "eng", key used to get translated messages in anuvadak
  text: "English", displayable value
  code: "EN", code displayed in header
  icon: English displayable icon, imported variable
}
*/
const config = {
  defaultLanguage: language(),
  options: [
    {
      languageId: 'indonesia',
      locale: 'id',
      messagesId: 'ind',
      text: 'Indonesia',
      code: 'ID',
      countryCode: '62',
      icon: INDONESIA
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '62',
      icon: UNITED_STATES
    },
    {
      languageId: 'thai',
      locale: 'th',
      messagesId: 'tha',
      text: 'Thailand',
      code: 'TH',
      countryCode: '66',
      icon: THAILAND
    },
    {
      languageId: 'viet',
      locale: 'vi',
      messagesId: 'vie',
      text: 'Vietnam',
      code: 'VN',
      countryCode: '84',
      icon: VIETNAM
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '60',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '65',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '63',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '886',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '86',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '852',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '61',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '52',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '48',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '81',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '82',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '44',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '31',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '39',
      icon: UNITED_STATES
    },
    {
      languageId: 'english',
      locale: 'en',
      messagesId: 'eng',
      text: 'English',
      code: 'EN',
      countryCode: '91',
      icon: UNITED_STATES
    }
  ]
}

export function getCurrentLanguage (lang, countryCode) {
  const selectedLanguage = config.options[1]
  const configLanguage = config.options.find(x => countryCode ? x.countryCode === countryCode : x.locale === lang)
  const langCode = _.get(configLanguage, 'locale', selectedLanguage.locale)
  const code = _.get(configLanguage, 'code', selectedLanguage.code)
  window.localStorage.setItem('lang', langCode)
  window.localStorage.setItem('country_code', code)

  return configLanguage || selectedLanguage
}
export default config
