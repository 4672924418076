import actions from './actions'

const initState = {
  tokopediaCrackScreenDetails: {},
  tokopediaCrackScreenMessage: ''
}

export default function appReducer (state = initState, action) {
  switch (action.type) {
    case actions.SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_SUCCESS:
    case actions.SUBMIT_TOKOPEDIA_CRACK_SCREEN_FORM_FAILURE:
      return {
        ...state,
        tokopediaCrackScreenDetails: action.data,
        tokopediaCrackScreenMessage: action.message
      }
    case actions.RESET_TOKOPEDIA_CRACK_SCREEN_ENDORSE_REDUX:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}
