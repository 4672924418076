export const LANG_STRINGS = {
  submit: 'Kirimkan',
  selectDate: 'Pilih tanggal',
  enterAddress: 'Masukkan Alamat',
  applicationNumber: 'Nomor Aplikasi',
  thankYou: 'Terima Kasih!',
  email: 'Email',
  gender: 'Jenis Kelamin',
  male: 'Laki-Laki',
  female: 'Perempuan',
  dateOfBirth: 'Tanggal Lahir',
  address: 'Alamat',
  orderID: 'ID Order',
  idPassport: 'ID / Paspor',
  name: 'Nama',
  brand: 'Merek',
  model: 'Model & Seri',
  chasisNumber: 'Nomor Rangka',
  VIN: 'VIN',
  ktpNumber: 'KTP Number',
  plateNumber: 'Nomor Polisi',
  firstAndLastName: 'Nama Depan dan Belakang',
  emailRequired: 'Email harus diisi',
  emailError: 'Masukkan email yang valid',
  requiredField: 'Kolom yang harus diisi',
  orderNumber: 'Nomor Order',
  policyHolder: 'Data Pemegang Polis',
  nationality: 'Kebangsaan',
  idNumberPassPort: 'Nomor ID/Paspor',
  cityProvince: 'Kota/Provinsi',
  district: 'Kecamatan',
  townWardVillage: 'Kelurahan',
  serviceCenterAreaLabel: 'Pilih area perbaikan yang Anda inginkan',
  chooseBrandLabel: 'Pilih brand',
  brandLabel: 'Brand',
  specializingInBrand: 'Spesialis menangani brand {brand}',
  information: 'Informasi',
  noBrandFoundCopyright: 'Mohon maaf untuk saat ini tidak tersedia service center untuk memperbaiki gadget Anda. Untuk informasi lebih lanjut silakan menghubungi',
  customerReachOut: 'Customer Service di WA 0818 343 494',
  close: 'TUTUP',
  detailBrand: 'Detail brand',

  // revamp realted constants
  policy: 'Polis',
  claim: 'Klaim',
  profile_details: 'Profile',
  document_details: 'Documents',
  agencyPolicy: 'Polis mitra',
  agencyClaim: 'Klaim mitra',
  raiseClaim: 'buat klaim',
  detail: 'detail',
  polisId: 'Polis ID',
  startProtection: 'Mulai Perlindungan',
  policyHolderLabel: 'Policy Holder',
  end: 'Berakhir',
  cancel: 'batal',
  continue: 'lanjut',
  selectBenefitModalText: 'Pilih Manfaat Yang Ingin Diklaim',
  noBenefitCopyRight: 'Jika Anda tidak menemukan manfaat yang akan diklaim silakan menghubungi layanan pelanggan di WhatsApp 0818 343 494',
  next: 'next',
  allPolicy: 'Semua Polis',
  activePolicy: 'Polis Aktif',
  expiredPolciy: 'Polis Kedaluwarsa',
  showing: 'Showing',
  visitClaimPortal: 'Kunjungi Portal Klaim',
  thisFormAndDoc: 'Isi Form & Dokumen',
  verificationProcess: 'Proses Verifikasi',
  disbursementfunds: 'Pencairan Dana',
  noPolicyListCopyright: 'Anda belum memiliki Polis',
  noClaimListCopyright: 'Anda tidak memiliki Klaim',
  startWithTapInsureCopyRight: 'Ayo mulai hidup tanpa rasa khawatir dengan perlindungan terbaik dari TAP Insure!',
  downloadTapInsuranceCopyright: 'Download aplikasi TAP Insure dan beli perlindungan sekarang!',
  messageForNoClaimListPage: 'Tidak perlu khawatir jika Anda perlu melakukan pengajuan klaim,',
  claimProcessAtPp: 'karena proses klaim di PasarPolis sangat mudah dan cepat!',
  allClaims: 'Semua Klaim',
  initatedClaim: 'Klaim Dimulai',
  setteldClaim: 'Klaim Diproses',
  rejectedClaim: 'Klaim Selesai',
  benefitClaimed: 'Benefit di Klaim',
  submitDocument: 'lengkapi',
  claimId: 'Klaim ID',
  more: 'selengkapnya',
  cannotSubmitClaimForBenefit: 'Anda tidak dapat melanjutkan pengajuan klaim untuk manfaat',
  customerReachOutcomeDetailsBenefitText: 'Untuk informasi lebih lanjut silakan hubungi layanan pelanggan di WhatsApp (+62) 818 343 494',
  deadline: 'Batas Waktu :',
  dateOfBirthShort: 'Tgl. Lahir',
  individualAmountTapTravelExceededCopyRight: 'Individu ini tidak dapat mengajukan Klaim. Hubungi CS di 0818 343 494 untuk informasi lebih lanjut',
  longContinueText: 'lanjutkan',
  totalRemainingValueLeft: 'Total Nilai Pertanggungan Tersisa Adalah IDR {amount}',
  pleaseNoteThatTheMaximumClaimPaymentIsForBenefits: 'Perlu diperhatikan bahwa Maksimal Pembayaran Klaim untuk manfaat',
  isWorthIDR: 'adalah senilai IDR {amount}',
  claimInitiated: 'Klaim Dimulai',
  documentVerification: 'Verifikasi Dokumen',
  claimVerification: 'Verifikasi Klaim',
  claimSettled: 'Klaim Ditutup',
  claimCompleted: 'Klaim Selesai',
  documentPending: 'Dokumen Tertunda',
  inTheSurvey: 'Di Survei',
  workCompleted: 'Pekerjaan Selesai',
  documentReceived: 'Dokumen Diterima',
  documentVerified: 'Dokumen Diverifikasi',
  documentUploaded: 'Upload Ulang Dokumen',
  documentRejected: 'Dokumen Ditolak',
  inVerification: 'Dalam Verifikasi',
  claimApproved: 'Klaim Disetujui',
  claimPaid: 'Klaim Dibayar',
  surveySuccessful: 'Survey Berhasil',
  claimRejected: 'Klaim Ditolak',
  documentSent: 'Dokumen Dikirim',
  paymentInProcess: 'Proses Pembayaran',
  serviceCenterInProgress: 'Pengecekan oleh pusat servis',
  inProcessOfRepair: 'Sedang dalam proses perbaikan',
  repairCompleted: 'Perbaikan selesai',
  deviceYetToBeReceived: 'Menunggu perangkat elektronik diterima',
  approvalFromServiceCenter: 'Persetujuan dari Pusat Layanan',
  claimCancelled: 'Klaim Dibatalkan',
  returnsOfGoodsApproved: 'Pengembalian Barang Disetujui',
  documentApproved: 'Dokumen Disetujui',
  inSurvey: 'Di survei',
  returnsOfGoodsInProgress: 'Pengembalian Barang Dalam Proses',
  returnAccepected: 'Pengembalian Barang Diterima',
  insured: 'Tertanggung',
  discriptionVisitClaimPortal: 'Kunjungi policies.pasarpolis.io/ kemudian lakukan otentifikasi OTP di ponsel Anda.',
  discriptionForFormDocument: 'Lengkapi form klaim dan unggah dokumen sesuai dengan manfaat yang Anda pilih.',
  discriptionVerificationProcess: 'Silahkan tunggu proses verifikasi klaim dan dokumen Anda dalam waktu maksimal 3 hari kerja.',
  discriptionDisbursementOfFunds: 'Pengembalian dana klaim akan segera ditransfer ke rekening Anda maksimal 1x24 jam.',
  fillTheDocumentsByDate: 'Batas Waktu Penyelesaian : {maxDocSubmissionDate}',
  claimDetail: 'Klaim Detail',
  benefitTitle: 'Benefit Diklaim',
  statusClaimTitle: 'Status Klaim',
  product: 'Produk',
  paketLabel: 'Paket',
  categoryLabel: 'Kategori',
  partnerLabel: 'Partner',
  policyIdLabel: 'Polis ID',
  regexIncorrect: 'Nilai yang diberikan bukan format yang benar',
  required: 'Harus',
  useHelp: 'Gunakan bantuan',
  alreadySaved: 'Sudah Tersimpan',
  yes: 'Yes',
  no: 'No',
  uploadImageTypeAndSizeText: 'Dokumen harus berformat: .jpg, .jpeg, .png, .mp4 atau .pdf dan tidak melebihi dari 25 MB',
  lowerLimitUploadError: 'Ukuran file terlalu kecil, min {lowerlimit} KB',
  upperLimitUpladError: 'Ukuran file terlalu besar, maks {upperLimit} MB',
  userGuidelineText: 'PANDUAN FOTO',
  policyExpired: 'Polis Berakhir',
  enterServiceCenterName: 'Pilih nama Service Center',
  bankAccount: 'Rekening Bank',
  selectBankAccount: 'Pilih Rekening Bank',
  newBankAccount: 'Rekening Baru',
  saveBankAccount: 'SIMPAN REKENING BANK',
  acocuntNumber: 'Nomor Rekening',
  accountHolderName: 'Nama lengkap nasabah',
  submitLabel: 'Simpan',
  verify: 'Verify',
  all: 'Semua',
  active: 'Aktif',
  expired: 'Berakhir',
  initiated: 'Dimulai',
  transition: 'Diproses',
  closed: 'Selesai',
  home: 'Home',
  logOut: 'Log out',
  CompletedClaimForm: 'Klaim Form Lengkap',
  completeClaimForm: 'Lengkapi Klaim Form',
  claimMustBeResolved: 'Klaim Harus Diselesaikan',
  policyDetail: 'Policy Detail',
  editProfile: 'Edit Profil',
  changePicture: 'Ubah Foto',

  // added on the mobile design
  claimForm: 'Klaim Form',
  about: 'Tentang Pasarpolis Insurance Broker',
  aboutContent: 'PT PasarPolis Insurance Broker (PPIB) adalah perusahaan Pialang Asuransi berbasis Teknologi Web-based dan Aplikasi Mobile yang berlisensi dan diawasi secara resmi oleh Otoritas Jasa Keuangan (OJK). PPIB bertujuan memberikan kemudahan dalam mengakses produk asuransi melalui berbagai pilihan produk asuransi kemitraan yang biaya preminya terjangkau dan mudah diakses oleh seluruh lapisan masyarakat, semua dalam 1 aplikasi. Sebagai perusahaan pialang asuransi, PPIB menawarkan lebih dari 175 produk kemitraan dengan lebih dari 50 mitra asuransi dan 40 mitra ekosistem digital. PPIB berkomitmen untuk memberikan pengalaman yang menyenangkan bagi nasabah melalui penerbitan polis yang instan hingga proses klaim yang mudah dan cepat.',
  aboutContent2: 'PT Pasarpolis Insurance Broker berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK). PPIB memiliki persetujuan OJK untuk penyelenggaraan Layanan Pialang Asuransi Digital. PPIB merupakan anggota dari APPARINDO.',
  info: 'Informasi',
  submitclaim: 'Ajukan Klaim',
  faq: 'FAQ',
  insuranceDictionary: 'Kamus Asuransi',
  company: 'Perusahaan',
  aboutCompany: 'Tentang PasarPolis',
  career: 'Karir',
  office: 'Kantor Kami',
  ptBrokerTitle: 'PT Pasarpolis Insurance Broker',
  ppBrokeraddress: `One Pacific Place, Lantai 10,SCBD,Jl. Jend. Sudirman Kav 52-53 Jakarta Selatan 12190`,
  viewMap: 'Lihat Peta'
}

export const ERROR_STRINGS = {
  error: 'Server Error',
  serverError: 'Server mengalami masalah dan tidak dapat menyelesaikan permintaan Anda'
}
