import actions from './actions'

const initState = {
  createOrderStatus: '',
  getBenefitsGojekPassengerStatus: '',
  createClaimGocarPassengerStatus: '',
  validateClaimGoPassengerStatus: '',
  validateClaimGoPassengerStatusCode: '',
  createOrderData: {}
}

export default function gojek (state = initState, action) {
  switch (action.type) {
    case actions.RESET_STATE_GOJEK_PASSENGER:
      return {
        ...state,
        ...initState
      }
    case actions.CREATE_ORDER_GOJEK_PASSENGER_STATUS:
      return {
        ...state,
        createOrderData: action.createOrderData,
        createOrderStatus: action.createOrderStatus
      }
    case actions.GET_BENEFITS_GO_PASSENGER_SUCCESS:
    case actions.GET_BENEFITS_GO_PASSENGER_FAILURE:
      return {
        ...state,
        dataBenefits: action.data,
        getBenefitsGojekPassengerStatus: action.getBenefitsGojekPassengerStatus
      }
    case actions.CREATE_CLAIM_GO_PASSENGER_SUCCESS:
    case actions.CREATE_CLAIM_GO_PASSENGER_FAILURE:
      return {
        ...state,
        dataClaim: action.data,
        createClaimGocarPassengerStatus: action.message
      }
    case actions.VALIDATE_CLAIM_GO_PASSENGER_STATUS:
      return {
        ...state,
        dataValidateGocar: action.dataValidateGocar,
        validateClaimGoPassengerStatus: action.validateClaimGoPassengerStatus,
        validateClaimGoPassengerStatusCode: action.validateClaimGoPassengerStatusCode
      }
    default:
      return state
  }
}
