import actions from './actions'

const initState = {
  lazadaPolicyDetails: {},
  lazadaPolicyDetailsMessage: '',
  postLazadaCrackScreenEndorseMessage: '',
  lazadaFullGadgetProtectionDetails: {},
  lazadaFullGadgetProtectionDetailsMessage: '',
  lazadahcpProvinceData: [],
  lazadahcpProvinceDataMessage: ''
}

export default function appReducer (state = initState, action) {
  switch (action.type) {
    case actions.GET_PROVINCE_DATA_SUCCESS:
    case actions.GET_PROVINCE_DATA_FAILURE:
      return {
        ...state,
        lazadahcpProvinceData: action.data,
        lazadahcpProvinceDataMessage: action.message
      }
    case actions.GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_SUCCESS:
    case actions.GET_LAZADA_HOSPITAL_CASH_PLAN_POLICY_DETAIL_FAILURE:
      return {
        ...state,
        lazadaPolicyDetails: action.data,
        lazadaPolicyDetailsMessage: action.message
      }
    case actions.GET_LAZADA_POLICY_DETAIL_SUCCESS:
    case actions.GET_LAZADA_POLICY_DETAIL_FAILURE:
      return {
        ...state,
        lazadaPolicyDetails: action.data,
        lazadaPolicyDetailsMessage: action.message
      }

    case actions.GET_LAZADA_VEHICLE_POLICY_DETAIL_SUCCESS:
    case actions.GET_LAZADA_VEHICLE_POLICY_DETAIL_FAILURE:
      return {
        ...state,
        lazadaVehiclePolicyDetails: action.data,
        lazadaVehiclePolicyDetailsMessage: action.message
      }

    case actions.POST_LAZADA_POLICY_DETAIL_SUCCESS:
    case actions.POST_LAZADA_POLICY_DETAIL_FAILURE:
      return {
        ...state,
        postLazadaCrackScreenEndorseData: action.data,
        postLazadaCrackScreenEndorseMessage: action.message
      }
    case actions.GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_SUCCESS:
    case actions.GET_LAZADA_FULL_GADGET_PROTECTION_POLICY_DETAIL_FAILURE:
      return {
        ...state,
        lazadaFullGadgetProtectionDetails: action.data,
        lazadaFullGadgetProtectionDetailsMessage: action.message
      }
    case actions.RESET_LAZADA_ENDORSE_REDUX:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}
