
import SSOSessionWrapper from '../../containers/SSO/SSOSessionWrapper'

import {
  DropDownImage,
  DropDownMenu,
  DropDownMenuItem,
  HeaderContainer,
  HeaderLogoContainer,
  HeaderLogoImage,
  LeftSideLogoContainer,
  NavigationLinksListWrapper,
  NavigationLinksWrapper,
  ProfileArrowContainer,
  ProfileUrlImage,
  ProfileWrapper,
  RightSideContainer,
  UserNameTitle
} from './headerV2.style'

import { COLORS } from '../../constants/colors'
import { genricImageLinks } from '../../constants'
import IntlMessages from '../utility/intlMessages'
import LanguageDropDown from '../LanguageDropDown'
import { ArrowDown, LogoutIcon } from '../../image'
import useComponentVisible from '../../hooks/useComponentVisible'

function HeaderV2UI ({
  isHomePage = false,
  logout = () => { },
  userName = '',
  profileUrl = '',
  activeTab = '',
  logo = genricImageLinks.fallbackLogo,
  headerBackground = COLORS.tapPartnerYellow,
  navigationLinks = [],
  navigationClickHandler = () => { },
  isLoggedIn = false,
  selectedLanguage = '',
  changeLanguagehandler = () => { },
  profileRenderMenu = [],
  navigateToLink = () => { },
  headerTextColor = COLORS.originGold
}) {
  const { ref, isComponentVisible: isProfileOptionOpen, setIsComponentVisible: setIsProfileOptionOpen } = useComponentVisible(false)
  const openProfileRenderMenu = (e) => {
    e.stopPropagation()
    setIsProfileOptionOpen((open) => !open)
  }
  return (
    <HeaderContainer headerBackground={headerBackground} textColor={headerTextColor}>

      <LeftSideLogoContainer>
        <HeaderLogoContainer>
          <HeaderLogoImage src={logo} alt='logo' onClick={() => navigateToLink('')} />
        </HeaderLogoContainer>
        {
          isLoggedIn
            ? (
              <NavigationLinksListWrapper>
                {
                  navigationLinks.map(({ title, link, disabled, isNonLinkApplicable }) => {
                    const isNonLink = !activeTab && isNonLinkApplicable
                    return (
                      <NavigationLinksWrapper
                        key={title}
                        link={link}
                        active={isHomePage && (activeTab === link || isNonLink)}
                        disabled={disabled}
                        onClick={(e) => navigationClickHandler(e, link)}
                        textColor={headerTextColor}
                      >
                        <IntlMessages
                          id={title}
                        />
                      </NavigationLinksWrapper>
                    )
                  })
                }
              </NavigationLinksListWrapper>
            )
            : null
        }
      </LeftSideLogoContainer>

      <RightSideContainer>
        <SSOSessionWrapper>
          <LanguageDropDown
            background={headerBackground}
            selectedLanguage={selectedLanguage}
            changeLanguagehandler={changeLanguagehandler}
          />
        </SSOSessionWrapper>
        {isLoggedIn
          ? <SSOSessionWrapper>
            <ProfileWrapper>
              <div>
                {profileUrl && <ProfileUrlImage src={profileUrl} />}
              </div>
              <UserNameTitle>
                {userName || ''}
              </UserNameTitle>
              <ProfileArrowContainer>
                <img ref={ref} className={isProfileOptionOpen ? 'transform-180' : 'transform-360'} onClick={openProfileRenderMenu} src={ArrowDown} alt='arrow' />
                <div>
                  {
                    isProfileOptionOpen
                      ? (
                        <DropDownMenu background={headerBackground}>
                          {profileRenderMenu.map(({ link, title, img }) => {
                            return (
                              <DropDownMenuItem
                                key={title}
                                onClick={(e) => {
                                  navigateToLink(link)
                                  setIsProfileOptionOpen(false)
                                }
                                }>
                                <DropDownImage src={img} />
                                <IntlMessages id={title} />
                              </DropDownMenuItem>
                            )
                          })}
                        </DropDownMenu>
                      )
                      : null
                  }
                </div>
              </ProfileArrowContainer>
            </ProfileWrapper>
          </SSOSessionWrapper>
          : null
        }
        {
          isLoggedIn
            ? (
              <div>
                <img alt='logout' src={LogoutIcon} onClick={logout} />
              </div>
            )
            : null
        }
      </RightSideContainer>

    </HeaderContainer>
  )
}

export default HeaderV2UI
