import { Provider } from 'react-redux'
import { store, history } from '../redux/store'
import PublicRoutes from '../router'
import PoliciesAppHolder from './policiesAppStyle'
import Boot from '../redux/boot'
import * as Sentry from '@sentry/react'

const PoliciesApp = () => (
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <PoliciesAppHolder>
      <Provider store={store}>
        <PublicRoutes history={history} />
      </Provider>
    </PoliciesAppHolder>
  </Sentry.ErrorBoundary>
)
Boot()
  .then(() => PoliciesApp())
  .catch(error => { console.error(error) })

export default PoliciesApp
