const isSSOSession = () => {
  const ssoCookie = window.sessionStorage.getItem('isSSOSession')
  let isSSOSession = false
  if (ssoCookie === 'true') {
    isSSOSession = true
  }
  return isSSOSession
}

export default isSSOSession
