const actions = {
  SUBMIT_GOSURE_ENDORSE_REQUEST: 'SUBMIT_GOSURE_ENDORSE_REQUEST',
  SUBMIT_GOSURE_ENDORSE_SUCCESS: 'SUBMIT_GOSURE_ENDORSE_SUCCESS',
  SUBMIT_GOSURE_ENDORSE_FAILURE: 'SUBMIT_GOSURE_ENDORSE_FAILURE',
  GET_BIKE_BRAND_REQUEST: 'GET_BIKE_BRAND_REQUEST',
  GET_BIKE_BRAND_SUCCESS: 'GET_BIKE_BRAND_SUCCESS',
  GET_BIKE_BRAND_FAILURE: 'GET_BIKE_BRAND_FAILURE',
  GET_BIKE_MODEL_REQUEST: 'GET_BIKE_MODEL_REQUEST',
  GET_BIKE_MODEL_SUCCESS: 'GET_BIKE_MODEL_SUCCESS',
  GET_BIKE_MODEL_FAILURE: 'GET_BIKE_MODEL_FAILURE',
  SUBMIT_AGGREGATE_ENDORSE_REQUEST: 'SUBMIT_AGGREGATE_ENDORSE_REQUEST',
  SUBMIT_AGGREGATE_ENDORSE_SUCCESS: 'SUBMIT_AGGREGATE_ENDORSE_SUCCESS',
  SUBMIT_AGGREGATE_ENDORSE_FAILURE: 'SUBMIT_AGGREGATE_ENDORSE_FAILURE',
  RESET_GOSURE_REDUX: 'RESET_GOSURE_REDUX',
  GET_NATIONALITY_REQUEST: 'GET_NATIONALITY_REQUEST',
  GET_NATIONALITY_SUCCESS: 'GET_NATIONALITY_SUCCESS',
  GET_NATIONALITY_FAILURE: 'GET_NATIONALITY_FAILURE',

  GET_CITY_REQUEST: 'GET_CITY_REQUEST',
  GET_CITY_SUCCESS: 'GET_CITY_SUCCESS',
  GET_CITY_FAILURE: 'GET_CITY_FAILURE',

  GET_DISTRICT_REQUEST: 'GET_DISTRICT_REQUEST',
  GET_DISTRICT_SUCCESS: 'GET_DISTRICT_SUCCESS',
  GET_DISTRICT_FAILURE: 'GET_DISTRICT_FAILURE',

  GET_TOWN_REQUEST: 'GET_TOWN_REQUEST',
  GET_TOWN_SUCCESS: 'GET_TOWN_SUCCESS',
  GET_TOWN_FAILURE: 'GET_TOWN_FAILURE',

  submitGosureEndorseForm: (payload) => ({
    type: actions.SUBMIT_GOSURE_ENDORSE_REQUEST,
    payload
  }),
  submitAggregateEndorse: (payload) => ({
    type: actions.SUBMIT_AGGREGATE_ENDORSE_REQUEST,
    payload
  }),
  getBikeBrandList: () => ({
    type: actions.GET_BIKE_BRAND_REQUEST
  }),
  getBikeModelList: () => ({
    type: actions.GET_BIKE_MODEL_REQUEST
  }),
  getNationalityData: () => ({
    type: actions.GET_NATIONALITY_REQUEST
  }),
  resetGosureEndorseRedux: (payload) => ({
    type: actions.RESET_GOSURE_REDUX,
    payload
  }),
  getCityData: () => ({
    type: actions.GET_CITY_REQUEST
  }),
  getDistrictData: (payload) => ({
    type: actions.GET_DISTRICT_REQUEST,
    payload
  }),
  getTownData: (payload) => ({
    type: actions.GET_TOWN_REQUEST,
    payload
  })
}

export default actions
