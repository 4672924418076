// this file include in router js file
// and extend eith brand colors and pass to theme provider
export const COLORS = {
  veryLightGrey: '#f8f8f8',
  darkLimeGreen: '#23a637',
  white: '#ffffff',
  black: '#000000',
  strongRed: '#d0021b',
  matalicGray: '#303030',
  brandBlue: '#0180c8',
  brandYellow: '#ffd500',
  strongGreen: '#00880d',
  brandDarkBlue: '#2b2171',
  guardsmanRed: '#9b323e',
  mintCream: '#f4f7f6',
  rustyNail: '#8b572a',
  tapPartnerBlue: '#0064C8',
  lightGrey: '#EEE',
  tapPartnerYellow: '#FAF000',
  originGold: '#B4A000',
  transparent: 'transparent',
  darkGrey: '#999',
  grainyGrey: '#F5F5F5',
  disabledLightGrey: '#ddd',
  monoChromeGrey: '#CCCCCC',
  iceBlue: '#0096FA',
  mysticVoilet: '#9632FA',
  crimsonRed: '#DC0000',
  grassGreen: '#009632',
  lightYellow: '#FAF0C8',
  lightBlue: '#D2E6FA',
  lightVoilet: '#F0DCFA',
  lightRed: '#FADCDC',
  lightGreen: '#C8F0DC',
  tapTapHeartRed: '#FA3C46',
  tapTapHeartRedSoft: '#FAD2D2',
  honeyyellow: '#FAC800',
  almostWhite: '#F5F5F5',
  darkYellow: '#FFC800'
}
