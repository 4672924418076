import getRequest from '../get'
import postRequest from '../authPost'
import url from '../../endpoint/golifePassenger'

const createOrder = async ({ payload }) => {
  try {
    const uri = url.createOrderUrl()
    return await postRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const getBenefitsGolifePassenger = async ({ payload }) => {
  try {
    const uri = url.getBenefitsGolifePassengerUrl()
    return await getRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const createClaimGocarPassenger = async ({ payload }) => {
  try {
    const uri = url.createClaimGocarPassengerUrl()
    return await postRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

export default {
  createOrder,
  getBenefitsGolifePassenger,
  createClaimGocarPassenger
}
