import { useEffect, useMemo, useState } from 'react'
import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router'

import HeaderV2UI from './headerV2UI'
import config from '../../containers/LanguageSwitcher/config'
import {
  AGENCY_NAVIGATION_LINKS,
  B2CNAVIGATION_LINKS,
  agencyAgent,
  agencyCustomer,
  b2b2cUser,
  flagsUrlOntheGCP,
  profileRenderMenu,
  tapinsuranceUser
} from '../../constants/constants'
import appActions from '../../redux/app/actions'
import authActions from '../../redux/auth/actions'
import { useIsMobile } from '../../hooks/useIsMobile'
import HeaderMobile from '../HeaderMobile'
import { LANG_STRINGS } from '../../constants/lang'
import { getAssets } from '../../helpers/utility'

const url = process.env.PUBLIC_URL

function HeaderV2 () {
  const isMobile = useIsMobile()
  const language = useSelector(state => state.App.language)
  const appConfig = useSelector(state => state.App.config) || {}
  const isLoggedIn = useSelector(state => state.User.isLoggedIn)
  const userDetails = useSelector(state => state.User.details)
  const product = useSelector(state => state.App.product)

  const profileUrl = get(userDetails, 'profile.profile_picture_url')
  const userName = get(userDetails, 'name', '')
  const services = get(userDetails, 'services', '')

  const location = useLocation()
  const history = useHistory()
  const { source } = queryString.parse(location.search)
  const isHomePage = location.pathname === '/'

  const dispatch = useDispatch()

  const [isMobileLanguage, setIsMobileLanguageOpen] = useState(false)

  useEffect(() => {
    if (!(window.sessionStorage.getItem('service_list')) && userDetails && Object.keys(userDetails).length) {
      const { services } = userDetails
      if (services && services.length) {
        const stringifiedList = JSON.stringify(services)
        window.sessionStorage.setItem('service_list', stringifiedList)
        window.location.reload()
      }
    }
  }, [])

  const navigationClickHandler = (e, link) => {
    e.stopPropagation()
    history.push(link)
  }

  const changeLanguagehandler = (lang) => {
    dispatch(appActions.changeLang(lang))
  }

  const navigateToLink = (link) => {
    history.push(`${url}${link}`)
  }

  const logout = () => {
    dispatch(authActions.signOut({ product }))
  }

  const activeTab = source
  const navigationLinks = useMemo(() => {
    let navigationLinks = []
    if (services.includes(b2b2cUser) || services.includes(tapinsuranceUser)) {
      navigationLinks.push(...B2CNAVIGATION_LINKS)
    }

    if (services.includes(agencyCustomer) || services.includes(agencyAgent)) {
      navigationLinks.push(...AGENCY_NAVIGATION_LINKS)
    }

    if (navigationLinks.length) {
      navigationLinks[0].isNonLinkApplicable = true
    }

    return navigationLinks
  }, [
    services,
    b2b2cUser,
    tapinsuranceUser,
    agencyAgent,
    agencyCustomer
  ])

  const getMobileHeaderLink = () => {
    const links = []
    if (services.includes(b2b2cUser) || services.includes(tapinsuranceUser)) {
      links.push(...B2CNAVIGATION_LINKS)
    }

    if (services.includes(agencyCustomer) || services.includes(agencyAgent)) {
      links.push(...AGENCY_NAVIGATION_LINKS)
    }

    links.push({
      name: 'Language',
      title: 'Language',
      action: () => {
        setIsMobileLanguageOpen(isOpen => !isOpen)
      },
      render: () => {
        if (!isMobileLanguage) return
        return (
          <div>
            {
              flagsUrlOntheGCP.map(({ flag, lang }) => (
                <img src={flag} onClick={() => changeLanguagehandler(lang)} />
              ))
            }
          </div>
        )
      }
    })

    const protectedMobileHeaderLinks = [{
      name: 'Profile',
      link: '/profile',
      title: LANG_STRINGS.profile_details,
      protectedLink: true
    },
    {
      name: 'Document',
      link: '/documents',
      title: LANG_STRINGS.document_details,
      protectedLink: true
    },
    {
      name: 'Log Out',
      action: logout,
      title: LANG_STRINGS.logOut,
      protectedLink: true,
      closeAtAction: true
    }
    ]

    if (isLoggedIn) {
      links.push(...protectedMobileHeaderLinks)
    }
    return links
  }

  const mobileNavigateToLink = ({ link, name, action }) => {
    if (!link) {
      return
    }
    history.push(`${url}${link}`)
  }

  const getHeaderTitle = () => {
    const policyDetailPattern = /^\/policydetail\/[^\/]+$/
    const claimDetailPattern = /^\/claimdetail\/[^\/]+$/
    const queryParams = new URLSearchParams(location.search)
    const source = queryParams.get('source')

    if (!isLoggedIn) return ''

    if (policyDetailPattern.test(location.pathname)) return LANG_STRINGS.policyDetail
    if (claimDetailPattern.test(location.pathname)) return LANG_STRINGS.claimDetail

    if (source === 'polis') return LANG_STRINGS.policy
    if (source === 'claim') return LANG_STRINGS.claim
    if (source === 'agency-policy') return LANG_STRINGS.agencyPolicy
    if (source === 'agency-claim') return LANG_STRINGS.agencyClaim

    if (location.pathname === '/' && isLoggedIn) return LANG_STRINGS.policy

    let title = getMobileHeaderLink().find(({ link }) => link === location.pathname + location.search)?.title || ''
    return title
  }

  return (
    isMobile
      ? <HeaderMobile
        headerTitle={getHeaderTitle()}
        headerLink={getMobileHeaderLink()}
        logout={logout}
        config={config}
        userName={userName}
        language={language}
        isHomePage={isHomePage}
        isLoggedIn={isLoggedIn}
        activeTab={activeTab}
        logo={appConfig?.LOGO}
        profileUrl={profileUrl}
        showBackButton={isLoggedIn && (location.pathname !== '/')}
        navigateToLink={mobileNavigateToLink}
        selectedLanguage={language?.locale}
        navigationLinks={navigationLinks}
        profileRenderMenu={profileRenderMenu}
        headerBackground={appConfig.BRAND_COLOR}
        changeLanguagehandler={changeLanguagehandler}
        navigationClickHandler={navigationClickHandler}
      />
      : <HeaderV2UI
        logout={logout}
        config={config}
        userName={userName}
        language={language}
        isHomePage={isHomePage}
        isLoggedIn={isLoggedIn}
        activeTab={`/?source=${activeTab}`}
        logo={getAssets().logoUrl}
        profileUrl={profileUrl}
        navigateToLink={navigateToLink}
        selectedLanguage={language?.locale}
        navigationLinks={navigationLinks}
        profileRenderMenu={profileRenderMenu}
        headerBackground={getAssets().headerBackgroundColor}
        headerTextColor={appConfig.SECONDARY_COLOR}
        changeLanguagehandler={changeLanguagehandler}
        navigationClickHandler={navigationClickHandler}
      />
  )
}

export default HeaderV2
