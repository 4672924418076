const actions = {
  GET_BANK_LIST_REQUEST: 'GET_BANK_LIST_REQUEST',
  GET_BANK_LIST_SUCCESS: 'GET_BANK_LIST_SUCCESS',
  GET_BANK_LIST_FAILURE: 'GET_BANK_LIST_FAILURE',
  RESET_MASTER_DATA_REDUX: 'RESET_MASTER_DATA_REDUX',
  getBankList: () => ({
    type: actions.GET_BANK_LIST_REQUEST
  }),
  resetMasterDataRedux: () => ({
    type: actions.RESET_MASTER_DATA_REDUX
  })
}
export default actions
