import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import _ from 'lodash'
import { clearToken } from '../../helpers/utility'
import actions from './actions'
import userActions from '../user/actions'
import request from '../../request/axios/auth'

export function * generateOtpRequest () {
  yield takeEvery(actions.GENERATE_OTP_REQUEST, function * (payload) {
    let res = yield call(request.sendOtp, {
      ...payload,
      languageCode: window.localStorage.getItem('lang')
    })
    if (res.status === 200) {
      const { result, operation_id: operationId } = res.data
      window.sessionStorage.setItem('verifyPhone', _.get(payload, 'payload.phone'))
      window.sessionStorage.setItem('operationId', operationId)

      yield put({
        type: actions.SAVE_OPERATION_ID,
        operation_id: operationId,
        status: result,
        phone: payload.payload
      })
    } else {
      yield put({
        type: actions.GENERATE_OTP_FAILURE,
        errorMessage: 'Gagal mengirim OTP. Silakan coba setelah beberapa waktu.'
      })
    }
  })
}

export function * checkWhatsAppOTPStatus () {
  yield takeEvery(actions.WHATS_APP_OTP_CHECK_REQUEST, function * ({ payload }) {
    let res = yield call(request.checkWhatsAppOTPStatus, {
      operation_id: window.sessionStorage.getItem('operationId'),
      medium: _.get(payload, 'medium', ''),
      languageCode: window.localStorage.getItem('lang')
    })
    // TODO Fix: Need to check with backend Service
    if (res.status === 200) {
      // yield put({
      //   type: actions.SAVE_OPERATION_ID,
      //   operation_id: window.sessionStorage.getItem('operationId'),
      //   status: res.status
      // })
      yield put({
        type: actions.WHATS_APP_OTP_CHECK_SUCCESS,
        data: res.data,
        operationId: window.sessionStorage.getItem('operationId'),
        status: res.status
      })
    } else {
      yield put({
        type: actions.GENERATE_OTP_FAILURE,
        errorMessage: 'Gagal mengirimkan otp'
      })
    }
  })
}

export function * resendOTPRequest () {
  yield takeEvery(actions.RESEND_OTP_REQUEST, function * ({ payload }) {
    let res = yield call(request.resendOTP, {
      mobileNo: _.get(payload, 'mobileNumber', ''),
      operationId: _.get(payload, 'operationId', ''),
      medium: _.get(payload, 'medium', ''),
      languageCode: window.localStorage.getItem('lang'),
      count: payload.count
    })
    if (res.status === 200) {
      yield put({
        type: actions.SAVE_OPERATION_ID,
        operation_id: _.get(payload, 'operationId', ''),
        status: res.status
      })
    } else {
      yield put({
        type: actions.GENERATE_OTP_FAILURE,
        errorMessage: 'Gagal mengirimkan otp'
      })
    }
  })
}

export function * saveOperationId () {
  yield takeEvery(actions.SAVE_OPERATION_ID, function * (payload) {
    yield put({
      type: actions.GENERATE_OTP_SUCCESS,
      status: payload.status,
      operationId: payload.operation_id,
      phone: payload.phone
    })
  })
}

export function * verifyOtpRequest () {
  yield takeEvery(actions.AUTH_VERIFY_OTP_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.verifyOtp, payload)
      const { token } = res.data
      const { profile } = res.data
      const { user } = res.data
      let serviceList
      if (user && user.services) {
        ({ services: serviceList } = user)
      }

      yield put({
        type: actions.AUTH_VERIFY_OTP_SUCCESS,
        errorMessage: '',
        token,
        profile,
        serviceList
      })
    } catch (error) {
      yield put({
        type: actions.AUTH_VERIFY_OTP_FAILURE,
        token: null,
        message: 'Gagal verifikasi OTP. Silahkan periksa OTP anda',
        otpErrorMessage: 'Gagal verifikasi OTP. Silahkan periksa OTP anda'
      })
    }
  })
}
export function * checkAuthorization () {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function * (payload) {
    let res = yield call(request.checkAuth)
    if (res.status === 200) {
      yield put(userActions.resetUserRedux())
      yield put({ type: userActions.GET_USER_DETAILS_REQUEST })
      yield put({
        type: actions.AUTH_VERIFY_OTP_SUCCESS,
        payload
      })
      yield put({
        type: actions.AUTH_SUCCESS
      })
    } else {
      yield put({
        type: actions.AUTH_FAILURE,
        message: 'Gagal masuk, coba lagi'
      })
    }
  })
}

export function * updateUser () {
  yield takeEvery(actions.UPDATE_USER, function * (payload) {
    yield call(request.updateUser, payload)
  })
}

export function * logout () {
  yield takeEvery(actions.LOGOUT_REQUEST, function * ({ payload }) {
    let res = yield call(request.logoutUser)
    if (res.status === 204) {
      yield put({
        type: actions.LOGOUT_SUCCESS
      })
      yield put({
        type: userActions.CLEAR_USER_PROFILE_REDUCER
      })
      clearToken()
      if (payload && payload.product) {
        window.history.pushState({}, null, `/${payload.product}`)
      } else {
        window.history.pushState({}, null, '/')
      }
    } else {
      yield put({
        type: actions.LOGOUT_FAILURE
      })
      yield put({
        type: userActions.CLEAR_USER_PROFILE_REDUCER
      })
      window.history.pushState({}, null, '/')
    }
  })
}

export function * verifySSOToken () {
  yield takeEvery(actions.VERIFY_SSO_TOKEN, function * ({ payload }) {
    let res = yield call(request.verifySSOToken, payload)
    if (res.status === 200) {
      yield put({
        type: actions.SSO_TOKEN_VERIFIED
      })
    } else {
      yield put({
        type: actions.SSO_TOKEN_FAILURE,
        message: res.message || 'Terjadi beberapa kesalahan.'
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(checkAuthorization),
    fork(logout),
    fork(generateOtpRequest),
    fork(resendOTPRequest),
    fork(saveOperationId),
    fork(verifyOtpRequest),
    fork(checkWhatsAppOTPStatus),
    fork(updateUser),
    fork(verifySSOToken)
  ])
}
