const actions = {
  GET_TOKOPEDIA_POLICY_DETAILS_REQUEST: 'GET_TOKOPEDIA_POLICY_DETAILS_REQUEST',
  GET_TOKOPEDIA_POLICY_DETAILS_SUCCESS: 'GET_TOKOPEDIA_POLICY_DETAILS_SUCCESS',
  GET_TOKOPEDIA_POLICY_DETAILS_FAILURE: 'GET_TOKOPEDIA_POLICY_DETAILS_FAILURE',
  GET_FIRSTMEDIA_POLICY_DETAILS_REQUEST: 'GET_FIRSTMEDIA_POLICY_DETAILS_REQUEST',
  GET_FIRSTMEDIA_POLICY_DETAILS_SUCCESS: 'GET_FIRSTMEDIA_POLICY_DETAILS_SUCCESS',
  GET_FIRSTMEDIA_POLICY_DETAILS_FAILURE: 'GET_FIRSTMEDIA_POLICY_DETAILS_FAILURE',
  POST_TOKOPEDIA_ENDORSE_REQUEST: 'POST_TOKOPEDIA_ENDORSE_REQUEST',
  POST_TOKOPEDIA_ENDORSE_SUCCESS: 'POST_TOKOPEDIA_ENDORSE_SUCCESS',
  POST_TOKOPEDIA_ENDORSE_FAILURE: 'POST_TOKOPEDIA_ENDORSE_FAILURE',
  RESET_TOKOPEDIA_ENDORSE_REDUX: 'RESET_TOKOPEDIA_ENDORSE_REDUX',
  getTokopediaPolicyDetails: payload => ({
    type: actions.GET_TOKOPEDIA_POLICY_DETAILS_REQUEST,
    payload
  }),
  getFirstMediaPolicyDetails: payload => ({
    type: actions.GET_FIRSTMEDIA_POLICY_DETAILS_REQUEST,
    payload
  }),
  postTokopediaEndorseRequest: payload => ({
    type: actions.POST_TOKOPEDIA_ENDORSE_REQUEST,
    payload
  }),
  resetTokopediaEndorseRedux: payload => ({
    type: actions.RESET_TOKOPEDIA_ENDORSE_REDUX,
    payload
  })
}
export default actions
