import { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../helpers/AsyncFunc'
import Page404 from '../../containers/404'
import { getAssets } from '../../helpers/utility.js'

let claimDetail = asyncComponent(() => import('../ClaimDetails'))

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Home'))
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('../Profile')),
    exact: true
  },
  {
    path: 'documents',
    component: asyncComponent(() => import('../Documents')),
    exact: true
  },
  {
    path: 'policydetail/:id',
    component: asyncComponent(() => import('../policyApp/policyDetails')),
    exact: true
  },
  {
    path: 'policydetail/:id/claim',
    component: asyncComponent(() => import('../Claim/Home'))
  },
  {
    path: 'claimdetail/:id/',
    component: claimDetail,
    exact: true
  },
  {
    path: 'policydetail/:id/claim/:benefit_id',
    component: claimDetail
  },
  {
    path: 'policydetail/:id/claim/:benefit_id/summary',
    component: asyncComponent(() => import('../Claim/Summary'))
  },
  {
    path: 'policydetail/:id/claim/:benefit_id/summary/finish',
    component: asyncComponent(() => import('../Claim/Finish'))
  },
  {
    path: 'gojek-passenger/claim',
    component: asyncComponent(() => import('../Gojek'))
  },
  {
    path: 'go-driver/claim',
    component: asyncComponent(() => import('../Gojek'))
  },
  {
    path: 'gojek-passenger/claim-form',
    component: asyncComponent(() => import('../Gojek/claimForm'))
  },
  {
    path: 'go-driver/claim-form',
    component: asyncComponent(() => import('../Gojek/claimForm'))
  },
  {
    path: 'go-life/claim',
    component: asyncComponent(() => import('../Golife'))
  },
  {
    path: 'go-life/:orderId/success',
    component: asyncComponent(() => import('../Golife/success'))
  },
  {
    path: 'go-life/claim-details/:id',
    component: claimDetail
  },
  {
    path: 'gojek-passenger/:orderId/success',
    component: asyncComponent(() => import('../Gojek/success'))
  },
  {
    path: 'gojek-passenger/claim-details/:id',
    component: claimDetail
  },
  {
    path: 'go-driver/:orderId/success',
    component: asyncComponent(() => import('../Gojek/success'))
  },
  {
    path: 'go-driver/claim-details/:id',
    component: claimDetail
  },
  {
    path: 'tokopedia/shipping/:id',
    component: asyncComponent(() => import('../Shipping/return.js'))
  },
  {
    path: 'tokopedia/shipping/',
    component: asyncComponent(() => import('../Shipping'))
  },
  {
    path: 'gosure/endorse/:id',
    component: asyncComponent(() => import('../Endorsements/GosureEndorsement'))
  },
  {
    path: ':productId/aggregate-endorse/:aggregateId',
    component: asyncComponent(() => import('../Endorsements/GosureEndorsement'))
  },
  // {
  //   path: 'sendo/endorse/:id',
  //   component: asyncComponent(() => import('../Endorsements/SendoEndorse'))
  // },
  // {
  //   path: 'sendo/endorse/:id',
  //   component: asyncComponent(() => import('../Endorsements/SendoPersonalAccidentProtectionPro'))
  // },
  {
    path: ':productId/endorse/:id',
    component: asyncComponent(() => import('../Endorsements'))
  }
]

class AppRouter extends Component {
  render () {
    const { url } = this.props
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}>
        <div style={{ minHeight: '100vh' }}>
          <Switch>
            {routes.map(singleRoute => {
              const { path, exact, ...otherProps } = singleRoute
              return (
                <Route
                // exact={exact !== false}
                  exact
                  key={singleRoute.path}
                  path={`${url}${path}`}
                  {...otherProps}
                />
              )
            })}
            <Route component={Page404} />
          </Switch>
        </div>
        {getAssets().footer}
      </div>
    )
  }
}

export default AppRouter
