const host = process.env.REACT_APP_URL

const createOrderUrl = () => {
  return `${host}api/v1/gojek-passenger/create-order/`
}

const getBenefitsGojekPassengerUrl = () => {
  return `${host}api/v1/gojek-passenger/benefits/`
}

const createClaimGocarPassengerUrl = () => {
  return `${host}api/v1/gojek-passenger/create-claim/`
}

const validateClaimGocarUrl = () => {
  return `${host}api/v1/gojek-passenger/validate-claim/`
}

export default {
  createOrderUrl,
  getBenefitsGojekPassengerUrl,
  createClaimGocarPassengerUrl,
  validateClaimGocarUrl
}
