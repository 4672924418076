const actions = {
  GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',
  GET_USER_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',
  GET_LOCATIONS_REQUEST: 'GET_LOCATIONS_REQUEST',
  GET_LOCATIONS_SUCCESS: 'GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAILURE: 'GET_LOCATIONS_FAILURE',
  SEND_PHONE_OTP_REQUEST: 'SEND_PHONE_OTP_REQUEST',
  SEND_PHONE_OTP_SUCCESS: 'SEND_PHONE_OTP_SUCCESS',
  SEND_PHONE_OTP_FAILURE: 'SEND_PHONE_OTP_FAILURE',
  SEND_EMAIL_OTP_REQUEST: 'SEND_EMAIL_OTP_REQUEST',
  SEND_EMAIL_OTP_SUCCESS: 'SEND_EMAIL_OTP_SUCCESS',
  SEND_EMAIL_OTP_FAILURE: 'SEND_EMAIL_OTP_FAILURE',
  VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',
  UPDATE_PHONE_REQUEST: 'UPDATE_PHONE_REQUEST',
  UPDATE_PHONE_SUCCESS: 'UPDATE_PHONE_SUCCESS',
  UPDATE_PHONE_FAILURE: 'UPDATE_PHONE_FAILURE',
  UPDATE_EMAIL_REQUEST: 'UPDATE_EMAIL_REQUEST',
  UPDATE_EMAIL_SUCCESS: 'UPDATE_EMAIL_SUCCESS',
  UPDATE_EMAIL_FAILURE: 'UPDATE_EMAIL_FAILURE',
  ADD_EMAIL_REQUEST: 'ADD_EMAIL_REQUEST',
  ADD_EMAIL_SUCCESS: 'ADD_EMAIL_SUCCESS',
  ADD_EMAIL_FAILURE: 'ADD_EMAIL_FAILURE',
  UPLOAD_USER_FILE_REQUEST: 'UPLOAD_USER_FILE_REQUEST',
  UPLOAD_USER_FILE_SUCCESS: 'UPLOAD_USER_FILE_SUCCESS',
  UPLOAD_USER_FILE_FAILURE: 'UPLOAD_USER_FILE_FAILURE',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',
  UPDATE_COMPLETE_USER_REQUEST: 'UPDATE_COMPLETE_USER_REQUEST',
  UPDATE_COMPLETE_USER_SUCCESS: 'UPDATE_COMPLETE_USER_SUCCESS',
  UPDATE_COMPLETE_USER_FAILURE: 'UPDATE_COMPLETE_USER_FAILURE',
  GET_USER_BANK_ACCOUNTS_REQUEST: 'GET_USER_BANK_ACCOUNTS_REQUEST',
  GET_USER_BANK_ACCOUNTS_SUCCESS: 'GET_USER_BANK_ACCOUNTS_SUCCESS',
  GET_USER_BANK_ACCOUNTS_FAILURE: 'GET_USER_BANK_ACCOUNTS_FAILURE',
  PUT_USER_BANK_ACCOUNTS_REQUEST: 'PUT_USER_BANK_ACCOUNTS_REQUEST',
  PUT_USER_BANK_ACCOUNTS_SUCCESS: 'PUT_USER_BANK_ACCOUNTS_SUCCESS',
  PUT_USER_BANK_ACCOUNTS_FAILURE: 'PUT_USER_BANK_ACCOUNTS_FAILURE',
  POST_USER_BANK_ACCOUNTS_REQUEST: 'POST_USER_BANK_ACCOUNTS_REQUEST',
  POST_USER_BANK_ACCOUNTS_SUCCESS: 'POST_USER_BANK_ACCOUNTS_SUCCESS',
  POST_USER_BANK_ACCOUNTS_FAILURE: 'POST_USER_BANK_ACCOUNTS_FAILURE',
  DELETE_USER_BANK_ACCOUNTS_REQUEST: 'DELETE_USER_BANK_ACCOUNTS_REQUEST',
  DELETE_USER_BANK_ACCOUNTS_SUCCESS: 'DELETE_USER_BANK_ACCOUNTS_SUCCESS',
  DELETE_USER_BANK_ACCOUNTS_FAILURE: 'DELETE_USER_BANK_ACCOUNTS_FAILURE',
  GET_USER_DOCUMENTS_REQUEST: 'GET_USER_DOCUMENTS_REQUEST',
  GET_USER_DOCUMENTS_SUCCESS: 'GET_USER_DOCUMENTS_SUCCESS',
  GET_USER_DOCUMENTS_FAILURE: 'GET_USER_DOCUMENTS_FAILURE',
  PUT_USER_DOCUMENTS_REQUEST: 'PUT_USER_DOCUMENTS_REQUEST',
  PUT_USER_DOCUMENTS_SUCCESS: 'PUT_USER_DOCUMENTS_SUCCESS',
  PUT_USER_DOCUMENTS_FAILURE: 'PUT_USER_DOCUMENTS_FAILURE',
  UPLOAD_USER_DOCUMENTS_REQUEST: 'UPLOAD_USER_DOCUMENTS_REQUEST',
  UPLOAD_USER_DOCUMENTS_SUCCESS: 'UPLOAD_USER_DOCUMENTS_SUCCESS',
  UPLOAD_USER_DOCUMENTS_FAILURE: 'UPLOAD_USER_DOCUMENTS_FAILURE',
  POST_USER_DOCUMENTS_REQUEST: 'POST_USER_DOCUMENTS_REQUEST',
  POST_USER_DOCUMENTS_SUCCESS: 'POST_USER_DOCUMENTS_SUCCESS',
  POST_USER_DOCUMENTS_FAILURE: 'POST_USER_DOCUMENTS_FAILURE',
  DELETE_USER_DOCUMENTS_REQUEST: 'DELETE_USER_DOCUMENTS_REQUEST',
  DELETE_USER_DOCUMENTS_SUCCESS: 'DELETE_USER_DOCUMENTS_SUCCESS',
  DELETE_USER_DOCUMENTS_FAILURE: 'DELETE_USER_DOCUMENTS_FAILURE',
  SET_UPDATING_DOCUMENT_ID: 'UPDATING_DOCUMENT_ID',
  SET_UPDATING_BANK_ACCOUNT_ID: 'UPDATING_BANK_ACCOUNT_ID',
  VERIFY_BANK_ACCOUNT_REQUEST: 'VERIFY_BANK_ACCOUNT_REQUEST',
  VERIFY_BANK_ACCOUNT_SUCCESS: 'VERIFY_BANK_ACCOUNT_SUCCESS',
  VERIFY_BANK_ACCOUNT_FAILURE: 'VERIFY_BANK_ACCOUNT_FAILURE',
  RESET_USER_REDUX: 'RESET_USER_REDUX',

  CLEAR_USER_PROFILE_REDUCER: 'CLEAR_USER_PROFILE_REDUCER',

  getUserProfile: () => ({
    type: actions.GET_USER_PROFILE_REQUEST
  }),
  getUserDetails: () => ({
    type: actions.GET_USER_DETAILS_REQUEST
  }),
  getLocations: () => ({
    type: actions.GET_LOCATIONS_REQUEST
  }),
  sendPhoneOtp: payload => ({
    type: actions.SEND_PHONE_OTP_REQUEST,
    payload
  }),
  sendEmailOtp: payload => ({
    type: actions.SEND_EMAIL_OTP_REQUEST,
    payload
  }),
  verifyOtp: payload => ({
    type: actions.VERIFY_OTP_REQUEST,
    payload
  }),
  updatePhone: payload => ({
    type: actions.UPDATE_PHONE_REQUEST,
    payload
  }),
  updateEmail: payload => ({
    type: actions.UPDATE_EMAIL_REQUEST,
    payload
  }),
  addEmail: payload => ({
    type: actions.ADD_EMAIL_REQUEST,
    payload
  }),
  uploadUserFile: (payload, chain = null, chainPayload = {}) => ({
    type: actions.UPLOAD_USER_FILE_REQUEST,
    payload,
    chain,
    chainPayload
  }),
  updateCompleteUser: payload => ({
    type: actions.UPDATE_COMPLETE_USER_REQUEST,
    payload
  }),
  updateUser: payload => ({
    type: actions.UPDATE_USER_REQUEST,
    payload
  }),
  updateUserProfile: payload => ({
    type: actions.UPDATE_USER_PROFILE_REQUEST,
    payload
  }),
  getUserDocuments: payload => ({
    type: actions.GET_USER_DOCUMENTS_REQUEST,
    payload
  }),
  putUserDocuments: payload => ({
    type: actions.PUT_USER_DOCUMENTS_REQUEST,
    payload
  }),
  postUserDocuments: payload => ({
    type: actions.POST_USER_DOCUMENTS_REQUEST,
    payload
  }),
  deleteUserDocuments: payload => ({
    type: actions.DELETE_USER_DOCUMENTS_REQUEST,
    payload
  }),
  getUserBankAccounts: payload => ({
    type: actions.GET_USER_BANK_ACCOUNTS_REQUEST,
    payload
  }),
  putUserBankAccounts: payload => ({
    type: actions.PUT_USER_BANK_ACCOUNTS_REQUEST,
    payload
  }),
  postUserBankAccounts: payload => ({
    type: actions.POST_USER_BANK_ACCOUNTS_REQUEST,
    payload
  }),
  deleteUserBankAccounts: payload => ({
    type: actions.DELETE_USER_BANK_ACCOUNTS_REQUEST,
    payload
  }),
  verifyBankAccount: payload => ({
    type: actions.VERIFY_BANK_ACCOUNT_REQUEST,
    payload
  }),
  resetUserRedux: payload => ({
    type: actions.RESET_USER_REDUX,
    payload
  })
}
export default actions
