import queryBuilder from '../../../helpers/queryBuilder'
import url from '../../endpoint/lazadaCrackScreenEndorse'
import notAuthGetRequest from '../noAuthGet'
import postRequest from '../post'

const getLazadaCrackScreenPolicyDetails = async ({ query }) => {
  try {
    const queryString = queryBuilder(query)
    const base = `${url.lazadaCrackScreenPolicyDetailsUrl()}${queryString}`
    return await notAuthGetRequest(base)
  } catch (error) {
    return error.response
  }
}
const getLazadaFullGadgetProtectionPolicyDetails = async ({ query }) => {
  try {
    const queryString = queryBuilder(query)
    const base = `${url.lazadaFullGadgetPolicyDetailsUrl()}${queryString}`
    return await notAuthGetRequest(base)
  } catch (error) {
    return error.response
  }
}
const getLazadaVehiclePolicyDetails = async ({ query }) => {
  try {
    const queryString = queryBuilder(query)
    const base = `${url.lazadaVehiclePolicyDetailsUrl()}${queryString}`
    return await notAuthGetRequest(base)
  } catch (error) {
    return error.response
  }
}
const postLazadaCrackScreenPolicyEndorse = async ({ body = {}, params = {} }) => {
  try {
    const base = url.postLazadaCrackScreenPolicyEndorse(params)
    return await postRequest(base, body)
  } catch (error) {
    return error.response
  }
}
const getLazadaHospitalCashPlanEndorse = async ({ query }) => {
  try {
    const queryString = queryBuilder(query)
    const base = `${url.getLazadaHospitalCashPlanEndorse()}${queryString}`
    return await notAuthGetRequest(base)
  } catch (error) {
    return error.response
  }
}

const getprovinceData = async () => {
  try {
    const base = url.getprovinceDataUrl()
    return await notAuthGetRequest(base)
  } catch (error) {
    return error.response
  }
}
export default {
  getLazadaFullGadgetProtectionPolicyDetails,
  getLazadaCrackScreenPolicyDetails,
  postLazadaCrackScreenPolicyEndorse,
  getLazadaVehiclePolicyDetails,
  getLazadaHospitalCashPlanEndorse,
  getprovinceData
}
