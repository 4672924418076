import styled from 'styled-components'

export default styled.div`
    background-color: #fff;
    box-shadow: rgba(0,0,0,.04) 0 2px 6px 0;
    .pasarpolis{
      font-size: 12px;
    }
    h3{
      margin-bottom: 0px;
    }
    .pasarpolis b{
      color: #ffd500;
    }
    .burger-menu {
        display: none;
    }
    .ant-select-dropdown ul li .flag-img {
        width: 25px;
        height: auto;
        margin-bottom: 2px;
    }
    .header .section-lang {
        display: flex;
        margin-right: 5px;
    }
    .header {
        display: flex;
        justify-content: space-between;
        height: 70px;
        align-items: center;
        z-index: 100000;
    }
    .header img {
        width: 120px;
        height: auto;
    }
    .logout-container a {
        color: #000;
    }
    .user-icon p {
        margin-bottom: 0;
    }
    .section-lang .ant-select-selection{
        background-color: unset;
        border: unset;
    }
    .ant-select:focus {
        outline: none !important;
    }
    .section-lang .ant-select-selection:focus {
        outline: none !important;
    }
    .section-lang .ant-select-arrow svg {
        color: #000;
    }
    .action-container {
        position: relative;
    }
    .action-container .logout-container {
        margin-left: 10px;
    }
    .action-container .logout-container:after {
        content: '';
        width: 0;
        height: 100%;
        position: absolute;
        border: .5px solid black;
        margin-left: 5px;
        margin-right: 15px;
        top: 0;
        left: -10px;
    }
    @media (min-width: 320px) and (max-width: 480px) {
        .header div:first-child {
            padding-left: 10px;
        }
        .header{
          padding-left: 15px;
        }
    }
    @media only screen and (max-width: 768px) {
        .header {
            height: 40px;
            background-color: #ffd500;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            right: 0;
            left: 0;
            padding-left: 15px;
            z-index: 100000;
        }
        .header img {
            width: 120px;
            height: auto;
        }
        .user-icon {
            display: flex;
            align-items: center;
        }
        .user-icon p {
            display: none;
        }
        .header .section-lang {
            display: none;
        }
        .burger-menu {
            display: block;
        }
        .second-level-menu {
            display: none;
        }
        .second-level-menu:hover {
            display: block;
        }
        .bm-item {
            border-bottom: 1px solid #ffd500;
        }
        .bm-item ul li{
            padding: 10px 10px;
        }
        .bm-item:focus {
            outline: none;
        }
        .bm-item a {
            color: #7a7a7a;
        }
    }
`
