const host = process.env.REACT_APP_URL
const BASE_PATH = 'api/v1/claim/'
const BASE_PATH_V2 = 'api/v2/claim/'
const BASE_PATH_V3 = 'api/v3/claims/'
const BASE_PATH_V4 = 'api/v4/claim/'

const getBenefits = payload => {
  return `${host}${BASE_PATH}benefits/?package=${payload.package}&product=${
    payload.product
  }`
}

const getBenefitsV3 = () => {
  return `${host}api/v3/claim/benefits/`
}

const getBenefitsV4 = () => {
  return `${host}api/v4/claim/benefits/`
}

const getFormBenefits = payload => {
  return `${host}${BASE_PATH}form/${payload}/`
}

const postClaim = () => {
  return `${host}${BASE_PATH_V4}create/`
}

const getClaimDetailsUrl = () => {
  return `${host}${BASE_PATH_V3}details/`
}

// const uploadClaimDocument = () => {
//   return `${host}api/v1/claim/upload/`
// }

const uploadGenericClaimDocument = payload => {
  return `${host}${BASE_PATH_V2}save-form-attribute/?claim_id=${
    payload.claim_id
  }&form=${payload.form}`
}

const saveGenericAttributesUrl = () => {
  return `${host}${BASE_PATH_V3}save-form/`
}

const finalSubmitUrl = () => {
  return `${host}${BASE_PATH_V3}submit/`
}

const verifyBankAccount = () => {
  return `${host}${BASE_PATH}verify-bank-account/`
}

const validateClaimUrl = () => {
  return `${host}${BASE_PATH_V4}validate-claim/`
}

const getFeedbackFormsUrl = () => {
  return `${host}${BASE_PATH}feedback_forms/`
}
const getFeedbackEmailFormsUrl = () => {
  return `${host}api/v1/feedback/feedback_forms/`
}

const postFeedbackFormsUrl = () => {
  return `${host}${BASE_PATH}give_feedback/`
}

const postFeedbackEmailFormsUrl = () => {
  return `${host}api/v1/feedback/give_feedback/`
}
const postSalvageFormUrl = (claimID) => {
  return `${host}${BASE_PATH_V3}salvage/submit-form/`
}
const createShippingClaim = () => {
  return `${host}${BASE_PATH}create-shipping-claim/`
}

const uploadCommentingDoc = () => {
  return `${host}${BASE_PATH}upload-file/`
}

const createVerifyImageRequest = ({ category }) => {
  return `${host}api/v1/image-processor/${category}/extract-info/`
}

const getVerifyImageRequestStatus = ({ requestId }) => {
  return `${host}api/v1/image-processor/${requestId}/status/`
}

const updateFormDoc = () => {
  return `${host}${BASE_PATH_V3}save-form-attributes/`
}

const savePaymentForm = () => {
  return `${host}${BASE_PATH_V3}save-payment-form/`
}

const claimBankListUrl = () => {
  return `${host}api/v1/claim/get-bank-list/`
}

const choicesExternalData = () => {
  return `${host}api/v1/claim/get-diagnostic-list/`
}

const getProvinceListUrl = () => {
  return `${host}api/v1/claim/province-list/`
}

const getCityOnProvinceList = () => {
  return `${host}api/v1/claim/cities/`
}

const getPostalCodeListUrl = () => {
  return `${host}api/v1/claim/postal-codes/`
}

const getServiceCenterListUrl = () => {
  return `${host}api/v1/claim/select-service-center/`
}

const getServiceCenterBranchListUrl = () => {
  return `${host}api/v1/claim/service-branch-list/`
}

const getTotalServiceCenterListUrl = () => {
  return `${host}api/v1/claim/list-service-center/`
}

const getQuestionnaireUrl = () => {
  return `${host}api/v1/claim/get-questions/`
}

const getServiceCenterBrandListUrl = `${host}api/v1/claim/brand-list/`

const getFamilyEligibleUserListUrl = `${host}api/v1/claim/eligible-users/`
const postFamilyCreateClaims = `${host}${BASE_PATH_V4}create/`
const getAssocaitedUserOnClaimUrl = `${host}${BASE_PATH}user-family-claims/`

export default {
  getTotalServiceCenterListUrl,
  getServiceCenterListUrl,
  getServiceCenterBranchListUrl,
  getBenefits,
  getFormBenefits,
  postClaim,
  getClaimDetailsUrl,
  getPostalCodeListUrl,
  uploadGenericClaimDocument,
  uploadCommentingDoc,
  saveGenericAttributesUrl,
  finalSubmitUrl,
  verifyBankAccount,
  validateClaimUrl,
  getFeedbackFormsUrl,
  postFeedbackFormsUrl,
  getFeedbackEmailFormsUrl,
  postFeedbackEmailFormsUrl,
  postSalvageFormUrl,
  createShippingClaim,
  createVerifyImageRequest,
  getVerifyImageRequestStatus,
  updateFormDoc,
  getBenefitsV3,
  getBenefitsV4,
  savePaymentForm,
  claimBankListUrl,
  choicesExternalData,
  getProvinceListUrl,
  getCityOnProvinceList,
  getQuestionnaireUrl,
  getServiceCenterBrandListUrl,
  getFamilyEligibleUserListUrl,
  postFamilyCreateClaims,
  getAssocaitedUserOnClaimUrl
}
