import { all, takeEvery, put, fork, call, take, race } from 'redux-saga/effects'
import actions from './actions'
import authActions from '../auth/actions'
import request from '../../request/axios/user'
import _ from 'lodash/object'

export function * getUserProfileRequest () {
  yield takeEvery(actions.GET_USER_PROFILE_REQUEST, function * () {
    try {
      let res = yield call(request.getUserProfile)
      if (res.status === 200) {
        yield put({
          type: actions.GET_USER_PROFILE_SUCCESS,
          data: res.data,
          message: 'Sukses'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.GET_USER_PROFILE_FAILURE,
          data: {},
          message: 'Gagal mendapatkan profil pengguna'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_USER_PROFILE_FAILURE,
        data: {},
        message: 'Gagal mendapatkan profil pengguna'
        // TODO: move to constant
      })
    }
  })
}

export function * getUserDetailsRequest () {
  yield takeEvery(actions.GET_USER_DETAILS_REQUEST, function * () {
    try {
      let res = yield call(request.getUserDetails)
      if (res.status === 200) {
        const thirdPartyUser = !!(res.data.is_third_party_user)
        const isDanaUser = res.data.partner === 'dana'

        yield put({
          type: actions.GET_USER_DETAILS_SUCCESS,
          data: res.data,
          thirdPartyUser,
          isDanaUser,
          message: 'Sukses'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.GET_USER_DETAILS_FAILURE,
          data: {},
          message: 'Gagal mendapatkan rincian pengguna'
        // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_USER_DETAILS_FAILURE,
        data: {},
        message: 'Gagal mendapatkan rincian pengguna'
        // TODO: move to constant
      })
    }
  })
}

export function * getLocationsRequest () {
  yield takeEvery(actions.GET_LOCATIONS_REQUEST, function * () {
    try {
      let res = yield call(request.getLocations)
      if (res.status === 200) {
        yield put({
          type: actions.GET_LOCATIONS_SUCCESS,
          data: res.data,
          message: 'Sukses'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.GET_LOCATIONS_FAILURE,
          data: [],
          message: 'Gagal mendapatkan daftar lokasi'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_LOCATIONS_FAILURE,
        data: [],
        message: 'Gagal mendapatkan daftar lokasi'
        // TODO: move to constant
      })
    }
  })
}

export function * getSendPhoneOtpRequest () {
  yield takeEvery(actions.SEND_PHONE_OTP_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.sendOtp, payload)
      if (res.status === 200) {
        yield put({
          type: actions.SEND_PHONE_OTP_SUCCESS,
          data: res.data.operation_id,
          message: 'Sukses'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.SEND_PHONE_OTP_FAILURE,
          data: '',
          message: 'Gagal mengirim OTP'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.SEND_PHONE_OTP_FAILURE,
        data: '',
        message: 'Gagal mengirim OTP'
        // TODO: move to constant
      })
    }
  })
}

export function * getSendEmailOtpRequest () {
  yield takeEvery(actions.SEND_EMAIL_OTP_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.sendOtp, payload)
      if (res.status === 200) {
        yield put({
          type: actions.SEND_EMAIL_OTP_SUCCESS,
          data: res.data.operation_id,
          message: 'Sukses'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.SEND_EMAIL_OTP_FAILURE,
          data: '',
          message: 'Gagal mengirim OTP Email'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.SEND_EMAIL_OTP_FAILURE,
        data: '',
        message: 'Gagal mengirim OTP Email'
        // TODO: move to constant
      })
    }
  })
}

export function * getVerifyOtpRequest () {
  yield takeEvery(actions.VERIFY_OTP_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.verifyOtp, payload)
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_OTP_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.VERIFY_OTP_FAILURE,
          data: ''
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_OTP_FAILURE,
        data: ''
      })
    }
  })
}

export function * getUpdatePhoneRequest () {
  yield takeEvery(actions.UPDATE_PHONE_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.updatePhone, payload)
      if (res.status === 200) {
        yield put({
          type: actions.UPDATE_PHONE_SUCCESS,
          code: '',
          message: _.get(res, 'data.message', 'Sukses')
        })
        yield put(authActions.signOut())
      } else {
        yield put({
          type: actions.UPDATE_PHONE_FAILURE,
          code: _.get(res, 'data.code', ''),
          message: _.get(res, 'data.message', 'Gagal memperbarui Nomor Telepon')
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPDATE_PHONE_FAILURE,
        code: '',
        message: e.message
      })
    }
  })
}

export function * getUpdateEmailRequest () {
  yield takeEvery(actions.UPDATE_EMAIL_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.updateEmail, payload)
      if (res.status === 200) {
        yield put({
          type: actions.UPDATE_EMAIL_SUCCESS,
          code: '',
          message: _.get(res, 'data.message', 'Sukses')
        })
        yield put({ type: actions.GET_USER_DETAILS_REQUEST })
      } else {
        yield put({
          type: actions.UPDATE_EMAIL_FAILURE,
          code: _.get(res, 'data.code', ''),
          message: _.get(res, 'data.message', 'Gagal memperbarui Email')
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPDATE_EMAIL_FAILURE,
        code: '',
        message: e.message
      })
    }
  })
}

export function * getAddEmailRequest () {
  yield takeEvery(actions.ADD_EMAIL_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.addEmail, payload)
      if (res.status === 200) {
        yield put({
          type: actions.ADD_EMAIL_SUCCESS,
          code: '',
          message: _.get(res, 'data.message', 'Sukses')
        })
        yield put({ type: actions.GET_USER_DETAILS_REQUEST })
      } else {
        yield put({
          type: actions.ADD_EMAIL_FAILURE,
          code: _.get(res, 'data.code', ''),
          message: _.get(res, 'data.message', 'Gagal memperbarui Email')
        })
      }
    } catch (e) {
      yield put({
        type: actions.ADD_EMAIL_FAILURE,
        code: '',
        message: e.message
      })
    }
  })
}

export function * getUploadUserFileRequest () {
  yield takeEvery(actions.UPLOAD_USER_FILE_REQUEST, function * ({ payload, chain, chainPayload }) {
    try {
      let res = yield call(request.uploadFile, payload)
      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.UPLOAD_USER_FILE_SUCCESS,
          data: _.get(res.data, 'data', ''),
          message: 'Sukses'
          // TODO: move to constant
        })
        if (chain) {
          const mergedPayload = _.merge(chainPayload, { body: { ..._.get(res.data, 'data', {}) } })
          yield put(chain(mergedPayload))
        }
      } else {
        yield put({
          type: actions.UPLOAD_USER_FILE_FAILURE,
          data: _.get(res.data, 'data', ''),
          message: 'Gagal unggah file'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPLOAD_USER_FILE_FAILURE,
        data: {},
        message: 'Gagal unggah file'
        // TODO: move to constant
      })
    }
  })
}

export function * getUpdateCompleteUserRequest () {
  yield takeEvery(actions.UPDATE_COMPLETE_USER_REQUEST, function * ({ payload }) {
    yield put(actions.updateUser(payload))
    yield put(actions.updateUserProfile(payload))

    const [, failure] = yield race([
      // if this occurs first, the race will exit, and success will be truthy
      all([
        take(actions.UPDATE_USER_SUCCESS),
        take(actions.UPDATE_USER_PROFILE_SUCCESS)
      ]),

      // if either of these occurs first, the race will exit, and failure will be truthy
      take(actions.UPDATE_USER_FAILURE),
      take(actions.UPDATE_USER_PROFILE_FAILURE)
    ])

    if (failure) {
      yield put({
        type: actions.UPDATE_COMPLETE_USER_FAILURE,
        message: 'Gagal update user'
        // TODO: move to constant
      })
    } else {
      yield put({
        type: actions.UPDATE_COMPLETE_USER_SUCCESS,
        message: 'Sukses'
        // TODO: move to constant
      })
      yield put(actions.getUserDetails())
    }
  })
}

export function * getUpdateUserRequest () {
  yield takeEvery(actions.UPDATE_USER_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.updateUser, payload)
      if (res.status === 200) {
        yield put({
          type: actions.UPDATE_USER_SUCCESS,
          message: 'Sukses'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.UPDATE_USER_FAILURE,
          message: 'Gagal update user'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPDATE_USER_FAILURE,
        message: 'Gagal update user'
        // TODO: move to constant
      })
    }
  })
}

export function * getUpdateUserProfileRequest () {
  yield takeEvery(actions.UPDATE_USER_PROFILE_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.updateUserProfile, payload)
      if (res.status === 200) {
        yield put({
          type: actions.UPDATE_USER_PROFILE_SUCCESS,
          message: 'Sukses'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.UPDATE_USER_PROFILE_FAILURE,
          message: 'Gagal update user'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPDATE_USER_PROFILE_FAILURE,
        message: 'Gagal update user'
        // TODO: move to constant
      })
    }
  })
}

export function * getUserBankAccounts () {
  yield takeEvery(actions.GET_USER_BANK_ACCOUNTS_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getBankAccounts, payload)
      if (res.status === 200) {
        const data = res.data
        let preferredBankAccountId
        if (Array.isArray(data)) {
          preferredBankAccountId = _.get(data.find(({ is_preferred: isPreferred }) => isPreferred), 'id')
        }
        yield put({
          type: actions.GET_USER_BANK_ACCOUNTS_SUCCESS,
          message: 'Sukses',
          data: res.data,
          preferredBankAccountId
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.GET_USER_BANK_ACCOUNTS_FAILURE,
          message: 'Gagal mendapatkan daftar rekening',
          data: []
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_USER_BANK_ACCOUNTS_FAILURE,
        message: 'Gagal mendapatkan daftar rekening',
        data: []
        // TODO: move to constant
      })
    }
  })
}

export function * getUpdateUserBankAccount () {
  yield takeEvery(actions.PUT_USER_BANK_ACCOUNTS_REQUEST, function * ({ payload }) {
    yield put({
      type: actions.SET_UPDATING_BANK_ACCOUNT_ID,
      id: _.get(payload, 'body.id')
    })
    try {
      let res = yield call(request.putBankAccounts, payload)
      if (res.status === 200) {
        yield put({
          type: actions.PUT_USER_BANK_ACCOUNTS_SUCCESS,
          message: 'Sukses',
          data: res.data
          // TODO: move to constant
        })
        yield put({
          type: actions.SET_UPDATING_BANK_ACCOUNT_ID,
          id: ''
        })
        yield put({ type: actions.GET_USER_BANK_ACCOUNTS_REQUEST })
      } else {
        yield put({
          type: actions.PUT_USER_BANK_ACCOUNTS_FAILURE,
          message: 'Gagal menyimpan rekening',
          data: []
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.PUT_USER_BANK_ACCOUNTS_FAILURE,
        message: 'Gagal menyimpan rekening',
        data: []
        // TODO: move to constant
      })
    }
  })
}

export function * getPostUserBankAccount () {
  yield takeEvery(actions.POST_USER_BANK_ACCOUNTS_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.postBankAccounts, payload)
      if (res.status === 200) {
        yield put({
          type: actions.POST_USER_BANK_ACCOUNTS_SUCCESS,
          message: 'Sukses',
          data: res.data
          // TODO: move to constant
        })
        yield put({ type: actions.GET_USER_BANK_ACCOUNTS_REQUEST })
      } else {
        yield put({
          type: actions.POST_USER_BANK_ACCOUNTS_FAILURE,
          message: _.get(res, 'data.message', 'Gagal membuat rekening'),
          data: []
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.POST_USER_BANK_ACCOUNTS_FAILURE,
        message: 'Gagal membuat rekening',
        data: []
        // TODO: move to constant
      })
    }
  })
}

export function * getDeleteUserBankAccount () {
  yield takeEvery(actions.DELETE_USER_BANK_ACCOUNTS_REQUEST, function * ({ payload }) {
    yield put({
      type: actions.SET_UPDATING_BANK_ACCOUNT_ID,
      id: _.get(payload, 'body.id')
    })
    try {
      let res = yield call(request.deleteBankAccounts, payload)
      if (res.status === 200) {
        yield put({
          type: actions.DELETE_USER_BANK_ACCOUNTS_SUCCESS,
          message: 'Sukses',
          data: res.data
          // TODO: move to constant
        })
        yield put({
          type: actions.SET_UPDATING_BANK_ACCOUNT_ID,
          id: ''
        })
        yield put({ type: actions.GET_USER_BANK_ACCOUNTS_REQUEST })
      } else {
        yield put({
          type: actions.DELETE_USER_BANK_ACCOUNTS_FAILURE,
          message: 'Gagal menghapus rekening',
          data: []
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.DELETE_USER_BANK_ACCOUNTS_FAILURE,
        message: 'Gagal menghapus rekening',
        data: []
        // TODO: move to constant
      })
    }
  })
}

export function * getDocuments () {
  yield takeEvery(actions.GET_USER_DOCUMENTS_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getDocuments, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_USER_DOCUMENTS_SUCCESS,
          message: 'Sukses',
          data: res.data
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.GET_USER_DOCUMENTS_FAILURE,
          message: 'Gagal mendapatkan daftar dokumen',
          data: []
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_USER_DOCUMENTS_FAILURE,
        message: 'Gagal mendapatkan daftar dokumen',
        data: []
        // TODO: move to constant
      })
    }
  })
}

export function * getUpdateDocuments () {
  yield takeEvery(actions.PUT_USER_DOCUMENTS_REQUEST, function * ({ payload }) {
    yield put({
      type: actions.SET_UPDATING_DOCUMENT_ID,
      id: _.get(payload, 'body.id')
    })
    try {
      let res = yield call(request.putDocuments, payload)
      if (res.status === 200) {
        yield put({
          type: actions.PUT_USER_DOCUMENTS_SUCCESS,
          message: 'Sukses',
          data: res.data
          // TODO: move to constant
        })
        yield put({
          type: actions.SET_UPDATING_DOCUMENT_ID,
          id: ''
        })
        yield put({ type: actions.GET_USER_DOCUMENTS_REQUEST })
      } else {
        yield put({
          type: actions.PUT_USER_DOCUMENTS_FAILURE,
          message: 'Gagal memperbarui dokumen',
          data: []
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.PUT_USER_DOCUMENTS_FAILURE,
        message: 'Gagal memperbarui dokumen',
        data: []
        // TODO: move to constant
      })
    }
  })
}

export function * getPostDocuments () {
  yield takeEvery(actions.POST_USER_DOCUMENTS_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.postDocuments, payload)
      if (res.status === 200) {
        yield put({
          type: actions.POST_USER_DOCUMENTS_SUCCESS,
          message: 'Sukses',
          data: res.data
          // TODO: move to constant
        })
        yield put({ type: actions.GET_USER_DOCUMENTS_REQUEST })
      } else {
        yield put({
          type: actions.POST_USER_DOCUMENTS_FAILURE,
          message: 'Gagal mengunggah dokumen',
          data: []
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.POST_USER_DOCUMENTS_FAILURE,
        message: 'Gagal mengunggah dokumen',
        data: []
        // TODO: move to constant
      })
    }
  })
}

export function * getDeleteDocumentsRequest () {
  yield takeEvery(actions.DELETE_USER_DOCUMENTS_REQUEST, function * ({ payload }) {
    yield put({
      type: actions.SET_UPDATING_DOCUMENT_ID,
      id: _.get(payload, 'body.id')
    })
    try {
      let res = yield call(request.deleteDocuments, payload)
      if (res.status === 200) {
        yield put({
          type: actions.DELETE_USER_DOCUMENTS_SUCCESS,
          message: 'Sukses',
          data: res.data
          // TODO: move to constant
        })
        yield put({
          type: actions.SET_UPDATING_DOCUMENT_ID,
          id: ''
        })
        yield put({ type: actions.GET_USER_DOCUMENTS_REQUEST })
      } else {
        yield put({
          type: actions.DELETE_USER_DOCUMENTS_FAILURE,
          message: 'Gagal menghapus dokumen',
          data: []
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.DELETE_USER_DOCUMENTS_FAILURE,
        message: 'Gagal menghapus dokumen',
        data: []
        // TODO: move to constant
      })
    }
  })
}

export function * verifyBankAccountForms (props) {
  yield takeEvery(actions.VERIFY_BANK_ACCOUNT_REQUEST, function * ({ payload }) {
    const { body } = payload
    const remappedPayload = {
      body: {
        payload: {
          account_holder_name: body.holder_name,
          account_number: body.account_no,
          bank_code: body.bank_code,
          branch_name: body.branch_name
        }
      }
    }
    try {
      let res = yield call(request.verifyBankAccount, remappedPayload)
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_BANK_ACCOUNT_SUCCESS,
          data: res.data,
          status: 'success'
        })
      } else {
        yield put({
          type: actions.VERIFY_BANK_ACCOUNT_FAILURE,
          data: res.data,
          status: _.get(res, 'data.error', '')
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.VERIFY_BANK_ACCOUNT_FAILURE,
          status: e.data.error
        })
      }
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getUserProfileRequest),
    fork(getUserDetailsRequest),
    fork(getLocationsRequest),
    fork(getSendPhoneOtpRequest),
    fork(getSendEmailOtpRequest),
    fork(getVerifyOtpRequest),
    fork(getUpdatePhoneRequest),
    fork(getUpdateEmailRequest),
    fork(getAddEmailRequest),
    fork(getUploadUserFileRequest),
    fork(getUpdateUserRequest),
    fork(getUpdateUserProfileRequest),
    fork(getUserBankAccounts),
    fork(getUpdateUserBankAccount),
    fork(getPostUserBankAccount),
    fork(getDeleteUserBankAccount),
    fork(getDocuments),
    fork(getUpdateDocuments),
    fork(getPostDocuments),
    fork(getDeleteDocumentsRequest),
    fork(getUpdateCompleteUserRequest),
    fork(verifyBankAccountForms)
  ])
}
