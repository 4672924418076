import getRequest from '../get'
import authGet from '../authGet'
import postRequest from '../authPost'
import postUploadRequest from '../postUpload'
import url from '../../endpoint/claim'
import fileUrl from '../../endpoint/file'
import tanyaUrl from '../../endpoint/tanya'
import axios from 'axios'
import { getHeader } from '../../../helpers/utility'
import queryBuilder from '../../../helpers/queryBuilder'

const getBenefits = async ({ payload }) => {
  try {
    const uri = url.getBenefits(payload)
    return await getRequest(uri)
  } catch (error) {
    return error
  }
}

const getBenefitsV3 = async ({ query }) => {
  try {
    const queryString = queryBuilder(query)
    const uri = `${url.getBenefitsV3()}${queryString}`
    return await getRequest(uri)
  } catch (error) {
    return error
  }
}

const getBenefitsV4 = async ({ query }) => {
  try {
    const queryString = queryBuilder(query)
    const uri = `${url.getBenefitsV4()}${queryString}`
    return await getRequest(uri)
  } catch (error) {
    return error
  }
}

const getFormBenefits = async ({ payload }) => {
  try {
    const uri = url.getFormBenefits(payload)
    return await getRequest(uri)
  } catch (error) {
    return error
  }
}

const postClaim = async ({ payload }) => {
  const { question_data: questionData = [] } = payload
  try {
    const data = {
      application_no: payload.applicationNo,
      benefit_id: payload.benefitId,
      source: payload.source,
      user_id: payload.user_id,
      question_data: questionData
    }
    const uri = url.postClaim()
    return await postRequest(uri, data)
  } catch (error) {
    return error
  }
}

const getClaimDetails = async ({ payload }) => {
  try {
    const uri = url.getClaimDetailsUrl()
    const params = {
      'claim_id': payload
    }
    return await getRequest(uri, params)
  } catch (error) {
    return error
  }
}

const uploadGenericClaimDoc = async (payload) => {
  try {
    const uri = url.uploadGenericClaimDocument(payload)
    const { url: fileUrl, filename } = payload.signedUrlData
    let formData = payload.formData
    formData.append('file_url', fileUrl)
    formData.append('file_name', filename)
    formData.delete('file')
    return await postRequest(uri, formData)
  } catch (error) {
    return error.response
  }
}

const saveGenericAttributes = async (payloadData) => {
  try {
    const { claim_id: claimID, form, payload } = payloadData
    const uri = url.saveGenericAttributesUrl()
    payload['claim_id'] = claimID
    payload['form_slug'] = form
    return await postRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const finalSubmit = async (payload) => {
  try {
    const uri = url.finalSubmitUrl()
    return await postRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const verifyBankAccount = async ({ payload }) => {
  const uri = url.verifyBankAccount()
  return axios
    .post(`${uri}`, { payload }, { headers: getHeader() })
    .catch(error => {
      if (error.response) {
        throw error.response
      } else if (error.request) {
        throw error.request
      } else {
        throw error.message
      }
    })
    .then(response => {
      return response
    })
}

const validateClaimUrl = async ({ payload }) => {
  try {
    const uri = url.validateClaimUrl()
    return await getRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const getFeedbackFormsUrl = async ({ payload }) => {
  try {
    const uri = url.getFeedbackEmailFormsUrl() + '?rating=' + payload.rating + '&feedback_request_id=' + payload.reqId
    return await axios.get(uri)
  } catch (error) {
    return error.response
  }
}

const getFeedbackEmailFormsUrl = async ({ payload }) => {
  try {
    const uri = url.getFeedbackEmailFormsUrl() + '?rating=' + payload.rating + '&feedback_request_id=' + payload.reqId
    return await axios.get(uri)
  } catch (error) {
    return error.response
  }
}

const postFeedbackFormsUrl = async ({ payload }) => {
  try {
    const uri = url.postFeedbackEmailFormsUrl()
    return await axios.post(uri, payload)
  } catch (error) {
    return error.response
  }
}

const postFeedbackEmailFormsUrl = async ({ payload }) => {
  try {
    const uri = url.postFeedbackEmailFormsUrl()
    return await postRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}
const postSalvageForms = async (payloadData) => {
  try {
    const uri = url.postSalvageFormUrl()
    return await postRequest(uri, payloadData)
  } catch (error) {
    return error.response
  }
}
const createShippingClaim = async ({ payload }) => {
  try {
    const uri = url.createShippingClaim()
    return await axios.post(uri, payload)
  } catch (error) {
    return error.response
  }
}
const getComments = async ({ payload }) => {
  try {
    const uri = tanyaUrl.getCommentsUrl()
    const params = {
      'entity_ref_id': payload
    }
    return await getRequest(uri, params)
  } catch (error) {
    return error.response
  }
}

const getTickets = async ({ payload }) => {
  try {
    const uri = tanyaUrl.getTicketsUrl()
    const params = {
      'entity_ref_id': payload
    }
    return await getRequest(uri, params)
  } catch (error) {
    return error.response
  }
}

const createVerifyImageRequest = async ({ body, params }) => {
  try {
    const uri = url.createVerifyImageRequest(params)
    return await postRequest(uri, body)
  } catch (error) {
    return error.response
  }
}
const addComment = async (payload) => {
  try {
    const uri = tanyaUrl.addCommentUrl()
    return await postRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const getVerifyImageRequestStatus = async ({ params = { requestId: '' } }) => {
  try {
    const uri = url.getVerifyImageRequestStatus(params)
    return await authGet(uri)
  } catch (error) {
    return error.response
  }
}
const uploadCommentingDoc = async (payload) => {
  try {
    const uri = url.uploadCommentingDoc(payload)
    return await postUploadRequest(uri, payload.formData)
  } catch (error) {
    return error.response
  }
}

const updateFormAttribute = async ({ params, body }) => {
  try {
    const { claimId, form } = params
    body['claim_id'] = claimId
    body['form_slug'] = form
    const uri = url.updateFormDoc(params)
    return await postRequest(uri, body)
  } catch (error) {
    return error.response
  }
}

const savePaymentForm = async (body) => {
  try {
    let { claim_id: claimID, form, payload } = body
    payload = {
      ...payload,
      claim_id: claimID,
      form_slug: form
    }
    const uri = url.savePaymentForm()
    return await postRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const getClaimBankList = async (query) => {
  try {
    const uri = url.claimBankListUrl()
    return await getRequest(uri, query)
  } catch (error) {
    return error.response
  }
}

const getserviceCenterList = async (query) => {
  try {
    const uri = url.getServiceCenterListUrl()
    return await getRequest(uri, query)
  } catch (error) {
    return error.response
  }
}

const getChoicesExternalData = async (query) => {
  try {
    const uri = url.choicesExternalData()
    return await getRequest(uri, query)
  } catch (error) {
    return error.response
  }
}

const getSignedUrl = async (payloadData) => {
  try {
    const { formData } = payloadData
    const uri = fileUrl.generateSignedURL
    return await postRequest(uri, formData, 'multipart/form-data')
  } catch (err) {
    return err.response
  }
}

const fileUploadUsingSignedUrl = async ({ signedUrlData, file }) => {
  try {
    return await axios.put(signedUrlData.signed_url,
      file,
      { headers: { 'Content-Type': file.type, 'Host': 'storage.googleapis.com' } }
    )
  } catch (error) {
    return error.message
  }
}

const getProvinceList = async (query) => {
  try {
    return await getRequest(url.getProvinceListUrl(), query)
  } catch (err) {
    return err.message
  }
}

const getCityOnProvinceList = async (query) => {
  try {
    return await getRequest(url.getCityOnProvinceList(), query)
  } catch (err) {
    return err.message
  }
}

const getPostalCodeList = async (query) => {
  try {
    return await getRequest(url.getPostalCodeListUrl(), query)
  } catch (err) {
    return err.message
  }
}

const getServiceCenterBranchList = async (query) => {
  try {
    return await getRequest(url.getServiceCenterBranchListUrl(), query)
  } catch (err) {
    throw err
  }
}

const getTotalServiceCenterList = async (query) => {
  try {
    return await getRequest(url.getTotalServiceCenterListUrl(), query)
  } catch (err) {
    return err.message
  }
}

const getQuetionnaireList = async (payload) => {
  try {
    return await postRequest(url.getQuestionnaireUrl(), payload)
  } catch (error) {
    return error
  }
}

const getServiceCenterBrandList = async (payload) => {
  try {
    return await getRequest(url.getServiceCenterBrandListUrl, payload)
  } catch (error) {
    return error
  }
}

const getFamilyEligibleUserList = async (payload) => {
  try {
    return await getRequest(url.getFamilyEligibleUserListUrl, payload)
  } catch (error) {
    return error
  }
}

const postFamilyCreateClaims = async (payload) => {
  try {
    return await postRequest(url.postFamilyCreateClaims, payload)
  } catch (error) {
    return error
  }
}

const getAssocaitedUserOnClaim = async (payload) => {
  try {
    const params = {
      'claim_id': payload
    }
    return await getRequest(url.getAssocaitedUserOnClaimUrl, params)
  } catch (error) {
    return error
  }
}

export default {
  getTotalServiceCenterList,
  getServiceCenterBranchList,
  getPostalCodeList,
  getBenefits,
  getFormBenefits,
  postClaim,
  getClaimDetails,
  uploadGenericClaimDoc,
  saveGenericAttributes,
  finalSubmit,
  verifyBankAccount,
  validateClaimUrl,
  getFeedbackFormsUrl,
  postFeedbackFormsUrl,
  getFeedbackEmailFormsUrl,
  postFeedbackEmailFormsUrl,
  postSalvageForms,
  createShippingClaim,
  getComments,
  getTickets,
  addComment,
  uploadCommentingDoc,
  createVerifyImageRequest,
  getVerifyImageRequestStatus,
  updateFormAttribute,
  getBenefitsV3,
  getBenefitsV4,
  savePaymentForm,
  getSignedUrl,
  fileUploadUsingSignedUrl,
  getClaimBankList,
  getChoicesExternalData,
  getserviceCenterList,
  getProvinceList,
  getCityOnProvinceList,
  getQuetionnaireList,
  getServiceCenterBrandList,
  getFamilyEligibleUserList,
  postFamilyCreateClaims,
  getAssocaitedUserOnClaim
}
