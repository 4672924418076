import actions from './actions'

const initState = {
  policyList: {},
  policyDetails: {},
  failedGetPolicyListMessage: '',
  getPolicyDetailResult: '',
  getPolicyListMessage: '',
  data: [],
  getClaimListMessage: '',
  claimData: [],
  policyClaimsData: [],
  getPolicyClaimListMessage: '',
  aggregateDetailsData: [],
  metadata: {},
  tokenisedEndorsementData: {},
  tokenisedEndorsementDataMessage: '',
  tokenisedEndorsementDetail: {},
  authorizedEndorsementData: [],
  authorizedEndorsementDataMessage: '',
  tag: false,
  sendOtpMessage: '',
  sendOtpData: undefined,
  resendOtpMessage: '',
  resendOtpData: undefined,
  verifyOtpMessage: '',
  verifyOtpData: undefined
}

export default function policy (state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_POLICY_REDUX:
      return {
        ...initState
      }
    case actions.RESET_REDUX_POLICY:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...initState
        }
      }
    case actions.POLICY_LIST:
      return {
        ...state
        // data: null
      }
    case actions.POLICY_LIST_START:
      return {
        ...state,
        getPolicyListMessage: action.getPolicyListMessage
      }
    case actions.CLAIM_LIST:
      return {
        ...state
      }
    case actions.POLICY_DETAILS_REQUEST:
      return {
        ...state
      }

    case actions.POLICY_LIST_RESULT:
      return {
        ...state,
        data: [...action.data],
        metadata: action.metadata,
        getPolicyListMessage: action.getPolicyListMessage
      }
    case actions.CLAIM_LIST_RESULT:
      return {
        ...state,
        claimData: [...action.data],
        getClaimListMessage: action.getClaimListMessage
      }
    case actions.POLICY_CLAIM_LIST_RESULT:
      return {
        ...state,
        policyClaimsData: [...action.data],
        getPolicyClaimListMessage: action.getClaimListMessage
      }
    case actions.FAILED_GET_CLAIM_LIST:
      return {
        ...state,
        getClaimListMessage: action.getClaimListMessage
      }
    case actions.FAILED_GET_POLICY_CLAIMS_LIST:
      return {
        ...state,
        getPolicyClaimListMessage: action.getPolicyClaimListMessage
      }
    case actions.AGGREGATE_POLICY_DETAILS_SUCCESS:
      return {
        ...state,
        aggregateDetailsData: action.data,
        getPolicyDetailResult: action.getPolicyDetailResult
      }
    case actions.POLICY_DETAILS_SUCCESS:
      return {
        ...state,
        detailsData: { ...action.data },
        getPolicyDetailResult: action.getPolicyDetailResult
      }
    case actions.FAILED_GET_POLICY_LIST:
      return {
        ...state,
        getPolicyListMessage: action.getPolicyListMessage
      }
    case actions.FAILED_GET_POLICY_DETAIL:
      return {
        ...state,
        getPolicyDetailResult: action.getPolicyDetailResult
      }
    case actions.TOKENIZED_ENDORSEMENT_DETAIL_SUCCESS:
    case actions.TOKENIZED_ENDORSEMENT_DETAIL_FAILURE:
      return {
        ...state,
        tokenisedEndorsementDetail: action.data
      }
    case actions.TOKENIZED_ENDORSEMENT_SUCCESS:
    case actions.TOKENIZED_ENDORSEMENT_FAILURE:
      return {
        ...state,
        tokenisedEndorsementData: action.data,
        tokenisedEndorsementDataMessage: action.getPolicyEndorsementResult
      }
    case actions.AUTHORIZED_ENDORSEMENT_SUCCESS:
    case actions.AUTHORIZED_ENDORSEMENT_FAILURE:
      return {
        ...state,
        authorizedEndorsementData: action.data,
        authorizedEndorsementDataMessage: action.getPolicyEndorsementResult
      }

    case actions.UNAUTHORIZED_ENDORSEMENT_SUCCESS:
    case actions.UNAUTHORIZED_ENDORSEMENT_FAILURE:
      return {
        ...state,
        authorizedEndorsementData: action.data,
        authorizedEndorsementDataMessage: action.getPolicyEndorsementResult
      }
    case actions.SEND_VERIFICATION_OTP_SUCCESS:
    case actions.SEND_VERIFICATION_OTP_FAILURE:
      return {
        ...state,
        sendOtpData: action.data,
        sendOtpMessage: action.message
      }
    case actions.VERIFY_VERIFICATION_OTP_SUCCESS:
    case actions.VERIFY_VERIFICATION_OTP_FAILURE:
      return {
        ...state,
        verifyOtpData: action.data,
        verifyOtpMessage: action.message
      }
    case actions.RESEND_VERIFICATION_OTP_SUCCESS:
    case actions.RESEND_VERIFICATION_OTP_FAILURE:
      return {
        ...state,
        resendOtpMessage: action.message,
        resendOtpData: action.data
      }
    case actions.FILTERED_POLICY:
      return {
        ...state,
        tag: action.payload.tag
      }
    default:
      return state
  }
}
