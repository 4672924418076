const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  GENERATE_OTP_REQUEST: 'GENERATE_OTP_REQUEST',
  GENERATE_OTP_SUCCESS: 'GENERATE_OTP_SUCCESS',
  GENERATE_OTP_FAILURE: 'GENERATE_OTP_FAILURE',
  RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
  SAVE_OPERATION_ID: 'SAVE_OPERATION_ID',
  VERIFY_OTP: 'VERIFY_OTP',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILED: 'VERIFY_OTP_FAILED',
  AUTH_VERIFY_OTP_REQUEST: 'AUTH_VERIFY_OTP_REQUEST',
  AUTH_VERIFY_OTP_SUCCESS: 'AUTH_VERIFY_OTP_SUCCESS',
  AUTH_VERIFY_OTP_FAILURE: 'AUTH_VERIFY_OTP_FAILURE',
  CLEAR_VERIFY_OTP_ERROR: 'CLEAR_VERIFY_OTP_ERROR',
  RESET_STATUS: 'RESET_STATUS',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_COUNTRY_CODE: 'UPDATE_COUNTRY_CODE',
  WHATS_APP_LOGIN: 'WHATS_APP_LOGIN',
  WHATS_APP_OTP_CHECK_REQUEST: 'WHATS_APP_OTP_CHECK_REQUEST',
  WHATS_APP_OTP_CHECK_SUCCESS: 'WHATS_APP_OTP_CHECK_SUCCESS',
  WHATS_APP_OTP_CHECK_FAILURE: 'WHATS_APP_OTP_CHECK_FAILURE',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_FAILURE: 'AUTH_FAILURE',
  RESET_REDUX_STATES: 'RESET_REDUX_STATES',
  VERIFY_SSO_TOKEN: 'VERIFY_SSO_TOKEN',
  SSO_TOKEN_FAILURE: 'SSO_TOKEN_FAILURE',
  SSO_TOKEN_VERIFIED: 'SSO_TOKEN_VERIFIED',

  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION
  }),
  login: () => ({
    type: actions.LOGIN_REQUEST
  }),
  signOut: (payload) => ({
    type: actions.LOGOUT_REQUEST,
    payload
  }),
  generateOtp: payload => ({
    type: actions.GENERATE_OTP_REQUEST,
    payload
  }),
  resendOtpRequest: payload => ({
    type: actions.RESEND_OTP_REQUEST,
    payload
  }),
  verifyOtp: payload => ({
    type: actions.VERIFY_OTP,
    payload
  }),
  authVerifyOtp: payload => ({
    type: actions.AUTH_VERIFY_OTP_REQUEST,
    payload
  }),
  verifyOtpSuccess: payload => ({
    type: actions.VERIFY_OTP_SUCCESS,
    payload
  }),
  verifyOtpFailed: payload => ({
    type: actions.VERIFY_OTP_FAILED,
    payload
  }),
  resetStatus: () => ({
    type: actions.RESET_STATUS
  }),
  clearVerifyOtpError: () => ({
    type: actions.CLEAR_VERIFY_OTP_ERROR
  }),
  updateUser: payload => ({
    type: actions.UPDATE_USER,
    payload
  }),
  updateCountryCode: payload => ({
    type: actions.UPDATE_COUNTRY_CODE,
    payload
  }),
  whatsAppLogin: payload => ({
    type: actions.WHATS_APP_LOGIN,
    payload
  }),
  checkWhatsAppOTPStatus: payload => ({
    type: actions.WHATS_APP_OTP_CHECK_REQUEST,
    payload
  }),
  resetReduxState: payload => ({
    type: actions.RESET_REDUX_STATES,
    payload
  }),
  verifySSOToken: payload => ({
    type: actions.VERIFY_SSO_TOKEN,
    payload
  })
}
export default actions
