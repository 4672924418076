const host = process.env.REACT_APP_URL

const policyList = () => {
  return `${host}api/v1/policy/policies/`
}
const claimList = () => {
  return `${host}api/v1/claim/list/`
}

const policydetail = () => {
  return `${host}api/v1/policy/policy-details/?er=1`
}

const tokenisedEndorsementDetail = () => {
  return `${host}api/v1/policy/token-policy-details/`
}

const tokenisedEndorsement = (id) => {
  return `${host}api/v1/policy/token-endorse/${id}/`
}

const authorizedEndorsement = (id) => {
  return `${host}api/v1/policy/authorized-endorse-policy/${id}/`
}

const generateOtpUrl = `${host}api/v1/user/send_otp/`
const verifyOtpUrl = `${host}api/v1/user/verify_otp/`
const resendOTPUrl = `${host}api/v1/user/resend_otp/`

const aggregatePolicyDetailApi = `${host}api/v1/policy/aggregate-policy-details/?er=1`

export default {
  policyList,
  policydetail,
  aggregatePolicyDetailApi,
  claimList,
  tokenisedEndorsement,
  tokenisedEndorsementDetail,
  authorizedEndorsement,
  generateOtpUrl,
  verifyOtpUrl,
  resendOTPUrl
}
