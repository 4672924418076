const actions = {
  AGENT_POLICY_DETAILS_REQUEST: 'AGENT_POLICY_DETAILS_REQUEST',
  VALIDATE_AGENCY_CLAIM: 'VALIDATE_AGENCY_CLAIM',
  CREATE_AGENCY_CLAIM: 'CREATE_AGENCY_CLAIM',
  AGENT_POLICY_DETAILS_SUCCESS: 'AGENT_POLICY_DETAILS_SUCCESS',
  AGENT_POLICY_DETAILS_FAILURE: 'AGENT_POLICY_DETAILS_FAILURE',
  AGENCY_BENEFIT_LISTS: 'AGENCY_BENEFIT_LISTS',
  FAILED_GET_AGENCY_BENEFITS: 'FAILED_GET_AGENCY_BENEFITS',
  GET_AGENCY_BENEFITS: 'GET_AGENCY_BENEFITS',
  AGENCY_POLICY_LIST: 'AGENCY_POLICY_LIST',
  AGENCY_POLICY_LIST_SUCCESS: 'AGENCY_POLICY_LIST_SUCCESS',
  AGENCY_POLICY_LIST_FAILURE: 'AGENCY_POLICY_LIST_FAILURE',
  AGENCY_CLAIM_LIST: 'AGENCY_CLAIM_LIST',
  AGENCY_CLAIM_LIST_REQUEST: 'AGENCY_CLAIM_LIST_REQUEST',
  AGENCY_CLAIM_LIST_SUCCESS: 'AGENCY_CLAIM_LIST_SUCCESS',
  AGENCY_CLAIM_LIST_FAILURE: 'AGENCY_CLAIM_LIST_FAILURE',
  AGENCY_POLICY_LIST_START: 'AGENCY_POLICY_LIST_START',
  AGENCY_POLICY_LIST_REQUEST: 'AGENCY_POLICY_LIST_REQUEST',

  CLEAR_BENEFIT_LIST: 'CLEAR_BENEFIT_LIST',
  RESET_AGENCY_STATE: 'RESET_AGENCY_STATE',

  agentPolicyDetails: id => ({
    type: actions.AGENT_POLICY_DETAILS_REQUEST,
    payload: id
  }),

  validateAgencyClaim: payload => ({
    type: actions.VALIDATE_AGENCY_CLAIM,
    payload
  }),
  postAgencyClaim: payload => ({
    type: actions.CREATE_AGENCY_CLAIM,
    payload
  }),

  getAgencyBenefits: payload => ({
    type: actions.GET_AGENCY_BENEFITS,
    payload
  }),
  getAgencyPolicyList: payload => ({
    type: actions.AGENCY_POLICY_LIST,
    payload
  }),
  getAgencyClaimList: payload => ({
    type: actions.AGENCY_CLAIM_LIST,
    payload
  }),
  resetReduxState: payload => ({
    type: actions.RESET_AGENCY_STATE,
    payload
  })
}
export default actions
