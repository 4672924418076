import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import request from '../../request/axios/gojekPassenger'
import middleware from '../../middleware/responseChecker'

export function * createOrderRequest () {
  yield takeEvery(actions.CREATE_ORDER_GOJEK_PASSENGER, function * (payload) {
    let res = yield call(request.createOrder, payload)
    if (res.status === 200) {
      yield put({
        type: actions.CREATE_ORDER_GOJEK_PASSENGER_STATUS,
        createOrderData: res.data,
        createOrderStatus: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.CREATE_ORDER_GOJEK_PASSENGER_STATUS,
        createOrderData: res.data,
        createOrderStatus: res.data.error
      })
    }
  })
}

export function * getBenefitsGojekPassengerRequest () {
  yield takeEvery(actions.GET_BENEFITS_GO_PASSENGER_REQUEST, function * (
    payload
  ) {
    let res = yield call(request.getBenefitsGojekPassenger, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_BENEFITS_GO_PASSENGER_SUCCESS,
        data: res.data,
        getBenefitsGojekPassengerStatus: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_BENEFITS_GO_PASSENGER_FAILURE,
        data: [],
        getBenefitsGojekPassengerStatus: res.data.error
      })
    }
  })
}

export function * createClaimGocarPassengerRequest () {
  yield takeEvery(actions.CREATE_CLAIM_GO_PASSENGER_REQUEST, function * (
    payload
  ) {
    let res = yield call(request.createClaimGocarPassenger, payload)
    if (res.status === 200) {
      yield put({
        type: actions.CREATE_CLAIM_GO_PASSENGER_SUCCESS,
        data: res.data,
        message: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.CREATE_CLAIM_GO_PASSENGER_FAILURE,
        data: {},
        message: res.data.error || 'message'
      })
    }
  })
}

export function * validateGoPassenger () {
  yield takeEvery(actions.VALIDATE_CLAIM_GO_PASSENGER, function * (
    payload
  ) {
    let res = yield call(request.validateClaimGocar, payload)
    if (res.status === 200) {
      yield put({
        type: actions.VALIDATE_CLAIM_GO_PASSENGER_STATUS,
        dataValidateGocar: res.data,
        validateClaimGoPassengerStatus: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.VALIDATE_CLAIM_GO_PASSENGER_STATUS,
        dataValidateGocar: {},
        validateClaimGoPassengerStatus: res.data.error || 'message',
        validateClaimGoPassengerStatusCode: res.data.code
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(createOrderRequest),
    fork(getBenefitsGojekPassengerRequest),
    fork(createClaimGocarPassengerRequest),
    fork(validateGoPassenger)
  ])
}
