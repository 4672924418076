const host = process.env.REACT_APP_URL

const getLanguage = messagesId => {
  return `${host}api/v1/language/${messagesId}/messages/`
}

const getAppConfigs = messagesId => {
  return `${host}api/v1/app/pre/`
}

export default {
  getLanguage,
  getAppConfigs
}
