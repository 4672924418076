const actions = {
  GET_BENEFITS: 'GET_BENEFITS',
  BENEFIT_LISTS: 'BENEFIT_LISTS',
  FAILED_GET_BENEFITS: 'FAILED_GET_BENEFITS',
  SUCCESS_CLAIM: 'SUCCESS_CLAIM',
  FAILED_CLAIM: 'FAILED_CLAIM',
  RESET_STATE: 'RESET_STATE',
  CREATE_CLAIM: 'CREATE_CLAIM',
  GET_CLAIM_DETAILS_REQUEST: 'GET_CLAIM_DETAILS_REQUEST',
  GET_CLAIM_DETAILS_SUCCESS: 'GET_CLAIM_DETAILS_SUCCESS',
  GET_CLAIM_DETAILS_FAILURE: 'GET_CLAIM_DETAILS_FAILURE',
  UPDATE_CLAIM_DETAILS: 'UPDATE_CLAIM_DETAILS',
  UPLOAD_GENERIC_CLAIM_DOC_REQUEST: 'UPLOAD_GENERIC_CLAIM_DOC_REQUEST',
  UPLOAD_GENERIC_CLAIM_DOC_SUCCESS: 'UPLOAD_GENERIC_CLAIM_DOC_SUCCESS',
  UPLOAD_GENERIC_CLAIM_DOC_FAILURE: 'UPLOAD_GENERIC_CLAIM_DOC_FAILURE',
  UPDATE_UPLOAD_GENERIC_CLAIM_DOC_LOADING_STATES: 'UPDATE_UPLOAD_GENERIC_CLAIM_DOC_LOADING_STATES',
  SAVE_GENERIC_ATTRIBUTES_DOC: 'SAVE_GENERIC_ATTRIBUTES_DOC',
  SAVE_GENERIC_ATTRIBUTES_REQUEST: 'SAVE_GENERIC_ATTRIBUTES_REQUEST',
  SAVE_GENERIC_ATTRIBUTES_SUCCESS: 'SAVE_GENERIC_ATTRIBUTES_SUCCESS',
  SAVE_GENERIC_ATTRIBUTES_FAILURE: 'SAVE_GENERIC_ATTRIBUTES_FAILURE',
  SAVE_GENERIC_ATTRIBUTES_STATUS: 'SAVE_GENERIC_ATTRIBUTES_STATUS',
  CHECK_FINAL_SUBMIT: 'CHECK_FINAL_SUBMIT',
  FINAL_SUBMIT_REQUEST: 'FINAL_SUBMIT_REQUEST',
  FINAL_SUBMIT_SUCCESS: 'FINAL_SUBMIT_SUCCESS',
  FINAL_SUBMIT_FAILURE: 'FINAL_SUBMIT_FAILURE',
  FINAL_SUBMIT_STATUS: 'FINAL_SUBMIT_STATUS',
  VERIFY_BANK_ACCOUNT: 'VERIFY_BANK_ACCOUNT',
  VERIFY_BANK_ACCOUNT_FORMS_REQUEST: 'VERIFY_BANK_ACCOUNT_FORMS_REQUEST',
  VERIFY_BANK_ACCOUNT_FORMS_SUCCESS: 'VERIFY_BANK_ACCOUNT_FORMS_SUCCESS',
  VERIFY_BANK_ACCOUNT_FORMS_FAILURE: 'VERIFY_BANK_ACCOUNT_FORMS_FAILURE',
  VERIFY_BANK_ACCOUNT_STATUS: 'VERIFY_BANK_ACCOUNT_STATUS',
  VALIDATE_CLAIM: 'VALIDATE_CLAIM',
  VALIDATE_CLAIM_STATUS: 'VALIDATE_CLAIM_STATUS',
  GET_FEEDBACK_FORMS_REQUEST: 'GET_FEEDBACK_FORMS_REQUEST',
  GET_FEEDBACK_FORMS_SUCCESS: 'GET_FEEDBACK_FORMS_SUCCESS',
  GET_FEEDBACK_FORMS_FAILURE: 'GET_FEEDBACK_FORMS_FAILURE',
  POST_FEEDBACK_FORMS: 'POST_FEEDBACK_FORMS',
  POST_FEEDBACK_FORMS_STATUS: 'POST_FEEDBACK_FORMS_STATUS',
  POST_SALVAGE_FORMS_STATUS: 'POST_SALVAGE_FORMS_STATUS',
  POST_SALVAGE_FORMS_REQUEST: 'POST_SALVAGE_FORMS_REQUEST',
  POST_SALVAGE_FORMS_SUCCESS: 'POST_SALVAGE_FORMS_SUCCESS',
  POST_SALVAGE_FORMS_FAILURE: 'POST_SALVAGE_FORMS_FAILURE',
  CREATE_SHIPPING_CLAIM: 'CREATE_SHIPPING_CLAIM',
  CREATE_SHIPPING_CLAIM_RESULT: 'CREATE_SHIPPING_CLAIM_RESULT',
  // Comment Actions
  GET_COMMENTS_REQUEST: 'GET_COMMENTS',
  GET_TICKETS_REQUEST: 'GET_TICKETS_REQUEST',
  GET_TICKETS_SUCCESS: 'GET_TICKETS_SUCCESS',
  GET_TICKETS_FAILURE: 'GET_TICKETS_FAILURE',
  GET_COMMENTS_SUCCESS: 'GET_COMMENTS_SUCCESS',
  GET_COMMENTS_FAILURE: 'GET_COMMENTS_FAILURE',
  ADD_COMMENT_REQUEST: 'ADD_COMMENT_REQUEST',
  ADD_COMMENT_SUCCESS: 'ADD_COMMENT_SUCCESS',
  ADD_COMMENT_FAILURE: 'ADD_COMMENT_FAILURE',
  UPLOAD_COMMENT_DOC_REQUEST: 'UPLOAD_COMMENT_DOC_REQUEST',
  UPLOAD_COMMENT_DOC_SUCCESS: 'UPLOAD_COMMENT_DOC_SUCCESS',
  UPLOAD_COMMENT_DOC_FAILURE: 'UPLOAD_COMMENT_DOC_FAILURE',
  UPLOAD_COMMON_CLAIM_FILE_REQUEST: 'UPLOAD_COMMON_CLAIM_FILE_REQUEST',
  UPLOAD_COMMON_CLAIM_FILE_SUCCESS: 'UPLOAD_COMMON_CLAIM_FILE_SUCCESS',
  UPLOAD_COMMON_CLAIM_FILE_FAILURE: 'UPLOAD_COMMON_CLAIM_FILE_FAILURE',
  UPLOAD_VERIFICATION_CLAIM_FILE_REQUEST: 'UPLOAD_VERIFICATION_CLAIM_FILE_REQUEST',
  UPLOAD_VERIFICATION_CLAIM_FILE_SUCCESS: 'UPLOAD_VERIFICATION_CLAIM_FILE_SUCCESS',
  UPLOAD_VERIFICATION_CLAIM_FILE_FAILURE: 'UPLOAD_VERIFICATION_CLAIM_FILE_FAILURE',
  CREATE_VERIFY_IMAGE_REQUEST: 'CREATE_VERIFY_IMAGE_REQUEST',
  CREATE_VERIFY_IMAGE_SUCCESS: 'CREATE_VERIFY_IMAGE_SUCCESS',
  CREATE_VERIFY_IMAGE_FAILURE: 'CREATE_VERIFY_IMAGE_FAILURE',
  VERIFY_IMAGE_REQUEST: 'VERIFY_IMAGE_REQUEST',
  VERIFY_IMAGE_SUCCESS: 'VERIFY_IMAGE_SUCCESS',
  VERIFY_IMAGE_FAILURE: 'VERIFY_IMAGE_FAILURE',
  POLLING_VERIFY_IMAGE_REQUEST: 'POLLING_VERIFY_IMAGE_REQUEST',
  POLLING_VERIFY_IMAGE_SUCCESS: 'POLLING_VERIFY_IMAGE_SUCCESS',
  POLLING_VERIFY_IMAGE_FAILURE: 'POLLING_VERIFY_IMAGE_FAILURE',
  VERIFY_IMAGE_CONTENT_REQUEST: 'VERIFY_IMAGE_CONTENT_REQUEST',
  VERIFY_IMAGE_CONTENT_SUCCESS: 'VERIFY_IMAGE_CONTENT_SUCCESS',
  VERIFY_IMAGE_CONTENT_FAILURE: 'VERIFY_IMAGE_CONTENT_FAILURE',
  UPDATE_CLAIM_FORM_WITH_GENERIC_DOC: 'UPDATE_CLAIM_FORM_WITH_GENERIC_DOC',
  SET_VERIFYING_DOCUMENT_URL: 'SET_VERIFYING_DOCUMENT_URL',
  UPDATE_FORM_ATTRIBUTE_REQUEST: 'UPDATE_FORM_ATTRIBUTE_REQUEST',
  UPDATE_FORM_ATTRIBUTE_SUCCESS: 'UPDATE_FORM_ATTRIBUTE_SUCCESS',
  UPDATE_FORM_ATTRIBUTE_FAILURE: 'UPDATE_FORM_ATTRIBUTE_FAILURE',
  GET_BENEFITS_LIST_REQUEST: 'GET_BENEFITS_LIST_REQUEST',
  GET_BENEFITS_LIST_SUCCESS: 'GET_BENEFITS_LIST_SUCCESS',
  GET_BENEFITS_LIST_FAILURE: 'GET_BENEFITS_LIST_FAILURE',
  CLEAR_UPLOAD_ERROR: 'CLEAR_UPLOAD_ERROR',

  SAVE_PAYMENT_FORM_REQUEST: 'SAVE_PAYMENT_FORM_REQUEST',
  SAVE_PAYMENT_FORM_SUCCESS: 'SAVE_PAYMENT_FORM_SUCCESS',
  SAVE_PAYMENT_FORM_FAILURE: 'SAVE_PAYMENT_FORM_FAILURE',

  GENERATE_SIGNED_URL_AND_UPLOAD_REQUEST: 'GENERATE_SIGNED_URL_AND_UPLOAD_REQUEST',
  GENERATE_SIGNED_URL_AND_UPLOAD_SUCCESS: 'GENERATE_SIGNED_URL_AND_UPLOAD_SUCCESS',
  GENERATE_SIGNED_URL_AND_UPLOAD_FAILURE: 'GENERATE_SIGNED_URL_AND_UPLOAD_FAILURE',

  UPLOAD_FILE_BY_SIGNED_URL_REQUEST: 'UPLOAD_FILE_BY_SIGNED_URL_REQUEST',
  UPLOAD_FILE_BY_SIGNED_URL_SUCCESS: 'UPLOAD_FILE_BY_SIGNED_URL_SUCCESS',
  UPLOAD_FILE_BY_SIGNED_URL_FAILURE: 'UPLOAD_FILE_BY_SIGNED_URL_FAILURE',

  GET_CLAIM_BANK_LIST_REQUEST: 'GET_CLAIM_BANK_LIST_REQUEST',
  GET_CLAIM_BANK_LIST_SUCCESS: 'GET_CLAIM_BANK_LIST_SUCCESS',
  GET_CLAIM_BANK_LIST_FAILURE: 'GET_CLAIM_BANK_LIST_FAILURE',

  GET_CHOICES_EXTERNAL_DATA_REQUEST: 'GET_CHOICES_EXTERNAL_DATA_REQUEST',
  GET_CHOICES_EXTERNAL_DATA_SUCCESS: 'GET_CHOICES_EXTERNAL_DATA_SUCCESS',
  GET_CHOICES_EXTERNAL_DATA_FAILURE: 'GET_CHOICES_EXTERNAL_DATA_FAILURE',

  UPDATE_UPLOAD_GENERIC_CLAIM_DOC_ERROR_STATES: 'UPDATE_UPLOAD_GENERIC_CLAIM_DOC_ERROR_STATES',

  GET_SERVICE_CENTER_LIST_REQUEST: 'GET_SERVICE_CENTER_LIST_REQUEST',
  GET_SERVICE_CENTER_LIST_SUCCESS: 'GET_SERVICE_CENTER_LIST_SUCCESS',
  GET_SERVICE_CENTER_LIST_FAILURE: 'GET_SERVICE_CENTER_LIST_FAILURE',

  GET_SERVICE_CENTER_BRANCH_LIST_REQUEST: 'GET_SERVICE_CENTER_BRANCH_LIST_REQUEST',
  GET_SERVICE_CENTER_BRANCH_LIST_SUCCESS: 'GET_SERVICE_CENTER_BRANCH_LIST_SUCCESS',
  GET_SERVICE_CENTER_BRANCH_LIST_FAILURE: 'GET_SERVICE_CENTER_BRANCH_LIST_FAILURE',

  GET_PROVINCE_LIST_REQUEST: 'GET_PROVINCE_LIST_REQUEST',
  GET_PROVINCE_LIST_SUCCESS: 'GET_PROVINCE_LIST_SUCCESS',
  GET_PROVINCE_LIST_FAILURE: 'GET_PROVINCE_LIST_FAILURE',

  GET_CITY_LIST_ON_PROVINCE_REQUEST: 'GET_CITY_LIST_ON_PROVINCE_REQUEST',
  GET_CITY_LIST_ON_PROVINCE_SUCCESS: 'GET_CITY_LIST_ON_PROVINCE_SUCCESS',
  GET_CITY_LIST_ON_PROVINCE_FAILURE: 'GET_CITY_LIST_ON_PROVINCE_FAILURE',

  GET_POSTAL_CODE_LIST_REQUEST: 'GET_POSTAL_CODE_LIST_REQUEST',
  GET_POSTAL_CODE_LIST_SUCCESS: 'GET_POSTAL_CODE_LIST_SUCCESS',
  GET_POSTAL_CODE_LIST_FAILURE: 'GET_POSTAL_CODE_LIST_FAILURE',

  GET_SERVICE_CENTER_BRAND_LIST_REQUEST: 'GET_SERVICE_CENTER_BRAND_LIST_REQUEST',
  GET_SERVICE_CENTER_BRAND_LIST_SUCCESS: 'GET_SERVICE_CENTER_BRAND_LIST_SUCCESS',
  GET_SERVICE_CENTER_BRAND_LIST_FAILURE: 'GET_SERVICE_CENTER_BRAND_LIST_FAILURE',

  GET_TOTAL_SERVICE_CENTER_LIST_REQUEST: 'GET_TOTAL_SERVICE_CENTER_LIST_REQUEST',
  GET_TOTAL_SERVICE_CENTER_LIST_FAILURE: 'GET_TOTAL_SERVICE_CENTER_LIST_FAILURE',
  GET_TOTAL_SERVICE_CENTER_LIST_SUCCESS: 'GET_TOTAL_SERVICE_CENTER_LIST_SUCCESS',

  // Add_ons Questions List
  GET_QUESTIONS_LIST_REQUEST: 'GET_QUESTIONS_LIST_REQUEST',
  GET_QUESTIONS_LIST_SUCCESS: 'GET_QUESTIONS_LIST_SUCCESS',
  GET_QUESTIONS_LIST_FAILURE: 'GET_QUESTIONS_LIST_FAILURE',

  CLEAR_AGENCY_BENEFIT_LIST: 'CLEAR_AGENCY_BENEFIT_LIST',

  VALIDATE_CLAIM_STATUS_REQUEST: 'VALIDATE_CLAIM_STATUS_REQUEST',
  VALIDATE_CLAIM_STATUS_SUCCESS: 'VALIDATE_CLAIM_STATUS_SUCCESS',
  VALIDATE_CLAIM_STATUS_FAILURE: 'VALIDATE_CLAIM_STATUS_FAILURE',

  GET_FAMILY_ELIGIBLIE_USER_LIST_REQUEST: 'GET_FAMILY_ELIGIBLIE_USER_LIST_REQUEST',
  GET_FAMILY_ELIGIBLIE_USER_LIST_SUCCESS: 'GET_FAMILY_ELIGIBLIE_USER_LIST_SUCCESS',
  GET_FAMILY_ELIGIBLIE_USER_LIST_FAILURE: 'GET_FAMILY_ELIGIBLIE_USER_LIST_FAILURE',

  POST_FAMILY_CREATE_CLAIM_REQUEST: 'POST_FAMILY_CREATE_CLAIM_REQUEST',
  POST_FAMILY_CREATE_CLAIM_SUCCESS: 'POST_FAMILY_CREATE_CLAIM_SUCCESS',
  POST_FAMILY_CREATE_CLAIM_FAILURE: 'POST_FAMILY_CREATE_CLAIM_FAILURE',

  GET_ASSOCIATED_USER_ON_CLAIM_REQUEST: 'GET_ASSOCIATED_USER_ON_CLAIM_REQUEST',
  GET_ASSOCIATED_USER_ON_CLAIM_SUCCESS: 'GET_ASSOCIATED_USER_ON_CLAIM_SUCCESS',
  GET_ASSOCIATED_USER_ON_CLAIM_FAILURE: 'GET_ASSOCIATED_USER_ON_CLAIM_FAILURE',

  getBenefits: payload => ({
    type: actions.GET_BENEFITS,
    payload
  }),
  getBenefitsList: payload => ({
    type: actions.GET_BENEFITS_LIST_REQUEST,
    payload
  }),
  postClaim: payload => ({
    type: actions.CREATE_CLAIM,
    payload
  }),
  successClaim: payload => ({
    type: actions.SUCCESS_CLAIM,
    payload
  }),
  failedClaim: payload => ({
    type: actions.FAILED_CLAIM,
    payload
  }),
  resetReduxState: payload => ({
    type: actions.RESET_STATE,
    payload
  }),
  getClaimDetails: payload => ({
    type: actions.GET_CLAIM_DETAILS_REQUEST,
    payload
  }),
  successGetClaimDetails: payload => ({
    type: actions.GET_CLAIM_DETAILS_SUCCESS,
    payload
  }),
  failedGetClaimDetails: payload => ({
    type: actions.GET_CLAIM_DETAILS_FAILURE,
    payload
  }),
  uploadGenericClaimDoc: payload => ({
    type: actions.UPLOAD_GENERIC_CLAIM_DOC_REQUEST,
    payload
  }),
  saveGenericAttributes: payload => ({
    type: actions.SAVE_GENERIC_ATTRIBUTES_REQUEST,
    payload
  }),
  verifyBankAccountForms: payload => ({
    type: actions.VERIFY_BANK_ACCOUNT_FORMS_REQUEST,
    payload
  }),
  validateClaim: payload => ({
    type: actions.VALIDATE_CLAIM,
    payload
  }),
  getFeedbackForms: payload => ({
    type: actions.GET_FEEDBACK_FORMS_REQUEST,
    payload
  }),
  postFeedbackForms: payload => ({
    type: actions.POST_FEEDBACK_FORMS,
    payload
  }),
  postSalvageForms: payload => ({
    type: actions.POST_SALVAGE_FORMS_REQUEST,
    payload
  }),
  createShippingClaim: payload => ({
    type: actions.CREATE_SHIPPING_CLAIM,
    payload
  }),
  createShippingClaimResult: payload => ({
    type: actions.CREATE_SHIPPING_CLAIM_RESULT,
    payload
  }),
  // Comment Actions
  getComments: payload => ({
    type: actions.GET_COMMENTS_REQUEST,
    payload
  }),

  getTickets: payload => ({
    type: actions.GET_TICKETS_REQUEST,
    payload
  }),
  getCommentsSuccess: payload => ({
    type: actions.GET_COMMENTS_SUCCESS,
    payload
  }),
  getCommentsFail: payload => ({
    type: actions.GET_COMMENTS_FAILURE,
    payload
  }),
  addComment: payload => ({
    type: actions.ADD_COMMENT_REQUEST,
    payload
  }),
  uploadCommentDoc: payload => ({
    type: actions.UPLOAD_COMMENT_DOC_REQUEST,
    payload
  }),
  uploadCommonClaimFile: payload => ({
    type: actions.UPLOAD_COMMON_CLAIM_FILE_REQUEST,
    payload
  }),
  uploadVerificationClaimFile: payload => ({
    type: actions.UPLOAD_VERIFICATION_CLAIM_FILE_REQUEST,
    payload
  }),
  verifyImageRequest: payload => ({
    type: actions.VERIFY_IMAGE_REQUEST,
    payload
  }),
  createVerifyImageRequest: payload => ({
    type: actions.CREATE_VERIFY_IMAGE_REQUEST,
    payload
  }),
  pollingVerifyImageRequest: payload => ({
    type: actions.POLLING_VERIFY_IMAGE_REQUEST,
    payload
  }),
  verifyImageContentRequest: payload => ({
    type: actions.VERIFY_IMAGE_CONTENT_REQUEST,
    payload
  }),
  updateClaimFormWithGenericDoc: payload => ({
    ...payload,
    type: actions.UPDATE_CLAIM_FORM_WITH_GENERIC_DOC
  }),
  setVerifyingDocumentUrl: url => ({
    type: actions.SET_VERIFYING_DOCUMENT_URL,
    url
  }),
  updateFormAttribute: payload => ({
    type: actions.UPDATE_FORM_ATTRIBUTE_REQUEST,
    payload
  }),
  clearUploadError: () => ({
    type: actions.CLEAR_UPLOAD_ERROR
  }),
  savePaymentForm: payload => ({
    type: actions.SAVE_PAYMENT_FORM_REQUEST,
    payload
  }),
  generateSignedUrlAndUplaod: payload => ({
    type: actions.GENERATE_SIGNED_URL_AND_UPLOAD_REQUEST,
    payload
  }),
  uploadFileBySignedUrl: payload => ({
    type: actions.UPLOAD_FILE_BY_SIGNED_URL_REQUEST,
    payload
  }),
  getClaimBankList: payload => ({
    type: actions.GET_CLAIM_BANK_LIST_REQUEST,
    payload
  }),
  getChoicesExternalData: payload => ({
    type: actions.GET_CHOICES_EXTERNAL_DATA_REQUEST,
    payload
  }),
  getServiceCenterList: payload => ({
    type: actions.GET_SERVICE_CENTER_LIST_REQUEST,
    payload
  }),
  getProvinceList: payload => ({
    type: actions.GET_PROVINCE_LIST_REQUEST,
    payload
  }),
  getCityListInProvince: payload => ({
    type: actions.GET_CITY_LIST_ON_PROVINCE_REQUEST,
    payload
  }),

  getServiceCenterBranchList: payload => ({
    type: actions.GET_SERVICE_CENTER_BRANCH_LIST_REQUEST,
    payload
  }),

  getPostalCodeList: payload => ({
    type: actions.GET_POSTAL_CODE_LIST_REQUEST,
    payload
  }),
  getTotalServiceCenter: (payload) => ({
    type: actions.GET_TOTAL_SERVICE_CENTER_LIST_REQUEST,
    payload
  }),
  getQuetionnaireList: (payload) => ({
    type: actions.GET_QUESTIONS_LIST_REQUEST,
    payload
  }),
  getServiceCenterBrandList: (payload) => ({
    type: actions.GET_SERVICE_CENTER_BRAND_LIST_REQUEST,
    payload
  }),
  getFamilyEligibleUserList: (payload) => ({
    type: actions.GET_FAMILY_ELIGIBLIE_USER_LIST_REQUEST,
    payload
  }),
  postFamilyCreateClaim: (payload) => ({
    type: actions.POST_FAMILY_CREATE_CLAIM_REQUEST,
    payload
  }),
  getAssocaitedUserOnClaim: (payload) => ({
    type: actions.GET_ASSOCIATED_USER_ON_CLAIM_REQUEST,
    payload
  })
}
export default actions
