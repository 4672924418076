import notAuthGetRequest from '../noAuthGet'
import postRequest from '../post'
import url from '../../endpoint/tokopediaendorse'
import queryBuilder from '../../../helpers/queryBuilder'
// import axios from 'axios'

const getTokopediaPolicyDetails = async ({ query }) => {
  try {
    const queryString = queryBuilder(query)
    const base = `${url.getTokopediaPolicyDetails()}${queryString}`
    return await notAuthGetRequest(base)
  } catch (error) {
    return error.response
  }
}

const getFirstMediaPolicyDetails = async ({ query }) => {
  try {
    const queryString = queryBuilder(query)
    const base = `${url.getFirstMediaPolicyDetails()}${queryString}`
    return await notAuthGetRequest(base)
  } catch (error) {
    return error.response
  }
}

const postTokopediaPolicyEndorse = async ({ body = {}, params = {} }) => {
  try {
    const base = url.postTokopediaPolicyEndorse(params)
    return await postRequest(base, body)
  } catch (error) {
    return error.response
  }
}

export default {
  getTokopediaPolicyDetails,
  postTokopediaPolicyEndorse,
  getFirstMediaPolicyDetails
}
