import { Component } from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { Debounce } from 'react-throttle'
import WindowResizeListener from 'react-window-size-listener'
import authAction from '../../redux/auth/actions'
import appActions from '../../redux/app/actions'
import AppRouter from './AppRouter'
import AppHolder from './commonStyle'
import './global.css'

const { Content } = Layout
const { logout } = authAction
const { toggleAll } = appActions
export class App extends Component {
  render () {
    const { url } = this.props.match
    const { height } = this.props
    const appHeight = window.innerHeight
    return (
      <AppHolder>
        <Layout style={{ height: appHeight }}>
          <Debounce time='50' handler='onResize'>
            <WindowResizeListener
              onResize={windowSize =>
                this.props.toggleAll(
                  windowSize.windowWidth,
                  windowSize.windowHeight
                )
              }
            />
          </Debounce>
          <Layout>
            <Layout
              className='isoContentMainLayout'
              style={{
                height: height
              }}
            >
              <Content
                className='isomorphicContent'
                style={{
                  flexShrink: '0',
                  background: '#f1f3f6',
                  position: 'relative'
                }}
              >
                <AppRouter url={url} />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </AppHolder>
    )
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    height: state.App.height
  }),
  { logout, toggleAll }
)(App)
