import { get } from 'lodash'
import getRequest from '../get'
import postRequest from '../post'
import url from '../../endpoint/policy'
import queryBuilder from '../../../helpers/queryBuilder'

const getPolicyList = async ({ query }) => {
  const queryString = queryBuilder(query)
  try {
    // let base = `${host}api/v1/policy/policies/?offset=0&limit=50&policyState=COMPLETED,PENDING_DOCUMENT`
    const base = `${url.policyList()}${queryString}`
    return await getRequest(base)
  } catch (error) {
    return error
  }
}

const getClaimList = async ({ query }) => {
  const queryString = queryBuilder(query)
  try {
    const base = `${url.claimList()}${queryString}`
    return await getRequest(base)
  } catch (error) {
    return error
  }
}

const getPolicyDetails = async ({ payload }) => {
  try {
    const base = url.policydetail() + '&id=' + payload
    return await getRequest(base)
  } catch (error) {
    return error
  }
}

const getAggregatePolicyDetails = async ({ payload }) => {
  try {
    const base = `${url.aggregatePolicyDetailApi}`
    return await getRequest(base, payload)
  } catch (error) {
    return error
  }
}

const tokenisedEndorsementDetail = async ({ query }) => {
  const queryString = queryBuilder(query)

  try {
    const base = `${url.tokenisedEndorsementDetail()}${queryString}`
    return await getRequest(base)
  } catch (error) {
    return error
  }
}
const tokenisedEndorsement = async (payload) => {
  const internalId = get(payload, 'query.internal_id')
  const payloadData = get(payload, 'body')
  const queryString = queryBuilder(get(payload, 'query'))

  try {
    const base = `${url.tokenisedEndorsement(internalId)}${queryString}`
    return await postRequest(base, payloadData)
  } catch (error) {
    return error.response
  }
}

const authorizedEndorsement = async (payload) => {
  const internalId = get(payload, 'query.internal_id')
  const payloadData = get(payload, 'body')
  const queryString = queryBuilder(get(payload, 'query'))

  try {
    const base = `${url.authorizedEndorsement(internalId)}${queryString}`
    return await postRequest(base, payloadData)
  } catch (error) {
    return error.response
  }
}

const unAuthorizedEndorsement = async (payload) => {
  const internalId = get(payload, 'query.internal_id')
  const payloadData = get(payload, 'body')
  const queryString = queryBuilder(get(payload, 'query'))

  try {
    const base = `${url.authorizedEndorsement(internalId)}${queryString}`
    return await postRequest(base, payloadData, true)
  } catch (error) {
    return error.response
  }
}

const generateOtp = async (payload) => {
  const payloadData = get(payload, 'body')

  try {
    const base = `${url.generateOtpUrl}`
    return await postRequest(base, payloadData)
  } catch (error) {
    return error.response
  }
}

const verifyOtp = async (payload) => {
  const payloadData = get(payload, 'body')

  try {
    const base = `${url.verifyOtpUrl}`
    return await postRequest(base, payloadData)
  } catch (error) {
    return error.response
  }
}

const resendOtp = async (payload) => {
  const payloadData = get(payload, 'body')

  try {
    const base = `${url.resendOTPUrl}`
    return await postRequest(base, payloadData)
  } catch (error) {
    return error.response
  }
}

export default {
  getPolicyList,
  getPolicyDetails,
  getAggregatePolicyDetails,
  getClaimList,
  tokenisedEndorsement,
  tokenisedEndorsementDetail,
  authorizedEndorsement,
  unAuthorizedEndorsement,
  generateOtp,
  verifyOtp,
  resendOtp
}
