import { all } from 'redux-saga/effects'
import appSagas from './app/saga'
import authSagas from './auth/saga'
import errorSagas from './error/saga'
import loadingSagas from './loading/saga'
import policySagas from './policy/saga'
import claimSagas from './claim/saga'
import gojekPassengerSagas from './gojekPassenger/saga'
import golifePassengerSagas from './golifePassenger/saga'
import userSagas from './user/saga'
import masterDataSagas from './masterData/saga'
import tokopediaEndorseSagas from './tokopediaEndorse/saga'
import tokopediaCrackScreenSagas from './tokopediaCrackScreenEndorse/saga'
import endorsement from './Endorsement/sagas'
import lazadaCrackScreen from './lazadaCrackScreenEndorse/saga'
import agencySagas from './agency/saga'
export default function * rootSaga (getState) {
  yield all([
    appSagas(),
    authSagas(),
    errorSagas(),
    loadingSagas(),
    policySagas(),
    claimSagas(),
    gojekPassengerSagas(),
    golifePassengerSagas(),
    userSagas(),
    masterDataSagas(),
    tokopediaEndorseSagas(),
    tokopediaCrackScreenSagas(),
    endorsement(),
    lazadaCrackScreen(),
    agencySagas()
  ])
}
