import ReactDOM from 'react-dom'
import PoliciesApp from './app'
import 'antd/dist/antd.css'
import './styles/index'
import TagManager from 'react-gtm-module'
import { getInitData } from './helpers/urlSync'
import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import * as serviceWorker from './serviceWorker'

if (process.env.REACT_APP_DSN) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_DSN}`,
    // autoSessionTracking: true,
    // integrations: [
    //   new Integrations.BrowserTracing()
    // ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1
  })
}

const initData = getInitData()

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GA}`,
  dataLayer: {}
}

if (initData.utm_source) {
  window.sessionStorage.setItem('utm_source', initData.utm_source)
}

if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(<PoliciesApp />, document.getElementById('root'))
serviceWorker.register()
