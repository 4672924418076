import postRequest from '../post'
import {
  generateOtpUrl,
  verifyOtpUrl,
  updateUserUrl,
  checkWhatsAppOTPStatusUrl,
  resendOTPUrl,
  logoutUrl,
  checkAuthUrl,
  ssoUrl
} from '../../endpoint/auth'
import { getHeader } from '../../../helpers/utility'
import axios from 'axios'
import getRequest from '../get'
import deleteRequest from '../authDelete'

const sendOtp = async ({ payload, languageCode }) => {
  // this function used to generate new OTP for Login user
  // payload is string of phone number with code like "62-99999999999"
  try {
    return await postRequest(generateOtpUrl, {
      captcha_response: payload.captchaResponse,
      phone: payload.phone,
      partner: 'pasarpolis',
      medium: payload.medium,
      language_code: languageCode
    }) // needs to change to actual partner
  } catch (error) {
    return error.message
  }
}

const resendOTP = async ({ mobileNo, operationId, medium, languageCode }) => {
  // this function used to resend the OTP to the user
  // this call send the same otp to user, as generated by the send OTP
  // api call
  try {
    const reqBody = {
      mobile_no: mobileNo,
      operation_id: operationId,
      language_code: languageCode
    }

    if (medium) {
      reqBody.medium = medium
    }
    return await postRequest(resendOTPUrl, reqBody)
  } catch (error) {
    return error.message
  }
}

const verifyOtp = async ({ body }) => {
  try {
    return await postRequest(verifyOtpUrl, body)
  } catch (error) {
    return error.message
  }
}

const updateUser = async ({ payload }) => {
  try {
    return await axios.post(updateUserUrl, payload, { headers: getHeader() })
  } catch (error) {
    return error.message
  }
}

const checkWhatsAppOTPStatus = async (data) => {
  try {
    return await getRequest(checkWhatsAppOTPStatusUrl, data)
  } catch (error) {
    return error.message
  }
}

const logoutUser = async () => {
  try {
    return await deleteRequest(logoutUrl)
  } catch (error) {
    return error.message
  }
}
const checkAuth = async () => {
  try {
    return await getRequest(checkAuthUrl)
  } catch (error) {
    return error.message
  }
}

const verifySSOToken = async (payload) => {
  try {
    return await postRequest(ssoUrl, {
      access_token: payload.access_token
      // partner: 'mitra'
    })
  } catch (error) {
    return error.message
  }
}

const exportFunctions = {
  sendOtp,
  verifyOtp,
  resendOTP,
  updateUser,
  checkWhatsAppOTPStatus,
  logoutUser,
  checkAuth,
  verifySSOToken
}

export default exportFunctions
