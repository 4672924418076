
const USER_LOCALE_MAP = {
  'en-US': 'en',
  'en_US': 'en',
  'id_ID': 'id',
  'id-ID': 'id'
}

const COUNTRY_LANG_MAP = {
  'VN': 'vi',
  'TH': 'th',
  'ID': 'id',
  'IN': 'en'
}

export const language = (productLang = '') => {
  let userLocale = ''
  let userLanguageCode = ''
  const userAgentLang = /Lang\/(\w{2}-\w{2})/g
  const match = userAgentLang.exec(navigator.userAgent)
  if (match) {
    userLocale = match[1]
    userLanguageCode = USER_LOCALE_MAP[userLocale]
    // if (language.locale !== userLanguageCode && userLanguageCode) {
    //   if (userLanguageCode) {
    //     userLanguageCode
    //   }
    // }
  }
  const navigatorLanguage = ((navigator || {}).language || '').split('-')[0]
  // if url contains language_code then change
  const queryString = window.location.search
  const languageCode = new URLSearchParams(queryString).get('language_code')
  // we give preference to id lang before navigator language
  const countryBasedLang = COUNTRY_LANG_MAP[localStorage.getItem('country_code')] || localStorage.getItem('lang')
  // get Country code from Coockies set by NGIX
  const countryCodeNginx = COUNTRY_LANG_MAP[document.cookie.match('(^|;)\\s*' + 'country_code' + '\\s*=\\s*([^;]+)')?.pop()] || ''

  // already discussed with PM
  // Priority 1. Manual passed in param, 2. Local storage, 3. Throught nginx based on country IP, 4 & 5. based on system or broweser langaugae
  return productLang || countryBasedLang || countryCodeNginx || languageCode || userLanguageCode || navigatorLanguage || 'id'
}
