import styled from 'styled-components'

export const FooterContainer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #4A4A4A;
  padding: 20px;
  color: var(--Monochrome-White, #FFF);

  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.2px;
  display: flex;
  width: 100%;
  padding: 64px 100px;
  justify-content: center;
  align-items: center;
  gap: 100px;
`

export const Section = styled.div`
  flex: 1;
  margin: 10px;

  @media (max-width: 768px) {
    flex: 100%;
    text-align: center;
  }
`

export const Title = styled.h4`
  margin-bottom: 20px;
  color: var(--Monochrome-White, #FFF);
  font-family: Mulish;
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
  text-align: left;

  @media only screen and (max-width: 720px){
    text-align: center;
  }
`

export const List = styled.div`
  list-style: none;
  padding: 0;
`

export const ListItem = styled.div`
  margin-bottom: 5px;
  color: ${props => props.type === 'link' ? '#FFC800' : 'var(--Monochrome-White, #FFF)'};
`

export const Address = styled.address`
  margin-top: 10px;
  max-width: 190px;
  @media only screen and (max-width: 720px){
    max-width: 100%;
  }
`

export const AddressTitle = styled.p`
  color: var(--Monochrome-White, #FFF);

  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 166.667% */
`

export const AddressContent = styled.p`
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #FFF;
`

export const Left = styled.div`
    flex: 1;
  min-width: 250px;
  margin: 10px;

  @media (max-width: 768px) {
    flex: 100%;
    text-align: center;
  }
`

export const Right = styled.div`
  flex: 1;
  margin: 10px;
  display: flex;
  gap: 32px;

  @media (max-width: 768px) {
    flex: 100%;
    text-align: center;
  }
`

export const FooterLogo = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const FooterAboutContent = styled.div``

export const ContactContainer = styled.div``

export const ContactList = styled.div`
  color: var(--Monochrome-White, #FFF);
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`
