import styled from 'styled-components'
import { COLORS } from '../../constants/colors'

export const Wrapper = styled.div`
  background: transparent;
.language-wrapper{
  display: flex;
  padding: 0px 24px;
  gap: 8px;
  position: relative;
  min-height: 100%;
  align-items: center;
  text-transform: uppercase;
  color: var(--Monochrome-Black, #333);

  font-size: 14px;
  font-weight: 700;
  line-height: 18px; 

  .lang-list{
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: ${props => props.openDropDown ? props.background : COLORS.transparent};
    border-radius: 12px;
    padding: 0px 20px;
    margin-top: ${props => props.openDropDown ? '96px' : '0px'};
    padding: 16px;
    align-items: center;
    gap: 4px;
    z-index: ${props => props.openDropDown ? 3 : 0};
  }

  .arrow{
    display: flex;
    justify-content: center;
  }
  .transform-180{
    transform: rotate(180deg);
    transition: transform 0.5s;
  }

  .transform-360{
    transform: rotate(360deg);
    transition: transform 0.5s;
  }
}
`
