import styled from 'styled-components'

const AppHolder = styled.div`
  .ant-btn-primary {
    font-size: 15px;
    border-color: ${props => props.theme.secondaryColor};
    background-color: ${props => props.theme.secondaryColor};
    border-radius: 2px;
    font-weight: bold;
    width: 100%;
    color: #ffffff;
  }
  .ant-btn-default {
    font-size: 15px;
    color: ${props => props.theme.secondaryColor};
    border-color: ${props => props.theme.secondaryColor};
    border-radius: 2px;
    font-weight: bold;
  }
  .ant-btn-default:hover {
    border-radius: 2px;
    font-weight: bold;
    color: ${props => props.theme.secondaryColor};
    border-color: ${props => props.theme.secondaryColor};
  }
  .ant-btn-primary:hover {
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    color: #ffffff;
    border: 1px solid ${props => props.theme.secondaryColor};
    background-color: ${props => props.theme.secondaryColor};
  }
  .ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
  }
  .anticon-loading {
    color: ${props => props.theme.secondaryColor};
  }
`

export default AppHolder
