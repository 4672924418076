import axios from 'axios'
import { getHeader } from '../../helpers/utility'

const deleteRequest = async (url, payload) => {
  if (payload === undefined) {
    return await axios.delete(url, { headers: getHeader() })
  } else {
    return await axios.delete(url, { headers: getHeader(), data: payload })
  }
}

export default deleteRequest
