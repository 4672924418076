import axios from 'axios'
import { noCookiesHeader, preLoginHeader } from '../../helpers/utility'

/**
 * this function used for the api call to post data onto server
 * @param url: String;
 * @param payload: Object;
 * @param noCookies: Boolean;
 */
const postRequest = async (url, payload, noCookies) => {
  const response = await axios.post(url, payload, {
    headers: noCookies ? noCookiesHeader() : preLoginHeader()
  })
  return response
}

export default postRequest
