import { Component } from 'react'
import { Row, Col } from 'antd'
import FooterWrapper from './footer.style'
import telephoneIcon from '../../image/telephoneIcon.png'
import whatsappIcon from '../../image/whatsappIcon.png'
import emailIcon from '../../image/emailIcon.png'
import IntlMessages from '../utility/intlMessages'
class AppFooter extends Component {
  render () {
    return (<FooterWrapper data-testid='footer'>
      <Row type='flex' justify='center'>
        <Col xs={20}>
          <p className='footer-head-text'><IntlMessages id='Hubungi Kami' /></p>
        </Col>
      </Row>
      <Row type='flex' justify='center'>
        <Col xs={20}>
          <Row>
            <Col xs={24} xl={9} lg={7} md={24} className='marginB15'>
              <p className='footer-question'><IntlMessages id='Punya pertanyaan? Kami siap melayani Anda' /></p>
            </Col>
            <Col xs={24} xl={15} lg={17} md={24} sm={24}>
              <Row>
                <Col xs={24} xl={7} lg={7} md={7} sm={24} className='marginB15'>
                  <p className='footer-head-text'>
                    Indonesia
                  </p>
                  {/* <p className='footer-secondary-text'><img src={telephoneIcon} className='iconImg' alt='phone' />(+62) 21 5089 0580</p> */}
                  <p className='footer-secondary-text'><img src={whatsappIcon} className='iconImg' alt='Whats application_no' />(+62) 818 343 494</p>
                  <p className='footer-secondary-text'><img src={emailIcon} className='iconImg' alt='Email' />cs@ppibroker.co.id</p>
                </Col>
                {/* <Col xs={24} xl={10} lg={10} md={10} sm={24} className='marginB15'>
                  <p className='footer-head-text'>
                    Thailand
                  </p>
                  <p className='footer-secondary-text'><img src={telephoneIcon} className='iconImg' alt='phone' />+662 153 9589</p>
                  <p className='footer-secondary-text'><img src={emailIcon} className='iconImg' alt='Email' />customerserviceth@pasarpolis.co.id</p>
                </Col>
                <Col xs={24} xl={7} lg={7} md={7} sm={24} className='marginB15'>
                  <p className='footer-head-text'>
                    Vietnam
                  </p>
                  <p className='footer-secondary-text'><img src={telephoneIcon} className='iconImg' alt='phone' />1900 232454</p>
                  <p className='footer-secondary-text'><img src={emailIcon} className='iconImg' alt='Email' />cskh@pasarpolis.co.id</p>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </FooterWrapper>)
  }
}

export default (AppFooter)
