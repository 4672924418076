import Auth from './auth/reducer'
import App from './app/reducer'
import Error from './error/reducer'
import Loading from './loading/reducer'
import Policy from './policy/reducer'
import Claim from './claim/reducer'
import GojekPassenger from './gojekPassenger/reducer'
import GolifePassenger from './golifePassenger/reducer'
import User from './user/reducer'
import MasterData from './masterData/reducer'
import TokopediaEndorse from './tokopediaEndorse/reducer'
import TokopediaCrackScreenEndorse from './tokopediaCrackScreenEndorse/reducer'
import Endorsement from './Endorsement/reducer'
import LazadaCrackScreen from './lazadaCrackScreenEndorse/reducer'
import Agency from './agency/reducer'
export default {
  Auth,
  App,
  Error,
  Loading,
  Policy,
  Claim,
  GojekPassenger,
  GolifePassenger,
  User,
  MasterData,
  TokopediaEndorse,
  Endorsement,
  TokopediaCrackScreenEndorse,
  LazadaCrackScreen,
  Agency
}
