import styled from 'styled-components'

const FooterWrapper = styled.div`
    display:block;
    background-color:#000 !important;
    padding: 30px 0px;
    margin-top: 10px;
    width: 100%;
    a {
        color: rgb(213, 213, 213) !important;
        margin-bottom:25px;
    }
    li {
        margin-bottom:10px;
    }
    .fs-32 {
        font-size: 32px;
    }
    .footer-head-text{
      font-family: 'Zilla Slab', serif;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom:10px;
    }
    .footer-secondary-text{
      font-family: Muli;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
    .footer-question{
      opacity: 0.6;
      font-family: Muli;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
    ul {
        padding:15px;
    }
    .footer-elements {
        padding:10px;
    }
    .footer-content {
        padding-left:15px !important;
    }
    .social-section a {
        padding:0 !important;
        color:#151515 !important;
    }
    .social-section {
        padding: 40px 0;
        background: #000;
    }
    .footer-links a{
         color: #d5d5d5 !important;
    }
    .text-right {
        text-align: right;
    }
    .footer-links a:hover{
        color: #575656 !important;
    }
    .footer-links a:active{
        color: #575656 !important;
    }
    .iconImg {
        width: 15px;
        margin-right: 5px;
        vertical-align: middle;
    }
    .marginB15 {
        margin-bottom: 15px;
    }
    @media (max-width: 768px) {
      .text-right {
          text-align: center;
      }
      .fs-32 {
          font-size: 24px;
      }
      .fs-12{
          font-size:12px
      }
      .footer-content {
          padding-bottom:15px;
      }
    }
`

export default FooterWrapper
