import { all, takeEvery, put, fork, call, select, race, take } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import actions from './actions'
import agencyActions from '../agency/actions'
import request from '../../request/axios/claim'
import userRequest from '../../request/axios/user'
import middleware from '../../middleware/responseChecker'
// import sleep from '../.../components/utility/sleep'
import _ from 'lodash/object'
import _collection from 'lodash/collection'
import arr from 'lodash/array'
import sanitizeString from '../../helpers/sanitizeString'
import get from 'lodash/get'

export function * getBenefitsRequest () {
  yield takeEvery(actions.GET_BENEFITS, function * (payload) {
    let res = yield call(request.getBenefits, payload)
    if (res.status === 200) {
      const { data } = res
      yield put({
        type: actions.BENEFIT_LISTS,
        data,
        successGetBenefitLists: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.FAILED_GET_BENEFITS,
        failedGetBenefitLists: 'Maaf, kami tidak dapat menampilkan benefit anda'
      })
    }
  })
}

export function * getBenefitsList () {
  yield takeEvery(actions.GET_BENEFITS_LIST_REQUEST, function * ({ payload }) {
    const { history } = payload
    yield put({
      type: actions.CLEAR_AGENCY_BENEFIT_LIST
    })
    yield put({
      type: agencyActions.CLEAR_BENEFIT_LIST
    })
    let res = yield call(request.getBenefitsV4, payload)
    if (res.status === 200) {
      const { data } = res
      if (data.benefit_list_data?.length === 0) {
        yield put({
          type: actions.GET_BENEFITS_LIST_FAILURE,
          message: 'Maaf, kami tidak dapat menampilkan benefit anda'
        })
      } else {
        yield put({
          type: actions.GET_BENEFITS_LIST_SUCCESS,
          data,
          message: 'success'
        })
      }
    } else {
      middleware(res, history)
      yield put({
        type: actions.GET_BENEFITS_LIST_FAILURE,
        message: 'Maaf, kami tidak dapat menampilkan benefit anda'
      })
    }
  })
}

export function * postClaimRequest () {
  yield takeEvery(actions.CREATE_CLAIM, function * (payload) {
    let res = yield call(request.postClaim, payload)
    if (res.status === 201) {
      const { claim_id: claimId } = res.data
      yield put({
        type: actions.SUCCESS_CLAIM,
        claimId
      })
    } else {
      middleware(res)
      const { data } = res.response
      yield put({
        type: actions.FAILED_CLAIM,
        createClaimStatus: data.error
      })
    }
  })
}

export function * getClaimDetailsRequest () {
  yield takeEvery(actions.GET_CLAIM_DETAILS_REQUEST, function * (payload) {
    let res = yield call(request.getClaimDetails, payload)

    if (res.status === 200) {
      yield put({
        type: actions.GET_CLAIM_DETAILS_SUCCESS,
        claimDetails: res.data,
        getClaimDetailStatus: 'success'
      })
      yield put({
        type: actions.CHECK_FINAL_SUBMIT,
        forms: _.get(res.data, 'forms', []),
        paymentForms: _.get(res.data, 'payment_forms', []),
        claimId: _.get(res.data, 'claim_id'),
        statusCode: _.get(res.data, 'status.status_code')
      })
      yield put({
        type: actions.GET_ASSOCIATED_USER_ON_CLAIM_REQUEST,
        payload: _.get(res.data, 'claim_id')
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_CLAIM_DETAILS_FAILURE,
        getClaimDetailStatus:
          'Maaf, kami tidak dapat menemukan detail klaim anda'
      })
    }
  })
}

export function * checkFinalSubmit () {
  yield takeEvery(actions.CHECK_FINAL_SUBMIT, function * ({ forms, paymentForms = [], claimId, statusCode }) {
    const checkInvalid = !forms.length || forms.find(({ is_valid: isValid }) => !isValid) || (paymentForms.length && !_collection.find(paymentForms, { is_valid: true })) // check if any forms still have is_valid as false
    if (checkInvalid) return

    if (['initiated', 'reprocess'].includes(statusCode)) {
      yield put({
        type: actions.FINAL_SUBMIT_REQUEST,
        payload: { claim_id: claimId }
      })
    }
  })
}

export function * uploadGenericDocRequest () {
  yield takeEvery(actions.UPLOAD_GENERIC_CLAIM_DOC_REQUEST, function * ({ payload }) {
    const { formData, form, replace, signedUrlData } = payload
    const attribute = formData instanceof FormData ? formData.get('attribute') : ''
    try {
      let res = yield call(request.uploadGenericClaimDoc, payload)
      if (res.status === 200) {
        const { url: fileUrl } = signedUrlData

        yield put({
          type: actions.UPLOAD_GENERIC_CLAIM_DOC_SUCCESS,
          data: {
            [form]: {
              [attribute]: fileUrl
            }
          },
          saveAttributesGenericDocStatus: 'success',
          attribute,
          form,
          replace
        })
      } else {
        middleware(res)
        yield put({
          type: actions.UPLOAD_GENERIC_CLAIM_DOC_FAILURE,
          saveAttributesGenericDocStatus: res.data.detail,
          attribute,
          form
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPLOAD_GENERIC_CLAIM_DOC_FAILURE,
        saveAttributesGenericDocStatus: 'Gagal unggah file',
        attribute,
        form
      })
    }
  })
}

export function * updateUploadGenericClaimDocLoadingStates () {
  yield takeEvery([
    actions.UPLOAD_GENERIC_CLAIM_DOC_REQUEST,
    actions.UPLOAD_GENERIC_CLAIM_DOC_SUCCESS,
    actions.UPLOAD_GENERIC_CLAIM_DOC_FAILURE,
    actions.GENERATE_SIGNED_URL_AND_UPLOAD_REQUEST,
    actions.GENERATE_SIGNED_URL_AND_UPLOAD_SUCCESS,
    actions.GENERATE_SIGNED_URL_AND_UPLOAD_FAILURE
  ], function * ({ type, payload, attribute: payloadAttribute, form: payloadForm }) {
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)
    const [, , requestState] = matches
    const isRequest = requestState === 'REQUEST'
    let attribute = ''
    let form = ''

    if (isRequest) {
      const formData = _.get(payload, 'formData')
      form = _.get(payload, 'form')
      if (!(formData instanceof FormData) || !form) return
      attribute = formData.get('attribute')
    } else if (payloadAttribute && payloadForm) {
      attribute = payloadAttribute
      form = payloadForm
    }

    if (requestState === 'FAILURE') {
      const formData = _.get(payload, 'formData')
      form = _.get(payload, 'form')
      if (!(formData instanceof FormData) || !form) return
      attribute = formData.get('attribute')
      yield put({
        type: actions.UPDATE_UPLOAD_GENERIC_CLAIM_DOC_ERROR_STATES,
        target: { [form]: { [attribute]: false } },
        errorTarget: { [form]: { [attribute]: true } }
      })
      return
    }

    if (!attribute) return
    yield put({
      type: actions.UPDATE_UPLOAD_GENERIC_CLAIM_DOC_LOADING_STATES,
      target: { [form]: { [attribute]: isRequest } },
      errorTarget: { [form]: { [attribute]: false } }
    })
  })
}

export function * updateClaimFormWithGenericDoc () {
  yield takeEvery([
    actions.UPLOAD_GENERIC_CLAIM_DOC_SUCCESS,
    actions.UPDATE_CLAIM_FORM_WITH_GENERIC_DOC
  ], function * ({ attribute: payloadAttribute, form: payloadForm, data, replace }) {
    const uploadedData = _.get(data, `${payloadForm}.${payloadAttribute}`)
    let getClaimDetails = (state) => state.Claim.claimDetails
    let claimDetails = yield select(getClaimDetails) // SSR breaking
    let forms = claimDetails.forms
    const claimId = claimDetails.claim_id
    if (!forms) return
    let targetClaimDetailForm = forms.find(({ name }) => name === payloadForm)
    if (!targetClaimDetailForm) return
    let targetClaimDetailFormAttributes = targetClaimDetailForm.attributes
    if (!targetClaimDetailFormAttributes) return
    let targetAttribute = targetClaimDetailFormAttributes.find(({ name }) => name === payloadAttribute)
    if (!targetAttribute) return
    const existingValues = targetAttribute.value
    let newAttrValue = uploadedData

    if (Array.isArray(existingValues)) {
      newAttrValue = Array.from(new Set(targetAttribute.value.concat(uploadedData)))
      if (replace) {
        newAttrValue = arr.without(newAttrValue, replace)
      }
    }
    targetAttribute.value = newAttrValue

    yield put(actions.updateFormAttribute({
      body: {
        [payloadAttribute]: newAttrValue
      },
      params: {
        form: payloadForm,
        claimId
      }
    }))

    yield put({
      type: actions.UPDATE_CLAIM_DETAILS,
      claimDetails
    })
  })
}

// create action to update loading documents

export function * saveGenericAttributesRequest () {
  yield takeEvery(actions.SAVE_GENERIC_ATTRIBUTES_REQUEST, function * ({ payload }) {
    let res = yield call(request.saveGenericAttributes, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_CLAIM_DETAILS_REQUEST,
        payload: payload.claim_id
      })
      yield put({
        type: actions.SAVE_GENERIC_ATTRIBUTES_SUCCESS,
        payload
      })
    } else {
      let message =
        res.data.message !== undefined ? _.get(res.data.message, 'message', 'Ada yang salah. Silakan coba lagi.') : res.data.error
      middleware(res)
      yield put({
        type: actions.SAVE_GENERIC_ATTRIBUTES_FAILURE,
        payload,
        saveGenericAttributesStatus: message
      })
    }
  })
}

export function * finalSubmitRequest () {
  yield takeEvery(actions.FINAL_SUBMIT_REQUEST, function * ({ payload }) {
    const { claim_id: claimId } = payload
    let res = yield call(request.finalSubmit, payload)
    if (res.status === 200) {
      yield put({
        type: actions.FINAL_SUBMIT_SUCCESS,
        finalSubmitStatus: 'success'
      })
      yield put({
        type: actions.GET_CLAIM_DETAILS_REQUEST,
        payload: claimId
      })
    } else {
      let message =
        res.data.message !== undefined ? res.data.message : res.data.error
      middleware(res)
      yield put({
        type: actions.FINAL_SUBMIT_FAILURE,
        finalSubmitStatus: message
      })
    }
  })
}

export function * verifyBankAccountForms (props) {
  yield takeEvery(actions.VERIFY_BANK_ACCOUNT_FORMS_REQUEST, function * ({ payload }) {
    let data = {
      payload: {
        account_holder_name: payload.payload.account_holder_name,
        account_number: payload.payload.account_number,
        bank_code: payload.payload.bank_name,
        claim_no: payload.payload.claim_no
      }
    }
    try {
      let res = yield call(request.verifyBankAccount, data)
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_BANK_ACCOUNT_FORMS_SUCCESS,
          data: res.data,
          status: 'success'
        })
      } else {
        yield put({
          type: actions.VERIFY_BANK_ACCOUNT_FORMS_FAILURE,
          data: res.data,
          status: _.get(res, 'data.error', '')
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.VERIFY_BANK_ACCOUNT_FORMS_FAILURE,
          status: _.get(e, 'data.error', '')
        })
      }
    }
  })
}

export function * validateClaim () {
  yield takeEvery(actions.VALIDATE_CLAIM, function * (
    payload
  ) {
    yield put({
      type: actions.VALIDATE_CLAIM_STATUS_REQUEST
    })
    let res = yield call(request.validateClaimUrl, payload)
    if (res.status === 200) {
      yield put({
        type: actions.VALIDATE_CLAIM_STATUS,
        dataValidate: res.data,
        validateClaimStatus: 'success'
      })
      yield put({
        type: actions.VALIDATE_CLAIM_STATUS_SUCCESS
      })
    } else {
      middleware(res)
      yield put({
        type: actions.VALIDATE_CLAIM_STATUS,
        dataValidate: {},
        validateClaimStatus: res.data.error || 'message',
        validateClaimStatusCode: res.data.code
      })
      yield put({
        type: actions.VALIDATE_CLAIM_STATUS_FAILURE
      })
    }
  })
}

export function * getFeedbackForms () {
  yield takeEvery(actions.GET_FEEDBACK_FORMS_REQUEST, function * (
    payload
  ) {
    let res = yield call(request.getFeedbackFormsUrl, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_FEEDBACK_FORMS_SUCCESS,
        feedbackForms: res.data,
        getFeedbackFormStatus: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_FEEDBACK_FORMS_FAILURE,
        feedbackForms: {},
        getFeedbackFormStatus: res.data.error || 'message'
      })
    }
  })
}

export function * postFeedbackForms () {
  yield takeEvery(actions.POST_FEEDBACK_FORMS, function * (
    payload
  ) {
    let res = yield call(request.postFeedbackFormsUrl, payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.POST_FEEDBACK_FORMS_STATUS,
        postFeedbackFormStatus: 'Success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.POST_FEEDBACK_FORMS_STATUS,
        postFeedbackFormStatus: res.data.error || 'message'
      })
    }
  })
}
export function * createShippingClaim () {
  yield takeEvery(actions.CREATE_SHIPPING_CLAIM, function * (
    payload
  ) {
    let res = yield call(request.createShippingClaim, payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.CREATE_SHIPPING_CLAIM_RESULT,
        status: 'Success',
        data: res.data
      })
    } else {
      middleware(res)
      yield put({
        type: actions.CREATE_SHIPPING_CLAIM_RESULT,
        status: res.data.error || 'message'
      })
    }
  })
}

export function * postSalavageForm () {
  yield takeEvery(actions.POST_SALVAGE_FORMS_REQUEST, function * ({ payload }) {
    let res = yield call(request.postSalvageForms, payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.POST_SALVAGE_FORMS_SUCCESS,
        postSalvageFormStatus: 'success'
      })
      yield put({
        type: actions.GET_CLAIM_DETAILS_REQUEST,
        payload: payload.claim_id
      })
    } else {
      let message =
        res.data.message !== undefined ? res.data.message : res.data.error
      middleware(res)
      yield put({
        type: actions.POST_SALVAGE_FORMS_FAILURE,
        postSalvageFormStatus: message
      })
    }
  })
}

export function * getCommentsList () {
  yield takeEvery(actions.GET_COMMENTS_REQUEST, function * (payload) {
    let res = yield call(request.getComments, payload)
    if (res.status === 200) {
      const data = res?.data
      yield put({
        type: actions.GET_COMMENTS_SUCCESS,
        commentsList: data?.comments,
        successGetCommentLists: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_COMMENTS_FAILURE,
        failedGetBenefitLists: 'Maaf, kami tidak dapat menampilkan benefit anda'
      })
    }
  })
}

export function * getTicketsList () {
  yield takeEvery(actions.GET_TICKETS_REQUEST, function * (payload) {
    let res = yield call(request.getTickets, payload)
    if (res.status === 200) {
      const data = res?.data
      yield put({
        type: actions.GET_TICKETS_SUCCESS,
        ticketList: data,
        successGetTicketLists: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_TICKETS_FAILURE,
        successGetTicketLists: 'failure'
      })
    }
  })
}

export function * getUploadVerificationClaimFile () {
  yield takeEvery(actions.UPLOAD_VERIFICATION_CLAIM_FILE_REQUEST, function * ({ payload }) {
    const { params = { category: '', claim_id: '' } } = payload
    const { category, claim_id: referenceId } = params

    yield put(actions.uploadCommonClaimFile(payload))
    const [uploadResult, uploadFailure] = yield race([
      // if this occurs first, the race will exit, and success will be truthy
      take(actions.UPLOAD_COMMON_CLAIM_FILE_SUCCESS),
      // if either of these occurs first, the race will exit, and failure will be truthy
      take(actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE)
    ])
    if (uploadFailure) {
      yield put({
        type: actions.UPLOAD_VERIFICATION_CLAIM_FILE_FAILURE,
        message: 'Gagal memverifikasi gambar'
        // TODO: move to constant
      })
    }
    // if fileUrl exit onlt then call verify image api else shows error
    const fileUrl = _.get(uploadResult, 'data.url')
    if (fileUrl) {
      yield put({
        type: actions.UPLOAD_VERIFICATION_CLAIM_FILE_SUCCESS,
        message: 'Sukses'
      })
      yield put(actions.createVerifyImageRequest({ body: { gcp_url: fileUrl, reference_id: referenceId }, params: { category } }))
      const [verifyImageRequestResult, verifyImageRequestFailure] = yield race([
        // if this occurs first, the race will exit, and success will be truthy
        take(actions.CREATE_VERIFY_IMAGE_SUCCESS),
        // if either of these occurs first, the race will exit, and failure will be truthy
        take(actions.CREATE_VERIFY_IMAGE_FAILURE)
      ])

      const verifyImageRequestId = _.get(verifyImageRequestResult, 'data.request_id')

      if (verifyImageRequestFailure) {
        yield put({
          type: actions.UPLOAD_VERIFICATION_CLAIM_FILE_FAILURE,
          message: 'Gagal update user'
          // TODO: move to constant
        })
        yield put({
          type: actions.POLLING_VERIFY_IMAGE_FAILURE,
          verifyImageData: {},
          message: 'Gagal memverifikasi gambar'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.UPLOAD_VERIFICATION_CLAIM_FILE_SUCCESS,
          message: 'Sukses'
          // TODO: move to constant
        })
        // start polling request this request_id
        yield put({
          type: actions.POLLING_VERIFY_IMAGE_REQUEST,
          payload: {
            params: {
              requestId: verifyImageRequestId
            }
          }
        })
      }
    }
  })
}

export function * addComment () {
  yield takeEvery(actions.ADD_COMMENT_REQUEST, function * ({ payload }) {
    let res = yield call(request.addComment, payload)
    if (res.status === 200 || res.status === 201) {
      const { data } = res
      yield put({
        type: actions.ADD_COMMENT_SUCCESS,
        data,
        successAddCommentLists: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.ADD_COMMENT_FAILURE,
        successAddCommentLists: 'failure',
        failedCommentMessage: 'Tidak dapat mengirim pesan saat ini'
      })
    }
  })
}

export function * createVerifyImageRequest () {
  yield takeEvery(actions.CREATE_VERIFY_IMAGE_REQUEST, function * ({ payload }) {
    /*
      payload = { body = { gcp_url, reference_id } }
    */
    const verifyingImage = _.get(payload, 'body.gcp_url')
    let res = yield call(request.createVerifyImageRequest, payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.CREATE_VERIFY_IMAGE_SUCCESS,
        createVerifyImageStatus: 'Sukses',
        data: res.data,
        verifyingImage
      })
    } else {
      yield put({
        type: actions.CREATE_VERIFY_IMAGE_FAILURE,
        createVerifyImageStatus: 'Gagal',
        data: {},
        verifyingImage
      })
    }
  })
}

export function * getPollingVerifyImageRequest () {
  yield takeEvery(actions.POLLING_VERIFY_IMAGE_REQUEST, function * ({ payload }) {
    /*
      payload = { body, params, query, settings }
    */
    try {
      const { settings = { retryLimit: 5, delayInterval: 3000 } } = payload
      const { retryLimit, delayInterval } = settings
      const successStatus = ['OCR_NOT_REQUIRED', 'OCR_SUCCESSFUL']
      const errroStatus = ['DOCUMENT_INVALID', 'OCR_UNSUCCESSFUL']
      const endProcessStatus = [...successStatus, ...errroStatus]
      let success = false
      let retryNo = 0
      let verifyImageData = {}
      let ocrSuccessFull = false
      let ocrNotRequired = false

      while (retryNo !== retryLimit && !success) {
        yield put(actions.verifyImageRequest(payload))
        // const requestStart = performance.now()
        const [, result] = yield race([
          // if either failure or delay occurs first, the race will exit, and result will be false
          race({
            failure: take(actions.VERIFY_IMAGE_FAILURE),
            timeout: delay(delayInterval)
          }),
          // if this occurs first, the race will exit, and result will be truthy
          take(actions.VERIFY_IMAGE_SUCCESS)
        ])

        const resultStatus = _.get(result, 'data.status')
        if (endProcessStatus.includes(resultStatus)) {
          verifyImageData = {
            ...result.data
          }
          /*
            verifyImageData
            {
              info: {},
              status: ''
            }
          */
          ocrSuccessFull = successStatus.includes(resultStatus)
          ocrNotRequired = resultStatus === 'OCR_NOT_REQUIRED'
          success = true
          yield delay(delayInterval)
          break
        } else {
          yield delay(delayInterval) // delay based on what config should have taken
          retryNo++
        }
      }

      if (success) {
        yield put({
          type: actions.POLLING_VERIFY_IMAGE_SUCCESS,
          verifyImageData,
          ocrSuccessFull,
          ocrNotRequired,
          message: 'Sukses memverifikasi gambar'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.POLLING_VERIFY_IMAGE_FAILURE,
          verifyImageData,
          ocrSuccessFull,
          ocrNotRequired,
          message: 'Gagal memverifikasi gambar'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.POLLING_VERIFY_IMAGE_FAILURE,
        verifyImageData: {},
        message: 'Gagal memverifikasi gambar'
        // TODO: move to constant
      })
    }
  })
}

export function * getVerifyImageRequest () {
  yield takeEvery(actions.VERIFY_IMAGE_REQUEST, function * ({ payload }) {
    /*
      payload = { params = { requestId } }
    */
    try {
      let res = yield call(request.getVerifyImageRequestStatus, payload)
      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.VERIFY_IMAGE_SUCCESS,
          data: res.data,
          message: 'Sukses'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.VERIFY_IMAGE_FAILURE,
          data: {},
          message: 'Gagal mendapatkan status verifikasi gambar'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_IMAGE_FAILURE,
        data: {},
        message: 'Gagal mendapatkan status verifikasi gambar'
        // TODO: move to constant
      })
    }
  })
}

export function * getUploadCommonClaimFile () {
  yield takeEvery(actions.UPLOAD_COMMON_CLAIM_FILE_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(userRequest.uploadFile, payload)
      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.UPLOAD_COMMON_CLAIM_FILE_SUCCESS,
          data: _.get(res.data, 'data', ''),
          message: 'Sukses'
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
          data: _.get(res.data, 'data', ''),
          message: 'Gagal unggah file'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
        data: {},
        message: 'Gagal unggah file'
        // TODO: move to constant
      })
    }
  })
}

export function * getVerifyImageContentRequest () {
  yield takeEvery(actions.VERIFY_IMAGE_CONTENT_REQUEST, function * ({ payload }) {
    try {
      const { required, received } = payload
      if (!(required instanceof Object) || !(received instanceof Object)) {
        yield put({
          type: actions.VERIFY_IMAGE_CONTENT_FAILURE,
          resultDict: {},
          allValueMatches: false,
          message: 'Gagal mencocokkan data'
          // TODO: move to constant
        })
      } else {
        let resultDict = {}
        let allValueMatches = true
        Object.keys(required).forEach((value) => {
          const requiredDict = required[value]
          const receivedDict = received[value] || {}
          const {
            original_value: requiredValue,
            check_match: requiredCheck
          } = requiredDict
          const {
            value: receivedValue,
            label: receivedLabel
          } = receivedDict

          if (requiredCheck) {
            let message = ''
            let valueMatches = false
            const cleanReceivedValue = sanitizeString(receivedValue)
            const cleanRequiredValue = sanitizeString(requiredValue)
            if (receivedLabel === 'IMEI') {
              if (receivedValue.length) {
                if (String(receivedValue[0]) !== requiredValue) {
                  message = `${receivedLabel} tidak sesuai dengan Anggota Polis terdaftar`
                  allValueMatches = false
                }
              } else {
                allValueMatches = false
                message = `${receivedLabel} tidak sesuai dengan Anggota Polis terdaftar`
              }
            } else if (cleanRequiredValue !== cleanReceivedValue) {
              message = `${receivedLabel} tidak sesuai dengan Anggota Polis terdaftar`
              allValueMatches = false
            } else {
              valueMatches = true
            }

            resultDict[value] = {
              receivedLabel,
              requiredValue,
              receivedValue,
              message,
              valueMatches
            }
          }
        })
        yield put({
          type: actions.VERIFY_IMAGE_CONTENT_SUCCESS,
          resultDict,
          allValueMatches,
          message: 'Sukses'
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_IMAGE_CONTENT_FAILURE,
        resultDict: {},
        allValueMatches: false,
        message: 'Gagal unggah file'
        // TODO: move to constant
      })
    }
  })
}

export function * uploadCommentDoc () {
  yield takeEvery(actions.UPLOAD_COMMENT_DOC_REQUEST, function * ({ payload }) {
    let res = yield call(request.uploadCommentingDoc, payload)
    if (res.status === 200) {
      yield put({
        type: actions.UPLOAD_COMMENT_DOC_SUCCESS,
        data: res.data.data
      })
    } else {
      middleware(res)
      yield put({
        type: actions.UPLOAD_COMMENT_DOC_FAILURE,
        data: {}
      })
    }
  })
}

export function * updateFormAttribute () {
  yield takeEvery(actions.UPDATE_FORM_ATTRIBUTE_REQUEST, function * ({ payload }) {
    /*
      payload = { body = { [attributeKey] }, params = { claimId, formName } }
    */
    // const claimId = _.get(payload, 'params.claimId')
    let res = yield call(request.updateFormAttribute, payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.UPDATE_FORM_ATTRIBUTE_SUCCESS,
        message: 'Sukses',
        data: res.data
      })
      // yield put(actions.getClaimDetails(claimId))
    } else {
      yield put({
        type: actions.UPDATE_FORM_ATTRIBUTE_FAILURE,
        message: 'Gagal',
        data: {}
      })
    }
  })
}

export function * savePaymentForm () {
  yield takeEvery(actions.SAVE_PAYMENT_FORM_REQUEST, function * ({ payload }) {
    const { claim_id: claimId } = payload
    let res = yield call(request.savePaymentForm, payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.SAVE_PAYMENT_FORM_SUCCESS,
        message: 'Sukses',
        data: res.data
      })
      yield put(actions.getClaimDetails(claimId))
    } else {
      yield put({
        type: actions.SAVE_PAYMENT_FORM_FAILURE,
        message: 'Gagal',
        data: {}
      })
    }
  })
}

export function * getClaimBankList () {
  yield takeEvery(actions.GET_CLAIM_BANK_LIST_REQUEST, function * ({ payload }) {
    let res = yield call(request.getClaimBankList, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_CLAIM_BANK_LIST_SUCCESS,
        data: res.data
      })
    } else {
      yield put({
        type: actions.GET_CLAIM_BANK_LIST_FAILURE,
        data: []
      })
    }
  })
}

export function * generateSignedUrlAndUpload () {
  yield takeEvery(actions.GENERATE_SIGNED_URL_AND_UPLOAD_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getSignedUrl, payload)
      if (res.status === 200) {
        const responseData = yield res.data
        yield put({
          type: actions.UPLOAD_FILE_BY_SIGNED_URL_REQUEST,
          payload: {
            apiData: payload,
            signedUrlData: responseData
          }
        })
      } else {
        yield put({
          type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
          data: res.data,
          message: _.get(res.data, 'message', '')
        })

        yield put({
          type: actions.GENERATE_SIGNED_URL_AND_UPLOAD_FAILURE,
          payload
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
        data: {},
        message: 'Gagal unggah file'
        // TODO: move to constant
      })
    }
  })
}

export function * getChoicesExternalData () {
  yield takeEvery(actions.GET_CHOICES_EXTERNAL_DATA_REQUEST, function * ({ payload }) {
    let res = yield call(request.getChoicesExternalData, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_CHOICES_EXTERNAL_DATA_SUCCESS,
        data: res.data
      })
    } else {
      yield put({
        type: actions.GET_CHOICES_EXTERNAL_DATA_FAILURE,
        data: []
      })
    }
  })
}

export function * uploadFileUsingSignedUrl () {
  yield takeEvery(actions.UPLOAD_FILE_BY_SIGNED_URL_REQUEST, function * ({ payload }) {
    try {
      const { apiData, signedUrlData } = payload
      const body = {
        file: apiData.file,
        signedUrlData: signedUrlData
      }
      let res = yield call(request.fileUploadUsingSignedUrl, body)
      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.UPLOAD_GENERIC_CLAIM_DOC_REQUEST,
          payload: {
            ...apiData,
            signedUrlData
          }
        })
      } else {
        yield put({
          type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
          data: _.get(res.data, 'data', ''),
          message: 'Gagal unggah file'
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
        data: {},
        message: 'Gagal unggah file'
        // TODO: move to constant
      })
    }
  })
}

export function * getServiceCenterList () {
  yield takeEvery(actions.GET_SERVICE_CENTER_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getserviceCenterList, payload)
      if ([200, 201].includes(res.status)) {
        const serviceCenterList = _.get(res, 'data.data', [])
        if (!Array.isArray(serviceCenterList)) {
          yield put({
            type: actions.GET_SERVICE_CENTER_LIST_FAILURE,
            data: []
          })
        } else {
          if (serviceCenterList.length === 0) {
            yield put({
              type: actions.GET_SERVICE_CENTER_LIST_FAILURE,
              data: []
            })
          } else {
            yield put({
              type: actions.GET_SERVICE_CENTER_LIST_SUCCESS,
              data: _.get(res, 'data.data', [])
            })
          }
        }
      } else {
        yield put({
          type: actions.GET_SERVICE_CENTER_LIST_FAILURE,
          data: []
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_SERVICE_CENTER_LIST_FAILURE,
        data: [],
        message: ''
      })
    }
  })
}

export function * getServiceCenterBranchList () {
  yield takeEvery(actions.GET_SERVICE_CENTER_BRANCH_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getServiceCenterBranchList, payload)
      if ([200, 201].includes(res.status)) {
        const serviceCenterList = _.get(res, 'data.data', [])
        if (!Array.isArray(serviceCenterList)) {
          yield put({
            type: actions.GET_SERVICE_CENTER_BRANCH_LIST_FAILURE,
            data: []
          })
        } else {
          if (serviceCenterList.length === 0) {
            yield put({
              type: actions.GET_SERVICE_CENTER_BRANCH_LIST_FAILURE,
              data: [],
              message: _.get(res, 'data.message', '')
            })
          } else {
            yield put({
              type: actions.GET_SERVICE_CENTER_BRANCH_LIST_SUCCESS,
              data: _.get(res, 'data.data', [])
            })
          }
        }
      } else {
        yield put({
          type: actions.GET_SERVICE_CENTER_BRANCH_LIST_FAILURE,
          data: [],
          message: _.get(res, 'data.message', '')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_SERVICE_CENTER_BRANCH_LIST_FAILURE,
        data: [],
        message: _.get(e, 'response.data.message', '')
      })
    }
  })
}

export function * getProvinceList () {
  yield takeEvery(actions.GET_PROVINCE_LIST_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getProvinceList, payload)
      const provinceList = _.get(res, 'data.data', [])
      if (res.status === 200 && provinceList.length) {
        yield put({
          type: actions.GET_PROVINCE_LIST_SUCCESS,
          data: _.get(res, 'data.data', []),
          message: ''
        })
      } else {
        yield put({
          type: actions.GET_PROVINCE_LIST_FAILURE,
          data: _.get(res, 'data.data', []),
          message: 'Ada kesalahan di sistem kami, silahkan hubungi CS'
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_PROVINCE_LIST_FAILURE,
        data: [],
        message: 'Ada kesalahan di sistem kami, silahkan hubungi CS'
      })
    }
  })
}

export function * getCityListInProvince () {
  yield takeEvery(actions.GET_CITY_LIST_ON_PROVINCE_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getCityOnProvinceList, payload)

      if (res.status === 200) {
        yield put({
          type: actions.GET_CITY_LIST_ON_PROVINCE_SUCCESS,
          data: _.get(res, 'data.data', []),
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_CITY_LIST_ON_PROVINCE_FAILURE,
          data: _.get(res, 'data.data', []),
          message: 'asd'
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_CITY_LIST_ON_PROVINCE_FAILURE,
        data: [],
        message: e
      })
    }
  })
}

export function * getPostalCodeList () {
  yield takeEvery(actions.GET_POSTAL_CODE_LIST_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getPostalCodeList, payload)

      if (res.status === 200) {
        yield put({
          type: actions.GET_POSTAL_CODE_LIST_SUCCESS,
          data: _.get(res, 'data.data', []),
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_POSTAL_CODE_LIST_FAILURE,
          data: _.get(res, 'data.data', []),
          message: 'asd'
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_POSTAL_CODE_LIST_FAILURE,
        data: [],
        message: e
      })
    }
  })
}

export function * getTotalServiceCenter () {
  yield takeEvery(actions.GET_TOTAL_SERVICE_CENTER_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getTotalServiceCenterList, payload)

      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.GET_TOTAL_SERVICE_CENTER_LIST_SUCCESS,
          data: _.get(res, 'data.data', []),
          message: _.get(res, 'data.message', 'success')
        })
      } else {
        yield put({
          type: actions.GET_TOTAL_SERVICE_CENTER_LIST_FAILURE,
          data: _.get(res, 'data.data', []),
          message: _.get(res, 'data.message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_TOTAL_SERVICE_CENTER_LIST_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function * getQuetionnaireList () {
  yield takeEvery(actions.GET_QUESTIONS_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getQuetionnaireList, payload)

      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.GET_QUESTIONS_LIST_SUCCESS,
          data: _.get(res, 'data.data', []),
          message: _.get(res, 'data.message', 'success')
        })
      } else {
        yield put({
          type: actions.GET_QUESTIONS_LIST_FAILURE,
          data: _.get(res, 'data.data', []),
          message: _.get(res, 'data.message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_QUESTIONS_LIST_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function * getServiceCenterBrandList () {
  yield takeEvery(actions.GET_SERVICE_CENTER_BRAND_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getServiceCenterBrandList, payload)

      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.GET_SERVICE_CENTER_BRAND_LIST_SUCCESS,
          data: _.get(res, 'data.data', []),
          message: _.get(res, 'data.message', 'success')
        })
      } else {
        yield put({
          type: actions.GET_SERVICE_CENTER_BRAND_LIST_FAILURE,
          data: _.get(res, 'data.data', []),
          message: _.get(res, 'data.message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_SERVICE_CENTER_BRAND_LIST_FAILURE,
        data: [],
        message: ''
      })
    }
  })
}

export function * getFamilyEligibleUserList () {
  yield takeEvery(actions.GET_FAMILY_ELIGIBLIE_USER_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getFamilyEligibleUserList, payload)

      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.GET_FAMILY_ELIGIBLIE_USER_LIST_SUCCESS,
          userList: res.data.data.users,
          benefitRemainingTSI: res.data?.data?.benefit_remaining_TSI || 0,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_FAMILY_ELIGIBLIE_USER_LIST_FAILURE,
          userList: [],
          message: get(res, 'res.response.data.message', 'Ada kesalahan di sistem kami, silahkan hubungi CS')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_FAMILY_ELIGIBLIE_USER_LIST_FAILURE,
        userList: [],
        message: 'Ada kesalahan di sistem kami, silahkan hubungi CS'
      })
    }
  })
}

export function * postFamilyCreateClaim () {
  yield takeEvery(actions.POST_FAMILY_CREATE_CLAIM_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.postFamilyCreateClaims, payload)
      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.POST_FAMILY_CREATE_CLAIM_SUCCESS,
          claimId: res.data.claim_id,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.POST_FAMILY_CREATE_CLAIM_FAILURE,
          data: [],
          createClaimStatus: get(res, 'response.data.error', 'Ada kesalahan di sistem kami, silahkan hubungi CS')
        })
      }
    } catch (e) {
      yield put({
        type: actions.POST_FAMILY_CREATE_CLAIM_FAILURE,
        data: {},
        createClaimStatus: 'Ada kesalahan di sistem kami, silahkan hubungi CS'
      })
    }
  })
}

export function * getAssocaitedUserOnClaim () {
  yield takeEvery(actions.GET_ASSOCIATED_USER_ON_CLAIM_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(request.getAssocaitedUserOnClaim, payload)
      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.GET_ASSOCIATED_USER_ON_CLAIM_SUCCESS,
          data: res.data.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_ASSOCIATED_USER_ON_CLAIM_FAILURE,
          data: [],
          createClaimStatus: 'Ada kesalahan di sistem kami, silahkan hubungi CS'
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_ASSOCIATED_USER_ON_CLAIM_FAILURE,
        data: []
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getBenefitsRequest),
    fork(postClaimRequest),
    fork(getClaimDetailsRequest),
    fork(uploadGenericDocRequest),
    fork(updateUploadGenericClaimDocLoadingStates),
    fork(updateClaimFormWithGenericDoc),
    fork(checkFinalSubmit),
    fork(saveGenericAttributesRequest),
    fork(finalSubmitRequest),
    fork(verifyBankAccountForms),
    fork(validateClaim),
    fork(getFeedbackForms),
    fork(postFeedbackForms),
    fork(postSalavageForm),
    fork(createShippingClaim),
    fork(getCommentsList),
    fork(getTicketsList),
    fork(addComment),
    fork(uploadCommentDoc),
    fork(getUploadCommonClaimFile),
    fork(getUploadVerificationClaimFile),
    fork(getPollingVerifyImageRequest),
    fork(getVerifyImageRequest),
    fork(createVerifyImageRequest),
    fork(getVerifyImageContentRequest),
    fork(updateFormAttribute),
    fork(getBenefitsList),
    fork(savePaymentForm),
    fork(generateSignedUrlAndUpload),
    fork(uploadFileUsingSignedUrl),
    fork(getClaimBankList),
    fork(getChoicesExternalData),
    fork(getServiceCenterList),
    fork(getProvinceList),
    fork(getCityListInProvince),
    fork(getServiceCenterBranchList),
    fork(getPostalCodeList),
    fork(getTotalServiceCenter),
    fork(getQuetionnaireList),
    fork(getServiceCenterBrandList),
    fork(getFamilyEligibleUserList),
    fork(postFamilyCreateClaim),
    fork(getAssocaitedUserOnClaim)
  ])
}
