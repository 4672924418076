const host = process.env.REACT_APP_URL

const lazadaCrackScreenPolicyDetailsUrl = () => {
  let base = `${host}api/v1/policy/lazada-crack-screen-protection-ultra-policy-details/`
  return base
}

const postLazadaCrackScreenPolicyEndorse = ({ id }) => {
  let base = `${host}api/v1/policy/${id}/endorse-policy-lazada/`
  return base
}

const lazadaFullGadgetPolicyDetailsUrl = () => {
  let base = `${host}api/v1/policy/lazada-full-gadeget-protection-policy-details/`
  return base
}
const lazadaVehiclePolicyDetailsUrl = () => {
  let base = `${host}api/v1/policy/lazada-vehicle-protection-policy-details/`
  return base
}
const getLazadaHospitalCashPlanEndorse = () => {
  let base = `${host}api/v1/policy/lazada-hospital-cash-plan-details/`
  return base
}
const getprovinceDataUrl = () => {
  let base = `${host}api/v1/policy/province-details/`
  return base
}

export default {
  lazadaCrackScreenPolicyDetailsUrl,
  postLazadaCrackScreenPolicyEndorse,
  lazadaFullGadgetPolicyDetailsUrl,
  lazadaVehiclePolicyDetailsUrl,
  getLazadaHospitalCashPlanEndorse,
  getprovinceDataUrl
}
