const host = process.env.REACT_APP_URL

const postGosureEndorseUrl = (id) => {
  return `${host}api/v1/policy/${id}/gosure_endorsement/`
}

const postAggregateEndorseUrl = (product) => {
  return `${host}api/v1/policy/${product}/aggregate_endorse/`
}

const getBikeBrandListUrl = `${host}api/v1/tags/bike_brand_list/`
const getBikeModelListUrl = `${host}api/v1/tags/bike_model_list/`
const getNationalityDataUrl = `${host}api/v1/tags/nationality_data/`
const getCityDataUrl = `${host}api/v1/tags/city_data/`
const getDistrictDataUrl = `${host}api/v1/tags/district_data/`
const getTownDataUrl = `${host}api/v1/tags/town_data/`
const postUploadFile = `${host}api/v1/policy/upload/`

export default {
  postGosureEndorseUrl,
  postAggregateEndorseUrl,
  getBikeBrandListUrl,
  getBikeModelListUrl,
  getNationalityDataUrl,
  getCityDataUrl,
  getDistrictDataUrl,
  getTownDataUrl,
  postUploadFile
}
