const searchBuilder = finalFilter => {
  let query = ''
  if (Object.keys(finalFilter).length <= 1) {
    for (let key in finalFilter) {
      query += `?${key}=${finalFilter[key]}`
    }
  }
  if (Object.keys(finalFilter).length > 1) {
    query = '?'
    for (let key in finalFilter) {
      query += `${key}=${finalFilter[key]}&`
    }
    query = query.slice(0, -1)
  }
  return query
}

export default searchBuilder
