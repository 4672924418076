import actions from './actions'

const initState = {
  getBankListMessage: '',
  bankList: []
}

export default function masterDataReducer (state = initState, action) {
  switch (action.type) {
    case actions.GET_BANK_LIST_SUCCESS:
    case actions.GET_BANK_LIST_FAILURE:
      return {
        ...state,
        getBankListMessage: action.message,
        bankList: action.data
      }
    case actions.RESET_MASTER_DATA_REDUX:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}
