import url from '../../endpoint/language'
import axios from 'axios'

const getLanguage = async ({ messagesId }) => {
  try {
    const base = url.getLanguage(messagesId)
    return await axios.get(base, { crossdomain: true })
  } catch (error) {
    return error.message
  }
}

const getAppConfigs = async () => {
  try {
    const base = url.getAppConfigs()
    return await axios.get(base)
  } catch (error) {
    if (error.response) {
      return error.response
    } else {
      return error.message
    }
  }
}

export default {
  getLanguage,
  getAppConfigs
}
