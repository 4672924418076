import styled from 'styled-components'
import { COLORS } from '../../constants/colors'

export const HeaderMobileContainer = styled.div`
  display: flex;
  width: 360px;
  height: 48px;
  justify-content: flex-end;
  align-items: center;
  gap: 60px;
  flex-shrink: 0;

  display: flex;
  width: 100vw;
  height: 48px;
  justify-content: flex-end;
  align-items: center;
  gap: 60px;
  flex-shrink: 0;
  background: var(--TAP-TAP-Light, #FAF000);
  color: var(--Monochrome-Black, #333);
  text-align: center;

  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  padding: 16px;
  position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2;
`

export const HamburgerIconConatiner = styled.div``

export const SideDrawer = styled.div`
  min-height: 100vh;
  width: 100vw;
  background: transparent;
  position: fixed;
  z-index: 20;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); 
  top: 48px;
  height: -webkit-fill-available;
`

export const SideDrawerLinkContainer = styled.div`
  width: 60vw;
  background: #FFF;
  background: ${COLORS.tapPartnerYellow};
  display: flex;
  flex-direction: column;
  gap: 16px;

  color: var(--Monochrome-Black, #333);
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding-top: 16px;
`
export const HeaderTitle = styled.div`
  width: 100%;

  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
`

export const Link = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const IconContainer = styled.div``
