import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import request from '../../request/axios/tokopediaendorse'
import _ from 'lodash/object'

export function * getTokopediaPolicyDetailsRequest () {
  yield takeEvery(actions.GET_TOKOPEDIA_POLICY_DETAILS_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getTokopediaPolicyDetails, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_TOKOPEDIA_POLICY_DETAILS_SUCCESS,
          message: 'Sukses',
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_TOKOPEDIA_POLICY_DETAILS_FAILURE,
          message: _.get(res, 'data.message', _.get(res, 'data.errors', '')),
          data: {}
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_TOKOPEDIA_POLICY_DETAILS_FAILURE,
        message: 'Gagal mendapatkan detil',
        data: {}
        // TODO: move to constant
      })
    }
  })
}

export function * getFirstMediaPolicyDetailsRequest () {
  yield takeEvery(actions.GET_FIRSTMEDIA_POLICY_DETAILS_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getFirstMediaPolicyDetails, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_FIRSTMEDIA_POLICY_DETAILS_SUCCESS,
          message: 'Sukses',
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_TOKOPEDIA_POLICY_DETAILS_FAILURE,
          message: _.get(res, 'data.message', _.get(res, 'data.errors', '')),
          data: {}
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_TOKOPEDIA_POLICY_DETAILS_FAILURE,
        message: 'Gagal mendapatkan detil',
        data: {}
        // TODO: move to constant
      })
    }
  })
}

export function * getPostTokopediaEndorseRequest (props) {
  yield takeEvery(actions.POST_TOKOPEDIA_ENDORSE_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.postTokopediaPolicyEndorse, payload)
      if (res.status === 200) {
        yield put({
          type: actions.POST_TOKOPEDIA_ENDORSE_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.POST_TOKOPEDIA_ENDORSE_FAILURE,
          data: res.data,
          message: _.get(res, 'data.error', _.get(res, 'data.message', 'Terjadi Kesalahan'))
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.POST_TOKOPEDIA_ENDORSE_FAILURE,
          message: e.data.error
        })
      }
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getTokopediaPolicyDetailsRequest),
    fork(getPostTokopediaEndorseRequest),
    fork(getFirstMediaPolicyDetailsRequest)
  ])
}
