const actions = {
  RESET_STATE_GOLIFE_PASSENGER: 'RESET_STATE_GOLIFE_PASSENGER',
  CREATE_ORDER_GOLIFE_PASSENGER: 'CREATE_ORDER_GOLIFE_PASSENGER',
  CREATE_ORDER_GOLIFE_PASSENGER_STATUS: 'CREATE_ORDER_GOLIFE_PASSENGER_STATUS',
  GET_BENEFITS_GO_LIFE_REQUEST: 'GET_BENEFITS_GO_LIFE_REQUEST',
  GET_BENEFITS_GO_LIFE_SUCCESS: 'GET_BENEFITS_GO_LIFE_SUCCESS',
  GET_BENEFITS_GO_LIFE_FAILURE: 'GET_BENEFITS_GO_LIFE_FAILURE',
  GET_BENEFITS_GO_LIFE_STATUS: 'GET_BENEFITS_GO_LIFE_STATUS',
  CREATE_CLAIM_GO_LIFE_REQUEST: 'CREATE_CLAIM_GO_LIFE_REQUEST',
  CREATE_CLAIM_GO_LIFE_SUCCESS: 'CREATE_CLAIM_GO_LIFE_SUCCESS',
  CREATE_CLAIM_GO_LIFE_FAILURE: 'CREATE_CLAIM_GO_LIFE_FAILURE',
  CREATE_CLAIM_GO_LIFE_STATUS: 'CREATE_CLAIM_GO_LIFE_STATUS',
  resetStateGolifePassenger: () => ({
    type: actions.RESET_STATE_GOLIFE_PASSENGER
  }),
  createOrder: payload => ({
    type: actions.CREATE_ORDER_GOLIFE_PASSENGER,
    payload
  }),
  createOrderStatus: payload => ({
    type: actions.CREATE_ORDER_GOLIFE_PASSENGER_STATUS,
    payload
  }),
  getBenefits: payload => ({
    type: actions.GET_BENEFITS_GO_LIFE_REQUEST,
    payload
  }),
  createClaimGocarPassenger: payload => ({
    type: actions.CREATE_CLAIM_GO_LIFE_REQUEST,
    payload
  })
}
export default actions
