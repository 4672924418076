import React from 'react'
import { Row, Col, Card, Button } from 'antd'
import IntlMessages from '../../components/utility/intlMessages'

class Page404 extends React.Component {
  policyList = () => {
    const { history } = this.props
    history.push('/')
  }
  render () {
    return (
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card style={{ marginTop: '130px' }}>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={24}
                xl={24}
                style={{ textAlign: 'center' }}
              >
                <h2>
                  <IntlMessages id='Halaman tidak ditemukan' />
                </h2>
                <Button onClick={this.policyList} style={{ marginTop: '20px' }}>
                  <IntlMessages id='Kembali ke list polis' />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Page404
