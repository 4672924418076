import getRequest from '../get'
import postRequest from '../authPost'
import url from '../../endpoint/gojekPassenger'

const createOrder = async ({ payload }) => {
  try {
    const uri = url.createOrderUrl()
    return await postRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const getBenefitsGojekPassenger = async ({ payload }) => {
  try {
    const uri = url.getBenefitsGojekPassengerUrl()
    return await getRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const validateClaimGocar = async ({ payload }) => {
  try {
    const uri = url.validateClaimGocarUrl()
    return await getRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

const createClaimGocarPassenger = async ({ payload }) => {
  try {
    const uri = url.createClaimGocarPassengerUrl()
    return await postRequest(uri, payload)
  } catch (error) {
    return error.response
  }
}

export default {
  createOrder,
  getBenefitsGojekPassenger,
  createClaimGocarPassenger,
  validateClaimGocar
}
