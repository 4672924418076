import postRequest from '../post'
import postUploadRequest from '../postUpload'
import getRequest from '../get'
import url from '../../endpoint/Endorsement'
import queryBuilder from '../../../helpers/queryBuilder'

const postGosureEndorseRequest = async ({ body, id }) => {
  try {
    return await postRequest(url.postGosureEndorseUrl(id), body)
  } catch (error) {
    return error.response
  }
}

const postAggregateEndorseRequest = async ({ body, id }) => {
  try {
    return await postRequest(url.postAggregateEndorseUrl(id), body)
  } catch (error) {
    return error.response
  }
}

const postUploadFileRequest = async (formData) => {
  try {
    return await postUploadRequest(url.postUploadFile, formData)
  } catch (error) {
    return error.response
  }
}

const getBikeModelList = async () => {
  try {
    const base = url.getBikeModelListUrl
    return await getRequest(base)
  } catch (error) {
    return error
  }
}

const getBikebrandList = async () => {
  try {
    const base = url.getBikeBrandListUrl
    return await getRequest(base)
  } catch (error) {
    return error
  }
}
const getNationalityData = async () => {
  try {
    const base = url.getNationalityDataUrl
    return await getRequest(base)
  } catch (error) {
    return error
  }
}
const getCityData = async () => {
  try {
    const base = url.getCityDataUrl
    return await getRequest(base)
  } catch (error) {
    return error
  }
}
const getDistrictData = async (payload) => {
  try {
    const queryString = queryBuilder(payload)
    const base = `${url.getDistrictDataUrl}${queryString}`
    return await getRequest(base)
  } catch (error) {
    return error
  }
}
const getTownData = async (payload) => {
  try {
    const queryString = queryBuilder(payload)
    const base = `${url.getTownDataUrl}${queryString}`
    return await getRequest(base)
  } catch (error) {
    return error
  }
}
export default {
  postGosureEndorseRequest,
  getBikeModelList,
  getBikebrandList,
  postAggregateEndorseRequest,
  getNationalityData,
  getCityData,
  getDistrictData,
  getTownData,
  postUploadFileRequest
}
