import getRequest from '../get'
import postRequest from '../post'
import url from '../../endpoint/agency/agency'

const getAgentPolicyDetails = async ({ payload }) => {
  try {
    const base = url.agencyPolicyDetail() + '&id=' + payload
    return await getRequest(base)
  } catch (error) {
    return error
  }
}

const validateAgentClaim = async ({ query }) => {
  try {
    const uri = `${url.validateAgencyClaimUrl()}?${query}`
    return await getRequest(uri)
  } catch (error) {
    return error.response
  }
}
const createAgencyClaim = async ({ payload }) => {
  try {
    const data = {
      ...payload,
      internal_id: payload.internal_id,
      benefit_id: payload.benefit_id,
      source: payload.source,
      user_id: payload.user_id
    }
    const uri = url.postAgencyClaim()
    return await postRequest(uri, data)
  } catch (error) {
    return error
  }
}

const getAgencyBenefits = async ({ payload }) => {
  const { query } = payload
  try {
    const uri = url.agencyBenefitsURL(query)
    return await getRequest(uri)
  } catch (error) {
    return error
  }
}

const agencyPolicyList = async ({ payload }) => {
  try {
    const uri = url.agencyPolicyListURL(payload)
    return await getRequest(uri)
  } catch (error) {
    return error
  }
}
const agencyClaimList = async ({ payload }) => {
  try {
    const uri = url.agencyClaimListURL(payload)
    return await getRequest(uri)
  } catch (error) {
    return error
  }
}

export default {
  getAgentPolicyDetails,
  validateAgentClaim,
  createAgencyClaim,
  getAgencyBenefits,
  agencyPolicyList,
  agencyClaimList
}
