// Footer.js
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  FooterContainer,
  Section,
  Title,
  List,
  ListItem,
  Address,
  AddressTitle,
  AddressContent,
  Left,
  Right,
  FooterLogo,
  FooterAboutContent,
  ContactContainer,
  ContactList
} from './FooterPPBroker.style'
import { LANG_STRINGS } from '../../constants/lang'
import { getAssets } from '../../helpers/utility'

export const footerLogo = 'https://storage.googleapis.com/pp_img/ppib_assets/common/PasarPolis.svg'

const Footer = () => {
  const intl = useIntl()

  return (
    <FooterContainer>
      <Left>
        <Section>
          <Title><FormattedMessage id={LANG_STRINGS.about} /></Title>
          <p><FormattedMessage id={LANG_STRINGS.aboutContent} /></p>
          <FooterLogo
            src={footerLogo}
            alt='PasarPolis'
          />
          <FooterAboutContent>
            <FormattedMessage id={LANG_STRINGS.aboutContent2} />
          </FooterAboutContent>
        </Section>
      </Left>

      <Right>

        <Section>
          <Title><FormattedMessage id={LANG_STRINGS.info} /></Title>
          <List>
            <a href='https://ppibroker.com/faq' target='_blank'>
              <ListItem type='link'>
                <FormattedMessage id={LANG_STRINGS.faq} />
              </ListItem>
            </a>
          </List>
        </Section>
        <Section>
          <Title><FormattedMessage id={LANG_STRINGS.company} /></Title>
          <List>
            <a href='https://ppibroker.com/about' target='_blank'>
              <ListItem type='link'>
                <FormattedMessage id={LANG_STRINGS.about} />
              </ListItem>
            </a>
          </List>
        </Section>
        <Section>
          <Title><FormattedMessage id={LANG_STRINGS.office} /></Title>
          <Address>
            <AddressTitle>{intl.formatMessage({ id: LANG_STRINGS.ptBrokerTitle })}</AddressTitle>
            <AddressContent>{intl.formatMessage({ id: LANG_STRINGS.ppBrokeraddress })}</AddressContent>

            <ContactContainer >
              <a href={`tel:${getAssets().phoneNumber1}`}>
                <ContactList>
                  P: {getAssets().phoneNumber1}
                </ContactList>
              </a>
              <a href={`tel:${getAssets().phoneNumber2}`}>
                <ContactList>
                  P: {getAssets().phoneNumber2}
                </ContactList>
              </a>
              <a href={`mailto:${getAssets().emailAddress}`}>
                <ContactList>
                  E: {getAssets().emailAddress}
                </ContactList>
              </a>
            </ContactContainer>
            <a target='_blank' href={getAssets().googleMapLink}>
              <ListItem type='link'>
                {intl.formatMessage({ id: LANG_STRINGS.viewMap })}
              </ListItem>
            </a>
          </Address>
        </Section>
      </Right>
    </FooterContainer >
  )
}

export default Footer
