import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import request from '../../request/axios/masterData'

export function * getUpdateUserBankAccount () {
  yield takeEvery(actions.GET_BANK_LIST_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.getBankList, payload)
      if (res.status === 200) {
        yield put({
          type: actions.GET_BANK_LIST_SUCCESS,
          message: 'Sukses',
          data: res.data
          // TODO: move to constant
        })
      } else {
        yield put({
          type: actions.GET_BANK_LIST_FAILURE,
          message: 'Gagal menyimpan rekening',
          data: []
          // TODO: move to constant
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_BANK_LIST_FAILURE,
        message: 'Gagal menyimpan rekening',
        data: []
        // TODO: move to constant
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getUpdateUserBankAccount)
  ])
}
