import authGet from '../authGet'
import url from '../../endpoint/masterData'
// import axios from 'axios'

const getBankList = async () => {
  try {
    const base = url.getBankList()
    return await authGet(base)
  } catch (error) {
    return error.response
  }
}

export default {
  getBankList
}
