import React, { useEffect, useRef, useState } from 'react'
import { flagsUrlOntheGCP } from '../../constants'
import { ArrowDown } from '../../image'
import { Wrapper } from './languageDropDown.style'
import { COLORS } from '../../constants/colors'

function LanguageDropDown ({
  background = COLORS.brandYellow,
  changeLanguagehandler = () => { },
  selectedLanguage = 'id'
}) {
  const [openDropDown, setOpenDropdown] = useState(false)

  const ref = useRef(null)

  const flagSelectHandler = (lang, countryCode) => {
    setOpenDropdown(false)
    changeLanguagehandler(lang, countryCode)
  }

  const toggleArrow = (e) => {
    e.stopPropagation()
    setOpenDropdown((pos) => !pos)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenDropdown(false)
      }
    }

    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [openDropDown])

  return (
    <Wrapper ref={ref} openDropDown={openDropDown} background={background}>
      <div className='language-wrapper'>
        <div className='lang-list'>
          {
            flagsUrlOntheGCP.sort((a, b) => a.lang !== selectedLanguage ? 1 : -1).map(({ flag, lang, countryCode }, index) => {
              if (!openDropDown && index !== 0) return null
              return (
                <div key={lang} onClick={() => index !== 0 && flagSelectHandler(lang, countryCode)}>
                  <img height='28px' width='28px' src={flag} alt={lang} />
                  {openDropDown
                    ? <>
                      &nbsp;
                      {lang}
                  </>
                    : null
                  }
                </div>
              )
            })
          }
        </div>
        <div
          className='arrow'
          onClick={toggleArrow}
          style={{
            height: '100%'
          }}
        >
          <img
            alt='arrow'
            src={ArrowDown}
            className={openDropDown ? 'transform-180' : 'transform-360'}
          />
        </div>
      </div>

    </Wrapper>
  )
}

export default LanguageDropDown
