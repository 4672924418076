import { DocumentIcon, ProfileIcon } from '../image'
import { COLORS } from './colors'
import { LANG_STRINGS } from './lang'

export const RESEND_OTP_COUNT_DOWN = 30000
export const WHATS_APP_NUMBER = 6281197009800

export const COUNTRY_CODES = [
  {
    value: '62',
    displayName: '+62'
  },
  {
    value: '66',
    displayName: '+66'
  },
  {
    value: '84',
    displayName: '+84'
  },
  {
    value: '60',
    displayName: '+60'
  },
  {
    value: '65',
    displayName: '+65'
  },
  {
    value: '63',
    displayName: '+63'
  },
  {
    value: '886',
    displayName: '+886'
  },
  {
    value: '86',
    displayName: '+86'
  },
  {
    value: '852',
    displayName: '+852'
  },
  {
    value: '61',
    displayName: '+61'
  },
  {
    value: '52',
    displayName: '+52'
  },
  {
    value: '48',
    displayName: '+48'
  },
  {
    value: '81',
    displayName: '+81'
  },
  {
    value: '82',
    displayName: '+82'
  },
  {
    value: '44',
    displayName: '+44'
  },
  {
    value: '31',
    displayName: '+31'
  },
  {
    value: '39',
    displayName: '+39'
  },
  {
    value: '91',
    displayName: '+91'
  }
]

export const B2CNAVIGATION_LINKS = [
  {
    title: LANG_STRINGS.policy,
    link: '/?source=polis',
    isNonLinkApplicable: true
  },
  {
    title: LANG_STRINGS.claim,
    link: '/?source=claim'
  }
]

export const AGENCY_NAVIGATION_LINKS = [
  {
    title: LANG_STRINGS.agencyPolicy,
    link: '/?source=agency-policy'
  },
  {
    title: LANG_STRINGS.agencyClaim,
    link: '/?source=agency-claim'
  }
]

export const genricImageLinks = {
  fallbackLogo: 'https://storage.googleapis.com/pp_img/website/pp-logo-black-cap.png'
}

export const flagsUrlOntheGCP = [
  { flag: 'https://storage.googleapis.com/pp_img/templates/Service-Center/Indonesia.svg', lang: 'id', countryCode: '62' },
  { flag: 'https://storage.googleapis.com/pp_img/templates/Service-Center/Vietnam.svg', lang: 'vi', countryCode: '62' },
  { flag: 'https://storage.googleapis.com/pp_img/templates/Service-Center/Thailand.svg', lang: 'th', countryCode: '66' },
  { flag: 'https://storage.googleapis.com/pp_img/templates/Service-Center/United%20Kingdom.svg', lang: 'en', countryCode: '84' }
]

export const profileRenderMenu = [
  { title: LANG_STRINGS.profile_details, link: '/profile', img: ProfileIcon },
  { title: LANG_STRINGS.document_details, link: '/documents', img: DocumentIcon }
]

export const b2b2cUser = 'b2b2c_user'
export const tapinsuranceUser = 'tapinsurance_user'

export const agencyAgent = 'agency_agent'
export const agencyCustomer = 'agency_customer'

export const agencyPolicyLink = 'agency-policy'
export const polisLink = 'polis'

export const tabKeyMap = {
  'polis': 'polis',
  'claim': 'claim',
  'agencyPolis': 'agency-policy',
  'agencyClaim': 'agency-claim'
}

export const benefitSteps = {
  loading: 'loading',
  singleBenefitList: 'singleBenefitList',
  multiBenefitList: 'multiBenefitList',
  addOnQuestionList: 'addOnQuestionList',
  beneficeryUserList: 'beneficeryUserList',
  errorBenefitMessage: 'errorBenefitMessage',
  validateOrCreateClaimMessage: 'validateOrCreateClaimMessage',
  tapTravelUserList: 'tapTravelUserList',
  tsiInfoBanner: 'tsiInfoBanner',
  secondClaimOnSameBenefit: 'secondClaimOnSameBenefit'
}
export const PolicyTabs = [
  { title: LANG_STRINGS.allPolicy, filter: '', mobileTitle: LANG_STRINGS.all },
  { title: LANG_STRINGS.activePolicy, filter: 'ACTIVE', mobileTitle: LANG_STRINGS.active },
  { title: LANG_STRINGS.expiredPolciy, filter: 'INACTIVE', mobileTitle: LANG_STRINGS.expired }
]

export const AgencyTabs = [
  { title: LANG_STRINGS.allPolicy, filter: '', mobileTitle: LANG_STRINGS.all },
  { title: LANG_STRINGS.activePolicy, filter: 'ACTIVE', mobileTitle: LANG_STRINGS.active },
  { title: LANG_STRINGS.expiredPolciy, filter: 'EXPIRED', mobileTitle: LANG_STRINGS.expired }
]

export const ClaimTabs = [
  { title: LANG_STRINGS.allClaims, filter: '', mobileTitle: LANG_STRINGS.all },
  { title: LANG_STRINGS.initatedClaim, filter: 'initiated', mobileTitle: LANG_STRINGS.initiated },
  { title: LANG_STRINGS.setteldClaim, filter: 'after-documents', mobileTitle: LANG_STRINGS.transition },
  { title: LANG_STRINGS.rejectedClaim, filter: 'settled', mobileTitle: LANG_STRINGS.closed }
]

export const setteledClaimsStauses = ['settled', 'rejected']

export const claimsNotInIntiatedSetteledAndRejected = ['settled', 'rejected', 'initiated']

export const STATUS_GROUP_INITIATED = 'initiated'
export const STATUS_GROUP_DOCUMENT_PENDING = 'document_pending'
export const STATUS_GROUP_DOC_RECEIVED = 'document_received'
export const STATUS_GROUP_DOC_REPROCESS = 'document_reprocess'
export const STATUS_GROUP_DOC_VERIFIED = 'document_verified'
export const STATUS_GROUP_WORK_ORDER_RELEASED = 'work_order_released'
export const STATUS_GROUP_APPROVED = 'approved'
export const STATUS_GROUP_SETTLED = 'settled'
export const STATUS_GROUP_REJECTED = 'rejected'
export const STATUS_GROUP_SALVAGE_PENDING = 'salvage_pending'
export const STATUS_GROUP_SALVAGE_DISPATCHED = 'salvage_dispatched'
export const STATUS_GROUP_SALVAGE_RECEIVED = 'salvage_received'
export const STATUS_GROUP_SALVAGE_VERIFIED = 'salvage_verified'
export const STATUS_GROUP_ON_SURVEY = 'on_survey'
export const STATUS_GROUP_AWAITING_WORK_ORDER_APPROVAL = 'awaiting_work_order_approval'
export const STATUS_GROUP_REPAIR_PROCESS = 'repair_process'
export const STATUS_GROUP_VEHICLE_RECEIVED = 'vehicle_received'
export const STATUS_GROUP_VEHICLE_AWAITED = 'vehicle_awaited'
export const STATUS_GROUP_SERVICE_CENTER_SURVEY = 'service_center_survey'
export const STATUS_GROUP_SERVICE_CENTER_WORK_IN_REPAIR = 'service_center_work_in_repair'
export const STATUS_GROUP_REPAIR_COMPLETE_BY_SERVICE_CENTER = 'repair_complete_by_service_cennter'
export const STATUS_GROUP_DEVICE_DELIVERY = 'device_delivery_in_progress'
export const STATUS_GROUP_CLAIM_CANCELLED = 'claim_cancelled'

export const mapStatusGroupTextCOLOR = {
  [STATUS_GROUP_INITIATED]: COLORS.originGold,
  [STATUS_GROUP_DOCUMENT_PENDING]: COLORS.originGold,
  [STATUS_GROUP_DOC_REPROCESS]: COLORS.mysticVoilet,
  [STATUS_GROUP_REJECTED]: COLORS.crimsonRed,
  [STATUS_GROUP_SETTLED]: COLORS.grassGreen
}
export const mapStatusGroupTextBackGround = {
  [STATUS_GROUP_INITIATED]: COLORS.lightYellow,
  [STATUS_GROUP_DOCUMENT_PENDING]: COLORS.lightYellow,
  [STATUS_GROUP_DOC_REPROCESS]: COLORS.lightVoilet,
  [STATUS_GROUP_REJECTED]: COLORS.lightRed,
  [STATUS_GROUP_SETTLED]: COLORS.lightGreen
}

export const CHRONOLOGYFIELDITEMS = ['chronology', 'chronologies']

export const CLAIMFORMICONS = {
  'date': 'https://storage.googleapis.com/pp_img/claim_portal/star-Calendar.svg',
  'year': 'https://storage.googleapis.com/pp_img/claim_portal/star-Calendar.svg',
  'time': 'https://storage.googleapis.com/pp_img/claim_portal/Clock.svg',
  'string': 'https://storage.googleapis.com/pp_img/claim_portal/Edit.svg',
  'text': 'https://storage.googleapis.com/pp_img/claim_portal/Edit.svg',
  'default': 'https://storage.googleapis.com/pp_img/claim_portal/Edit.svg'
}

export const BOOLEANOPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

export const FORMFIELDTYPES = {
  BOOLEAN: 'boolean',
  DISCLAIMER: 'disclaimer',
  CHECKBOX: 'checkbox',
  choices: 'choices',
  ASTERISK: 'asterisk',
  CHOICESEXTERNAL: 'choices_external',
  YEAR: 'year',
  DATE: 'date'
}

export const serviceCenterFormTitle = 'servicecenter-branch-details'

export const bankDeatilsFormTitle = 'bank-details-form'
export const bankDetailFormTitleV2 = 'bank-details'
export const goPayWalletFormName = 'bank-details-gopay-wallet'

export const servicecenterFormAttributes = {
  provinceConst: 'sc_province',
  brandConst: 'sc_brand',
  cityConst: 'sc_city',
  postalCodeConst: 'sc_postal_code',
  serviceCenterConst: 'service_center',
  serviceBranchCenterConst: 'servicecenter_branch'
}
