import { injectIntl, FormattedMessage } from 'react-intl'

const InjectMessage = ({ id, defaultMessage, values }) => {
  return (
    <FormattedMessage
      id={id || ' '}
      defaultMessage={defaultMessage || id}
      values={values || {}}
    />
  )
}
export default injectIntl(InjectMessage, {
  withRef: false
})
