import React from 'react'
import styled from 'styled-components'
import isSSO from '../../helpers/isSSOSession'

const SSOStyles = styled.div`
  visibility: ${props => props.isSSOSession ? 'hidden' : 'visible'};
    `

const SSOSessionWrapper = (props) => {
  const isSSOSession = isSSO()

  return (
    <SSOStyles isSSOSession={isSSOSession}>
      {props.children}
    </SSOStyles>
  )
}

export default SSOSessionWrapper
