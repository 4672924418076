const host = process.env.REACT_APP_URL

const getTokopediaPolicyDetails = () => {
  let base = `${host}api/v1/policy/tokopedia-policy-details/`
  return base
}
const getFirstMediaPolicyDetails = () => {
  let base = `${host}api/v1/policy/firstmedia-policy-details/`
  return base
}

const postTokopediaPolicyEndorse = ({ id }) => {
  let base = `${host}api/v1/policy/${id}/endorse-policy/`
  return base
}

export default {
  getTokopediaPolicyDetails,
  postTokopediaPolicyEndorse,
  getFirstMediaPolicyDetails
}
