import actions from './actions'

const initState = {
  profile: {},
  details: {},
  locations: [],
  phoneOperationId: '',
  emailOperationId: '',
  getUserDetailsMessage: '',
  updatePhoneMessage: '',
  updatePhoneCode: '',
  updateEmailMessage: '',
  updateEmailCode: '',
  addEmailMessage: '',
  addEmailCode: '',
  getUserProfileMessage: '',
  getLocationsMessage: '',
  sendPhoneOtpMessage: '',
  sendEmailOtpMessage: '',
  uploadUserFileMessage: '',
  updateUserMessage: '',
  updateUserProfileMessage: '',
  updateCompleteUserMessage: '',
  getUserDocumentsMessage: '',
  putUserDocumentsMessage: '',
  deleteUserDocumentsMessage: '',
  getUserBankAccountsMessage: '',
  updateUserBankAccountsMessage: '',
  postUserBankAccountsMessage: '',
  deleteUserBankAccountsMessage: '',
  updatingDocumentId: '',
  updatingBankAccountId: '',
  preferredBankAccountId: undefined,
  isLoggedIn: false
}

export default function appReducer (state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_USER_PROFILE_REDUCER:
      return {
        ...initState
      }
    case actions.GET_USER_PROFILE_SUCCESS:
    case actions.GET_USER_PROFILE_FAILURE:
      return { ...state, profile: action.data, getUserProfileMessage: action.message }
    case actions.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        details: action.data,
        getUserDetailsMessage: action.message,
        thirdPartyUser: action.thirdPartyUser,
        isDanaUser: action.isDanaUser
      }
    case actions.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        details: action.data,
        getUserDetailsMessage: action.message,
        thirdPartyUser: action.thirdPartyUser,
        isDanaUser: action.isDanaUser
      }
    case actions.GET_LOCATIONS_SUCCESS:
    case actions.GET_LOCATIONS_FAILURE:
      return { ...state, locations: action.data, getLocationsMessage: action.message }
    case actions.SEND_PHONE_OTP_SUCCESS:
    case actions.SEND_PHONE_OTP_FAILURE:
      return { ...state, phoneOperationId: action.data, sendPhoneOtpMessage: action.message }
    case actions.SEND_EMAIL_OTP_SUCCESS:
    case actions.SEND_EMAIL_OTP_FAILURE:
      return { ...state, emailOperationId: action.data, sendEmailOtpMessage: action.message }
    case actions.VERIFY_OTP_SUCCESS:
    case actions.VERIFY_OTP_FAILURE:
      return { ...state, token: action.data }
    case actions.UPDATE_PHONE_SUCCESS:
    case actions.UPDATE_PHONE_FAILURE:
      return { ...state, updatePhoneMessage: action.message, updatePhoneCode: action.code }
    case actions.UPDATE_EMAIL_SUCCESS:
    case actions.UPDATE_EMAIL_FAILURE:
      return { ...state, updateEmailMessage: action.message, updateEmailCode: action.code }
    case actions.ADD_EMAIL_SUCCESS:
    case actions.ADD_EMAIL_FAILURE:
      return { ...state, addEmailMessage: action.message, addEmailCode: action.code }
    case actions.UPLOAD_USER_FILE_SUCCESS:
    case actions.UPLOAD_USER_FILE_FAILURE:
      return { ...state, uploadUserFileMessage: action.message, userFile: action.data }
    case actions.UPDATE_USER_SUCCESS:
    case actions.UPDATE_USER_FAILURE:
      return { ...state, updateUserMessage: action.message }
    case actions.UPDATE_USER_PROFILE_SUCCESS:
    case actions.UPDATE_USER_PROFILE_FAILURE:
      return { ...state, updateUserProfileMessage: action.message }
    case actions.UPDATE_COMPLETE_USER_SUCCESS:
    case actions.UPDATE_COMPLETE_USER_FAILURE:
      return { ...state, updateCompleteUserMessage: action.message }
    case actions.GET_USER_DOCUMENTS_SUCCESS:
    case actions.GET_USER_DOCUMENTS_FAILURE:
      return { ...state, getUserDocumentsMessage: action.message, userDocuments: action.data }
    case actions.POST_USER_DOCUMENTS_SUCCESS:
    case actions.POST_USER_DOCUMENTS_FAILURE:
      return { ...state, postUserDocumentsMessage: action.message }
    case actions.PUT_USER_DOCUMENTS_SUCCESS:
    case actions.PUT_USER_DOCUMENTS_FAILURE:
      return { ...state, putUserDocumentsMessage: action.message }
    case actions.DELETE_USER_DOCUMENTS_SUCCESS:
    case actions.DELETE_USER_DOCUMENTS_FAILURE:
      return { ...state, deleteUserDocumentsMessage: action.message }
    case actions.GET_USER_BANK_ACCOUNTS_SUCCESS:
    case actions.GET_USER_BANK_ACCOUNTS_FAILURE:
      return { ...state, getUserBankAccountsMessage: action.message, userBankAccounts: action.data, preferredBankAccountId: action.preferredBankAccountId }
    case actions.PUT_USER_BANK_ACCOUNTS_SUCCESS:
    case actions.PUT_USER_BANK_ACCOUNTS_FAILURE:
      return { ...state, updateUserBankAccountsMessage: action.message }
    case actions.POST_USER_BANK_ACCOUNTS_SUCCESS:
    case actions.POST_USER_BANK_ACCOUNTS_FAILURE:
      return { ...state, postUserBankAccountsMessage: action.message }
    case actions.DELETE_USER_BANK_ACCOUNTS_SUCCESS:
    case actions.DELETE_USER_BANK_ACCOUNTS_FAILURE:
      return { ...state, deleteUserBankAccountsMessage: action.message }
    case actions.SET_UPDATING_DOCUMENT_ID:
      return { ...state, updatingDocumentId: action.id }
    case actions.SET_UPDATING_BANK_ACCOUNT_ID:
      return { ...state, updatingBankAccountId: action.id }
    case actions.VERIFY_BANK_ACCOUNT_SUCCESS:
    case actions.VERIFY_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        verifyBankAccountStatus: action.status,
        verifyAccountData: action.data
      }
    case actions.RESET_USER_REDUX:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}
