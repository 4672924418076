const actions = {
  RESET_STATE_GOJEK_PASSENGER: 'RESET_STATE_GOJEK_PASSENGER',
  CREATE_ORDER_GOJEK_PASSENGER: 'CREATE_ORDER_GOJEK_PASSENGER',
  CREATE_ORDER_GOJEK_PASSENGER_STATUS: 'CREATE_ORDER_GOJEK_PASSENGER_STATUS',
  GET_BENEFITS_GO_PASSENGER_REQUEST: 'GET_BENEFITS_GO_PASSENGER_REQUEST',
  GET_BENEFITS_GO_PASSENGER_SUCCESS: 'GET_BENEFITS_GO_PASSENGER_SUCCESS',
  GET_BENEFITS_GO_PASSENGER_FAILURE: 'GET_BENEFITS_GO_PASSENGER_FAILURE',
  GET_BENEFITS_GO_PASSENGER_STATUS: 'GET_BENEFITS_GO_PASSENGER_STATUS',
  CREATE_CLAIM_GO_PASSENGER_REQUEST: 'CREATE_CLAIM_GO_PASSENGER_REQUEST',
  CREATE_CLAIM_GO_PASSENGER_SUCCESS: 'CREATE_CLAIM_GO_PASSENGER_SUCCESS',
  CREATE_CLAIM_GO_PASSENGER_FAILURE: 'CREATE_CLAIM_GO_PASSENGER_FAILURE',
  CREATE_CLAIM_GO_PASSENGER_STATUS: 'CREATE_CLAIM_GO_PASSENGER_STATUS',
  VALIDATE_CLAIM_GO_PASSENGER: 'VALIDATE_CLAIM_GO_PASSENGER',
  VALIDATE_CLAIM_GO_PASSENGER_STATUS: 'VALIDATE_CLAIM_GO_PASSENGER_STATUS',
  resetStateGojekPassenger: () => ({
    type: actions.RESET_STATE_GOJEK_PASSENGER
  }),
  createOrder: payload => ({
    type: actions.CREATE_ORDER_GOJEK_PASSENGER,
    payload
  }),
  createOrderStatus: payload => ({
    type: actions.CREATE_ORDER_GOJEK_PASSENGER_STATUS,
    payload
  }),
  getBenefits: payload => ({
    type: actions.GET_BENEFITS_GO_PASSENGER_REQUEST,
    payload
  }),
  createClaimGocarPassenger: payload => ({
    type: actions.CREATE_CLAIM_GO_PASSENGER_REQUEST,
    payload
  }),
  validateClaimGoPassenger: payload => ({
    type: actions.VALIDATE_CLAIM_GO_PASSENGER,
    payload
  }),
  validateClaimGoPassengerStatus: payload => ({
    type: actions.VALIDATE_CLAIM_GO_PASSENGER_STATUS,
    payload
  })
}
export default actions
