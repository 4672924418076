import actions from './actions'

const initState = {
  createOrderStatus: '',
  getBenefitsGolifePassengerStatus: '',
  createClaimGocarPassengerStatus: ''
}

export default function golife (state = initState, action) {
  switch (action.type) {
    case actions.RESET_STATE_GOLIFE_PASSENGER:
      return {
        ...state,
        ...initState
      }
    case actions.CREATE_ORDER_GOLIFE_PASSENGER_STATUS:
      return {
        ...state,
        createOrderStatus: action.createOrderStatus
      }
    case actions.GET_BENEFITS_GO_LIFE_SUCCESS:
    case actions.GET_BENEFITS_GO_LIFE_FAILURE:
      return {
        ...state,
        dataBenefits: action.data,
        getBenefitsGolifePassengerStatus: action.getBenefitsGolifePassengerStatus
      }
    case actions.CREATE_CLAIM_GO_LIFE_SUCCESS:
    case actions.CREATE_CLAIM_GO_LIFE_FAILURE:
      return {
        ...state,
        dataClaim: action.data,
        createClaimGocarPassengerStatus: action.message
      }
    default:
      return state
  }
}
